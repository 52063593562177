import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = { ...state.user, ...action.payload };
    },
  },
});

// this is for dispatch
export const { setUser } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
