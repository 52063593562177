import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  targetEndpoint: "",
};

export const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    setTargetEndpoint(state, action) {
      state.targetEndpoint = action.payload;
    },
  },
});

// this is for dispatch
export const { setTargetEndpoint } = requestSlice.actions;

// this is for configureStore
export default requestSlice.reducer;
