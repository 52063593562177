import { useState } from "react";
import { RxGrid } from "react-icons/rx";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";

import { setStudioLayoutGrid } from "../../store/studioSlice";

const GRIDS = [
  {
    id: 1,
    width: 1,
    height: 1,
  },
  {
    id: 2,
    width: 2,
    height: 1,
  },
  {
    id: 3,
    width: 1,
    height: 2,
  },
  {
    id: 4,
    width: 2,
    height: 2,
  },
  {
    id: 5,
    width: 3,
    height: 3,
  },
];

const HeaderGridButton = () => {
  const dispatch = useDispatch();
  const { studioLayoutGrid } = useSelector((state) => state.studio.present);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onSelectGrid = (item) => {
    dispatch(setStudioLayoutGrid(item));
    closeMenu();
  };

  const onCustomFieldChange = ({ width, height }) => {
    if (width < 1 || height < 1 || width > 100 || height > 100) return;
    dispatch(setStudioLayoutGrid({ width, height }));
  };

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        sx={{ borderRadius: 2 }}
        onClick={openMenu}
        className="iconbtn"
      >
        <RxGrid size={"1.5em"} />
      </IconButton>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "150px",
            borderRadius: "0.5rem",
            filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.16))",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {GRIDS.map((item) => (
          <MenuItem
            key={item.id}
            sx={{ py: 1 }}
            onClick={() => onSelectGrid(item)}
            selected={
              studioLayoutGrid?.width === item.width &&
              studioLayoutGrid?.height === item.height
            }
          >
            {item.width} x {item.height}
          </MenuItem>
        ))}

        <Box mt={1} px={1}>
          <Typography mb={1.25} variant="body1" color="#aaa">
            Custom Grid
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs>
              <TextField
                value={studioLayoutGrid?.width}
                size="small"
                label="Rows"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  onCustomFieldChange({
                    width: Number(e.target.value),
                    height: studioLayoutGrid?.height,
                  })
                }
              />
            </Grid>
            <Grid item xs>
              <TextField
                value={studioLayoutGrid?.height}
                size="small"
                label="Cols"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  onCustomFieldChange({
                    width: studioLayoutGrid?.width,
                    height: Number(e.target.value),
                  })
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Menu>
    </>
  );
};

export default HeaderGridButton;
