import { v4 as uuid } from "uuid";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";

import { setToolsDragElement } from "../../store/toolsSlice";
import EditToolsContentTextMenuElements from "./EditToolsContentTextMenuElements";

const texts = [
  {
    name: "Add a heading",
    id: "text-heading",
    fontSize: 32,
  },
  {
    name: "Add a sub-heading",
    id: "text-sub-heading",
    fontSize: 20,
  },
  {
    name: "Add some paragraph text",
    id: "text-paragraph",
    fontSize: 10,
  },
];

const EditToolsContentText = () => {
  const dispatch = useDispatch();

  return (
    <>
      <Typography sx={{ color: "#fff" }} mb={1.5}>
        Text Elements
      </Typography>

      {texts.map((text, index) => {
        return (
          <Box
            px={2}
            py={1.3}
            mb={1.5}
            draggable
            className="bg-gray-700 rounded-lg cursor-pointer"
            key={`${text.id}-${index}`}
            onDragStart={(e) => {
              dispatch(
                setToolsDragElement({
                  ...text,
                  scaleX: 1,
                  scaleY: 1,
                  opacity: 1,
                  fill: "#000",
                  rotation: 0,
                  type: "text",
                  stroke: "#969696",
                  strokeWidth: 0,
                  animation: null,
                  draggable: true,
                  text: text.name,
                  textDecoration: "",
                  fontFamily: "Arial",
                  fontStyle: "normal",
                  id: `${text.id}-${uuid()}`,
                })
              );
            }}
            onDragEnd={(e) => {
              dispatch(setToolsDragElement(null));
            }}
          >
            <Typography sx={{ color: "#fff" }} fontSize={text.fontSize}>
              {text.name}
            </Typography>
          </Box>
        );
      })}

      <EditToolsContentTextMenuElements />
    </>
  );
};

export default EditToolsContentText;
