import { v4 as uuid } from "uuid";
import { ReactSVG } from "react-svg";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@mui/material";

import shapesJSON from "./shapes.json";
import { setToolsDragElement } from "../../store/toolsSlice";

const EditToolsContentElements = () => {
  const dispatch = useDispatch();
  const [shapes, setShapes] = useState([]);
  const [loading, setLoading] = useState(false);

  const getShapeList = async () => {
    setLoading(true);

    try {
      setShapes(shapesJSON);
    } catch (error) {
      setShapes([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getShapeList();
  }, []);

  return loading ? (
    <Grid container justifyContent="center" py={2}>
      <CircularProgress />
    </Grid>
  ) : (
    <Grid p={1.5} container spacing={4.5}>
      {shapes.length
        ? shapes.map((shape, index) => (
            <Grid item xs={4} key={`shape-${index}`}>
              <ReactSVG
                draggable
                src={shape.url}
                beforeInjection={(svg) => {
                  svg.setAttribute("style", "height: 60px; width: 60px");
                  // console.log(svg);
                }}
                // onClick={() => {
                //   console.log(shape);
                // }}
                onDragStart={(e) => {
                  const svg = e.target.querySelector("svg");
                  const svgBox = svg.getBBox();
                  console.log("shapes array", shape);
                  dispatch(
                    setToolsDragElement({
                      scaleX: 1,
                      scaleY: 1,
                      opacity: 1,
                      rotation: 0,
                      type: "shape",
                      src: shape.url,
                      borderWidth: 0,
                      draggable: true,
                      width: svgBox.width,
                      height: svgBox.height,
                      name: shape.file_name,
                      svgType: shape.type,
                      id: `shape-${index}-${uuid()}`,
                    })
                  );
                }}
                onDragEnd={(e) => {
                  dispatch(setToolsDragElement(null));
                }}
              />
            </Grid>
          ))
        : ""}
    </Grid>
  );
};

export default EditToolsContentElements;
