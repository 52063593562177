import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Button, Menu, ToggleButton } from "@mui/material";
import CustomToolTip from "../CustomToolTip";

const PropertiesDropdown = ({ size, items, buttonText, buttonIcon }) => {
  const dispatch = useDispatch();
  const studio = useSelector((state) => state.studio.present);
  const [selectedItems, setSelectedItems] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setNewItems = () => {
    if (!items.length) return [];
    const newItems = items.map((item, index) => ({
      ...item,
      id: index,
      selected: item.selected ? item.selected({ studio }) : null,
    }));
    setSelectedItems(newItems);
  };

  if (!selectedItems.length) {
    setNewItems();
  }

  const onToggle = (event, value) => {
    console.log("vvv->", value);
    const toggleItem = {
      ...value,
      selected: value.selected !== null ? !value.selected : null,
    };
    const newItems = selectedItems.map((item) => {
      if (item.id === toggleItem.id) return { ...item, selected: true };
      return { ...item, selected: false };
    });
    setSelectedItems(newItems);
    toggleItem.onChange({ studio, dispatch, value: value.selected });
  };

  return (
    <>
      <CustomToolTip
        content={
          "You can set order or alignemnt of selected element on your desired place"
        }
      >
        <Button
          fullWidth
          onClick={handleClick}
          startIcon={buttonIcon}
          sx={{
            textTransform: "none",
            justifyContent: "flex-start",
            color: "#333",
            "&:hover": {
              backgroundColor: "#f0f3f5",
            },
          }}
        >
          {buttonText}
        </Button>
      </CustomToolTip>

      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: `${size || 220}px`,
            borderRadius: "0.5rem",
            filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.16))",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Grid container alignItems="center" spacing={1} px={1}>
          {selectedItems?.map((item, index) => (
            <Grid item xs={6} key={index}>
              <ToggleButton
                color="primary"
                fullWidth
                value={item}
                selected={item.selected}
                style={{
                  backgroundColor: item?.selected ? "#5dbbe4" : "",
                  color: item?.selected ? "#fff" : "",
                }}
                sx={{
                  textTransform: "none",
                  padding: "0.3rem 0.4rem",
                  borderRadius: "0.5rem",
                  borderColor: "#f0f3f5",

                  "&:hover": {
                    backgroundColor: "#5dbbe4",
                    color: "#fff",
                  },
                }}
                onChange={onToggle}
              >
                <span className="pr-2">{item.icon}</span>
                <span>{item.text}</span>
              </ToggleButton>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </>
  );
};

export default PropertiesDropdown;
