import { MdFlip } from "react-icons/md";
import { CgEditFlipH, CgEditFlipV } from "react-icons/cg";

import PropertiesDropdown from "./PropertiesDropdown";
import { updateStudioElement } from "../../store/studioSlice";

const PropertiesPanelFlip = () => {
  const flipItems = [
    {
      text: "Horizontal",
      icon: <CgEditFlipH size={18} />,
      selected: ({ studio }) => {
        const { studioCurrentTarget, studioElements } = studio;
        const selectedElement = studioElements.find(
          (item) => item.id === studioCurrentTarget
        );
        const scaleX = selectedElement?.scaleX;
        return scaleX > 0 ? false : true;
      },
      onChange: ({ studio, dispatch }) => {
        const { studioCurrentTarget, studioElements } = studio;
        const selectedElement = studioElements.find(
          (item) => item.id === studioCurrentTarget
        );

        dispatch(
          updateStudioElement({
            scaleX: -selectedElement?.scaleX,
          })
        );
      },
    },
    {
      text: "Vertical",
      icon: <CgEditFlipV size={18} />,
      selected: ({ studio }) => {
        const { studioCurrentTarget, studioElements } = studio;
        const selectedElement = studioElements.find(
          (item) => item.id === studioCurrentTarget
        );
        const scaleY = selectedElement?.scaleY;
        return scaleY > 0 ? false : true;
      },
      onChange: ({ studio, dispatch }) => {
        const { studioCurrentTarget, studioElements } = studio;
        const selectedElement = studioElements.find(
          (item) => item.id === studioCurrentTarget
        );

        dispatch(
          updateStudioElement({
            scaleY: -selectedElement?.scaleY,
          })
        );
      },
    },
  ];

  return (
    <PropertiesDropdown
      items={flipItems}
      buttonText="Flip"
      buttonIcon={<MdFlip />}
    />
  );
};

export default PropertiesPanelFlip;
