import {Box} from "@mui/system";
import {Link} from "react-router-dom";
import {MdEmail} from "react-icons/md";
import {Button, Divider, Typography} from "@mui/material";

import AuthBox from "../components/Auth/AuthBox";
import {baseURL} from "../services/request";

const Welcome = () => {
    return (
        <AuthBox>
            <Typography mb={2} variant="h4" fontWeight="bold" textAlign="center">
                Media Editor
            </Typography>

            <Typography textAlign="center" mb={2}>
                Log in or create an account if you don't have one yet.
            </Typography>

            <Link to="/signin">
                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{
                        boxShadow: "0",
                        borderRadius: 1.5,
                        textTransform: "none",
                    }}
                >
                    Log in
                </Button>
            </Link>

            <Box position="relative">
                <Typography
                    textAlign="center"
                    variant="caption"
                    className="absolute left-0 right-0 mx-auto"
                    sx={{top: "-8px"}}
                >
                    Or
                </Typography>
                <Divider
                    light
                    sx={{
                        marginTop: 3,
                        marginBottom: 3,
                        borderRadius: 1.5,
                        textTransform: "none",
                    }}
                />
            </Box>

            <Typography textAlign="center" variant="body1" mb={2}>
                Sign up now for free!
            </Typography>

            <a href={`${baseURL}/register`}>
                <Button
                    fullWidth
                    size="large"
                    variant="outlined"
                    startIcon={<MdEmail/>}
                    sx={{borderRadius: 1.5, textTransform: "none"}}
                >
                    Continue with Email
                </Button>
            </a>
        </AuthBox>
    );
};

export default Welcome;
