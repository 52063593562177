import { Box } from "@mui/system";
import { Grid } from "@mui/material";

const AuthBox = (props) => {
  return (
    <main className="h-screen">
      <Grid
        container
        className="h-full"
        alignContent="center"
        justifyContent="center"
      >
        <Grid item>
          <Box
            p={3}
            sx={{
              width: 320,
              borderRadius: 3,
              background: "white",
              filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.13))",
            }}
          >
            {props.children}
          </Box>
        </Grid>
      </Grid>
    </main>
  );
};

export default AuthBox;
