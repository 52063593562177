import { useEffect, useState } from "react";
import { ButtonGroup, Button } from "@mui/material";

const DEFAULT_SIZES = {
  fullSize: 70,
  width: 20,
  height: 30,
  fontSize: 18,
  inputFontSize: 12,
};

const PropertiesStepperButton = ({
  defaultValue = 1,
  size = "small",
  onChange,
  animation,
}) => {
  const [value, setValue] = useState(defaultValue);
  const [buttonSizes, setButtonSizes] = useState({ ...DEFAULT_SIZES });
  const buttonStyle = {
    minWidth: buttonSizes.width,
    height: buttonSizes.height,
    fontSize: buttonSizes.fontSize,
  };

  useEffect(() => {
    const calculateSizes = () => {
      switch (size) {
        case "small":
          setButtonSizes({ ...DEFAULT_SIZES });
          break;
        case "medium":
          setButtonSizes({
            ...buttonSizes,
            height: 30,
            fullSize: 90,
            inputFontSize: 13,
          });
      }
    };

    calculateSizes();
  }, [size]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleOnInput = (event) => {
    const theValue = event.target.value;
    setValue(Number(theValue));
    onChange(Number(theValue));
  };

  const handleIncrement = () => {
    const theValue = animation ? (value + 1) : (value + 0.1);
    setValue(theValue);
    onChange(theValue);
  };

  const handleDecrement = () => {
    const theValue = animation ? (value - 1) : (value - 0.1);
    setValue(theValue);
    onChange(theValue);
  };

  return (
    <ButtonGroup
      color="inherit"
      size="small"
      style={{ width: buttonSizes.fullSize }}
    >
      <Button
        style={{ ...buttonStyle }}
        disabled={value <= 1}
        onClick={handleDecrement}
      >
        -
      </Button>

      <Button
        style={{
          ...buttonStyle,
          padding: 0,
          margin: 0,
        }}
      >
        <input
          type="number"
          value={animation ? Math.ceil(value) : Math.ceil(value * 10)}
          style={{
            border: 0,
            outline: 0,
            width: "100%",
            height: "100%",
            textAlign: "center",
            fontWeight: "normal",
            backgroundColor: "transparent",
            fontSize: buttonSizes.inputFontSize,
          }}
          className="input-number"
          onInput={handleOnInput}
        />
      </Button>
      <Button style={{ ...buttonStyle }} onClick={handleIncrement}>
        +
      </Button>
    </ButtonGroup>
  );
};

export default PropertiesStepperButton;
