import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IconButton } from "@mui/material";
import { BiUndo } from "react-icons/bi";

import EditToolsContentText from "./EditToolsContentText";
import EditToolsContentResize from "./EditToolsContentResize";
import EditToolsContentImages from "./EditToolsContentImages";
import EditToolsContentVideos from "./EditToolsContentVideos";
import EditToolsContentWidgets from "./EditToolsContentWidgets";
import EditToolsContentElements from "./EditToolsContentElements";
import EditToolsContentMyContent from "./EditToolsContentMyContent";
import EditToolsContentTemplates from "./EditToolsContentTemplates";
import { setToolsContentScrollEnd } from "../../store/toolsSlice";

import EditToolsStyle from "./EditTools.module.scss";
import HeaderLayerButton from "../Header/HeaderLayerButton";
import EditToolsContentLayers from "./EditToolsContentLayers";

const EditToolsContent = () => {
  const dispatch = useDispatch();
  const [boxHeight, setBoxHeight] = useState(0);
  const menuItems = useSelector((state) => state.tools.menu);
  const selectedMenu = menuItems.find((item) => item.selected);
  const { key: selectedMenuKey } = selectedMenu;

  const onContentScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const isAtBottom = scrollTop + clientHeight + 1 >= scrollHeight; // Add a small threshold
    dispatch(setToolsContentScrollEnd(isAtBottom));
  };
  const chngstyle = () => {};

  useEffect(() => {
    const editToolsElement = document.querySelector("#edit-tools-element");
    const editToolsElementHeight = editToolsElement.offsetHeight;
    setBoxHeight(editToolsElementHeight);
  }, []);

  return (
    <div className={EditToolsStyle.content} id="side-contect-wrap">
      <Box
        p={2}
        onScroll={onContentScroll}
        sx={{ maxHeight: boxHeight, overflow: "auto", height: "95%" }}
      >
        {selectedMenuKey === "resize" && <EditToolsContentResize />}
        {selectedMenuKey === "elements" && <EditToolsContentElements />}
        {selectedMenuKey === "images" && <EditToolsContentImages />}
        {selectedMenuKey === "text" && <EditToolsContentText />}
        {selectedMenuKey === "videos" && <EditToolsContentVideos />}
        {selectedMenuKey === "templates" && <EditToolsContentTemplates />}
        {selectedMenuKey === "my-content" && <EditToolsContentMyContent />}
        {selectedMenuKey === "widgets" && <EditToolsContentWidgets />}
        {selectedMenuKey === "layers" && <EditToolsContentLayers />}
      </Box>
      {/* <div className="icon-arrow">
        <IconButton
          size="small"
          color="inherit"
          sx={{ borderRadius: 2 }}
          onClick={chngstyle}
        >
          <BiUndo size={"1.5em"} />
        </IconButton>
      </div> */}
    </div>
  );
};

export default EditToolsContent;
