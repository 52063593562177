import { useSelector } from "react-redux";

import StudioElement from "./StudioElement";
import { Rect } from "react-konva";

const StudioElements = () => {

  const { studioElements, studioLayout } = useSelector(
    (state) => state.studio.present
  );
  const elements = studioElements?.filter((item) => item.name !== "TextMenu");

  const applyZoomScale = (attributes) => {
    return {
      ...attributes,
      scaleX: attributes.scaleX * (studioLayout.zoomScale / 100),
      scaleY: attributes.scaleY * (studioLayout.zoomScale / 100),
      x: attributes.x * (studioLayout.zoomScale / 100),
      y: attributes.y * (studioLayout.zoomScale / 100),
    };
  };
  
  return (
    <>
      {elements.map((item, index) => (
        <StudioElement
          key={`${item.id}-${index}`}
          attributes={applyZoomScale(item)} // Apply zoom scale here
          itemId={item.id}
        />
      ))}
    </>
  );
};

export default StudioElements;
