import Masonry from "@mui/lab/Masonry";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, CircularProgress } from "@mui/material";

import request from "../../services/request";
import { getToken } from "../../services/localStorage";
import { layoutScaleRelativeToPoint } from "../../utils";
import { updateStudioLayout } from "../../store/studioSlice";

const EditToolsContentResize = () => {
  const dispatch = useDispatch();
  const [layouts, setLayouts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { stage, studioLayout } = useSelector((state) => state.studio.present);

  const onResizeClick = (item) => {
    const groupNode = stage?.findOne("#studio-group");
    const defaultZoomScale = 85;

    const point = layoutScaleRelativeToPoint({
      stage,
      layout: item,
      node: groupNode,
      scaleBy: defaultZoomScale,
    });

    dispatch(
      updateStudioLayout({
        id: item.id,
        width: item.width,
        height: item.height,
        zoomScale: defaultZoomScale,
        x: (stage.width() - item.width) / 2,
        y: (stage.height() - item.height) / 2,
        group: {
          x: point.position.x,
          y: point.position.y,
          scaleX: point.scale.x,
          scaleY: point.scale.y,
        },
      })
    );
  };

  const getLayoutList = async () => {
    setLoading(true);

    const token = getToken();

    try {
      const res = await request({
        url: "/layouts",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const layouts = res.data?.data;
      setLayouts(layouts);
    } catch (error) {
      setLayouts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLayoutList();
  }, []);

  return loading ? (
    <Grid container justifyContent="center" py={2}>
      <CircularProgress />
    </Grid>
  ) : layouts.length ? (
    <Masonry columns={2} spacing={1}>
      {layouts.map((item, index) => (
        <Box
          className={`cursor-pointer ${
            studioLayout.id === item.id ? "bg-blue-600" : "bg-gray-700"
          } hover:bg-blue-600`}
          borderRadius={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          key={index}
          py={3}
          sx={{ height: (item.height / item.width) * 100 + 10 }}
          onClick={(e) => onResizeClick(item)}
        >
          <Typography color="white" textAlign="center" variant="body2">
            {item.name} <br /> {`${item.width}x${item.height}`}
          </Typography>
        </Box>
      ))}
    </Masonry>
  ) : (
    []
  );
};

export default EditToolsContentResize;
