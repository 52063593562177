import { IconButton } from "@mui/material";
import { MdOutlineFormatItalic } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import { updateStudioElement } from "../../../store/studioSlice";

const TextFontItalic = ({ dialect }) => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  let fontStyle =
    dialect === "odd"
      ? selectedElement.rows[0].elements[0].props.fontStyle
      : dialect === "even"
      ? selectedElement.rows[1].elements[0].props.fontStyle
      : selectedElement.fontStyle;
  const isItalic = fontStyle?.includes("italic");

  const onClick = () => {
    if (fontStyle === "normal") {
      fontStyle = fontStyle?.replace("normal", "").trim();
    }

    if (isItalic) {
      fontStyle = fontStyle?.replace("italic", "");
    } else {
      fontStyle = fontStyle?.replace("", "italic ").trim();
    }

    dispatch(updateStudioElement({ fontStyle, dialect }));
  };

  return (
    <IconButton color={isItalic ? "primary" : "inherit"} onClick={onClick}>
      <MdOutlineFormatItalic color={isItalic ? "#5DBBE4" : ""} />
    </IconButton>
  );
};

export default TextFontItalic;
