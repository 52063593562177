import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Box, Checkbox, Typography, Button, makeStyles } from "@mui/material";
import { BsPencil } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setMenuList } from "../../store/menuSlice";
import "./../../../src/styles/index.scss";
import { RxDragHandleDots2 } from "react-icons/rx";
import { HiOutlineBan } from "react-icons/hi";
const DraggableMenuList = ({
  changeTargetMenu,
  // menuList = [],
  searchTerm,
  changeModalVisibility,
}) => {
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state);
  const { menuList } = menu;
  console.log("menuList=>>>", menuList);
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const updatedMenuList = Array.from(menuList);
    const [reorderedItem] = updatedMenuList.splice(result.source.index, 1);
    updatedMenuList.splice(result.destination.index, 0, reorderedItem);
    
    dispatch(setMenuList(updatedMenuList));
  };

  // useEffect(() => {
  //   const filteredMenuList = menuList.filter((item) =>
  //     item.itemName.includes(searchTerm)
  //   );
  //   dispatch(setMenuList(filteredMenuList));
  // }, [searchTerm, dispatch]);

  const filteredMenuList = menuList.filter((item) =>
  item.itemName.includes(searchTerm)
);
  // const handleDragEnd = (result) => {
  //   if (!result.destination) return;
  //   const sourceIndex = result.source.index;
  //   const destinationIndex = result.destination.index;
  //   const updatedMenuList = [...menuList];
  //   const [reorderedItem] = updatedMenuList.splice(sourceIndex, 1);
  //   updatedMenuList.splice(destinationIndex, 0, reorderedItem);
  //   dispatch(setMenuList(updatedMenuList));
  // };

  if (menuList?.length === 0) {
    return (
      <Box
        item
        xs
        sx={{
          height: "400px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Box>
          {/* <BlockIcon sx={{ color: "#0089ff" }} /> */}
          <HiOutlineBan color="#9EDDF2" />
        </Box>
        <Box>
          <Typography sx={{ fontSize: "12px", color: "#b5b5b5" }}>
            No item found
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="menuList">
        {(provided) => (
          <Box
            style={{ padding: "0 50px 0 10px" }}
            className="w-full"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {filteredMenuList?.map((temp, index) => (
              <Draggable
                key={temp.id + ""}
                draggableId={temp.id + ""}
                index={index}
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="flex flex-row items-center"
                  >
                    <RxDragHandleDots2 />
                    <Box
                      className="mt-2 border-[0.5px] border-gray-300 rounded-lg w-100 flex flex-column"
                      style={{
                        flex: 1,
                        backgroundColor:
                          temp?.selected === true ? "#ecf8fd" : "",
                        borderColor: temp?.selected === true ? "#3fbce7" : "",
                      }}
                    >
                      <Box className="w-full flex flex-row justify-between items-center gap-2">
                        <Box className="flex flex-row items-center">
                          <Checkbox
                            style={{ color: "#3fbce7" }}
                            onChange={(e) => {
                              const t = { ...temp };
                              const copy = [...menuList];
                              t.selected = e.target.checked;
                              copy[index] = t;
                              dispatch(setMenuList(copy));
                            }}
                            checked={temp.selected === true}
                          />
                          <Typography className="w-auto" style={{ wordBreak: 'break-word' }}>
                            {temp.itemName}
                          </Typography>
                        </Box>
                        <Box className="flex flex-row justify-end items-center gap-2">
                          <Typography
                            style={{
                              backgroundColor: "#d9f2fa",
                              borderRadius: "100px",
                              fontSize: "12px",
                              marginRight: "10px",
                            }}
                            className="rounded-lg py-1 px-3 bg-blue-300"
                          >
                            {temp.itemPrice}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className="items-center border-0 border-l-[0.5px] border-l-gray-300">
                        <Button
                          onClick={() => {
                            // handleTemplateEditButton(temp, index)
                            changeTargetMenu(temp);
                            changeModalVisibility(true);
                          }}
                          className="h-full"
                          style={
                            {
                              // backgroundColor: "#d9f2fa",
                              // border: "0.5px solid #3fbce7",
                            }
                          }
                        >
                          <BsPencil sx={{ color: "secondary" }} />
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableMenuList;
