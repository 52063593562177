import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, Select, MenuItem, Typography } from "@mui/material";

import request from "../../services/request";
import { setStudioCategory } from "../../store/studioSlice";
import { getToken } from "../../services/localStorage";

const OnboardingCategories = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  const onSelectCategoryChange = (event) => {
    const category = event.target.value;
    dispatch(setStudioCategory(category));
    localStorage.setItem("category", JSON.stringify(category));
  };

  const getCategories = async () => {
    setLoading(true);

    const token = getToken();

    try {
      const res = await request({
        method: "get",
        url: "/categories",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const categoryList = res?.data?.data;
      setCategories(categoryList);
    } catch (error) {
      setCategories([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <Box>
      <Typography variant="h5" fontWeight="normal" textAlign="center" mb={2}>
        Choose Your Category
      </Typography>

      <Select
        fullWidth
        size="small"
        sx={{ mb: 1 }}
        onChange={onSelectCategoryChange}
      >
        {categories.map((item, index) => (
          <MenuItem value={item} key={index}>
            {item.name}
          </MenuItem>
        ))}
      </Select>

      <Link to="/">
        <Button
          fullWidth
          size="large"
          color="primary"
          variant="contained"
          sx={{
            boxShadow: "0",
            borderRadius: 1.5,
            textTransform: "none",
          }}
        >
          Next
        </Button>
      </Link>
    </Box>
  );
};

export default OnboardingCategories;
