import { useSelector, useDispatch } from "react-redux";

import { useAuthenticated } from "../../hooks";
import { createReactElement } from "../../utils";
import { setToolsMenu } from "../../store/toolsSlice";
import EditToolsMenuProfile from "./EditToolsMenuProfile";

import EditToolsStyle from "./EditTools.module.scss";

const EditToolsMenu = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuthenticated();
  const menuItems = useSelector((state) => state.tools.menu);
  const styles = {
    active: {
      backgroundColor: "#a5a3a3",
    },
  };
  return (
    <div className={EditToolsStyle.menu} id="ul-side">
      <ul className={EditToolsStyle.menuList} id="edit_ToolStyle">
        {menuItems.map((item, index) => (
          <li
            className={`${EditToolsStyle.menuListItem} ${item.selected ? EditToolsStyle.menuListItemActive : ""
              }`}
            style={{
              backgroundColor: item.selected ? "rgba(255, 255, 255, 0.25)" : "",
              borderRight: item.selected ? "3px solid " : "",
            }}
            id="menu-item"
            key={index}
            onClick={() => dispatch(setToolsMenu({ ...item, selected: true }))}
          >
            {createReactElement(item.icon, {
              size: "1.4em",
              className: EditToolsStyle.menuListItemIcon,
            })}
            <span className={EditToolsStyle.menuListItemText}>
              {item.title}
            </span>
          </li>
        ))}
      </ul>
      {isAuthenticated && <EditToolsMenuProfile />}
    </div>
  );
};

export default EditToolsMenu;
