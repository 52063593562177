import { v4 as uuid } from "uuid";
import { Html } from "react-konva-utils";
import { Group, Line, Rect, Text } from "react-konva";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, forwardRef, useMemo, useRef } from "react";

import {
  updateStudioElement,
  setStudioCurrentTarget,
} from "../../store/studioSlice";
import { TEXT_ELEMENT_DEFAULT_PROPS } from "../../constants";

const StudioElementText = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const textHtmlRef = useRef();
  const [editable, setEditable] = useState(false);
  const { stage, studioCurrentTarget, studioElements, text_edit } = useSelector(
    (state) => state.studio.present
  );
  const textNode = stage?.findOne(`#${props.id}`);
  const layer = stage?.findOne("#studio-layer");
  const mainGroup = stage?.findOne("#studio-group");

  const textContent = useMemo(() => {
    return props.text || props.name;
  }, []);

  const onDoubleClick = () => {
    // console.log("onDoubleClick")
    if (props?.type === "menu") return false;
    dispatch(setStudioCurrentTarget(props.id));
    const menuElement = textNode?.findAncestor(".Menu");
    if (menuElement) {
      const fontSize = props.fontSize * menuElement.scaleX();
      textHtmlRef.current.style.fontSize = `${fontSize}px`;
    }
    setEditable(true);
    // return false;
  };


  const handlefontsize = (item) => {
    let fontvalue = 0;

    switch (item.dataType) {
      case "title":
        fontvalue = item.fontSize * 2.4;
        break;
      case "price":
        fontvalue = item.fontSize * 1.6;
        break;
      default:
        fontvalue = item.fontSize;
    }

    return fontvalue;
  };

  const onTyping = (e) => {
    const textValue = e?.target?.innerText;

    dispatch(
      updateStudioElement({
        id: props.id,
        text: textValue,
      })
    );
  };

  const onTransformEnd = (e) => {
    const node = e.currentTarget;
    const fontSize = node.fontSize() * node.scaleX();

    dispatch(
      updateStudioElement({
        fontSize,
        x: node.x(),
        y: node.y(),
        id: props.id,
        rotation: node.rotation(),
      })
    );

    node.scale({ x: 1, y: 1 });
    layer.draw();
  };

  // useEffect(() => {
  //   const selectedElement = stage?.findOne(`#${props.id}`);
  //   setEditable(false);
  //   selectedElement?.clearCache();
  // }, [studioCurrentTarget]);

  useEffect(() => {
    const selectedElement = stage?.findOne(`#${props.id}`);
    // console.log(selectedElement, "selectedElement useEffect");
    if (selectedElement) {
      setEditable(false);
      selectedElement.clearCache();
    }
  }, [props.id, stage]);

  useEffect(() => {
    // console.log("useEffect", props.id)
    const selectedElement = stage?.findOne(`#${props.id}`);
    // console.log(selectedElement, "selectedElement useEffect");
    // setEditable(false);
    selectedElement?.clearCache();
  }, [studioCurrentTarget]);
  return (
    <>
      <Html
        transform
        transformFunc={(attr) => {
          attr.rotation = textNode?.rotation();
          attr.scaleX = textNode?.scaleX() * mainGroup?.scaleX();
          attr.scaleY = textNode?.scaleY() * mainGroup?.scaleY();
          attr.x = attr.x + textNode?.x() * mainGroup?.scaleX();
          attr.y = attr.y + textNode?.y() * mainGroup?.scaleY();
          return attr;
        }}
        divProps={{
          style: {
            visibility: props.id === studioCurrentTarget && text_edit ? "visible" : "hidden",
          },
        }}
      >
        <span
          ref={textHtmlRef}
          role="textbox"
          contentEditable
          // onBlur={onTyping}
          onBlur={() => {
            // console.log("on blur pointer")
          }}
          onInput={onTyping}
          suppressContentEditableWarning={true}
          style={{
            outline: 0,
            lineHeight: 0.85,
            color: props.fill,
            opacity: props.opacity,
            background: "transparent",
            fontSize: `${props.fontSize}px`,
            WebkitTextStrokeColor: props.stroke,
            textDecoration: props.textDecoration,
            fontFamily: `${props.fontFamily || "Arial"}`,
            WebkitTextStrokeWidth: `${props.strokeWidth}px`,
            fontWeight: props?.fontStyle?.includes("bold") ? "bold" : "normal",
            fontStyle: props?.fontStyle?.includes("italic")
              ? "italic"
              : "normal",
          }}
        >
          {textContent}
        </span>
      </Html>
      {/* // Border Fix */}
      {/* <Rect width={200} height={100} stroke='#000' strokeWidth={1} /> */}
      {/* <Line stroke='#000' strokeWidth={1} points={[50,0,0,0,0,50]} /> */}
      {props.text === "react" && (
        <Rect
          width={props.width}
          x={0}
          y={0}
          height={props.height}
          stroke="#000"
          strokeWidth={2}
          name="react"
        />
      )}
      {props.text === "react-line" && (
        <Rect
          width={1}
          x={props.left}
          y={0}
          height={props.height}
          stroke="#000"
          strokeWidth={1}
          name="line"
        />
      )}
      {/* {console.log(props, "helloWorld")} */}
      {props.text === "line" && (
        <Line
          stroke="#000"
          strokeWidth={1}
          dash={[2, 2]}
          x={props.scaleX}
          y={props.scaleY}
          menuID={props.menuID}
          points={[props?.left, 0, 0, 0]}
        />
      )}

      <Text
        {...props}
        ref={ref}
        //fontSize={handlefontsize(props)}
        id={props.id}
        visible={props.visible}
        // visible={!editable}
        onDblClick={onDoubleClick}
        onTransformEnd={onTransformEnd}
        onClick={() => dispatch(setStudioCurrentTarget(props.id))}
        draggable={props.draggable}
      //editable={props.draggable}
      // listening={false}
      />
    </>
  );
});

StudioElementText.defaultProps = TEXT_ELEMENT_DEFAULT_PROPS;

export default StudioElementText;
