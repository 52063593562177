import { useState, useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { updateStudioElement } from "../../store/studioSlice";

const PropertiesPanelPosition = () => {
  const dispatch = useDispatch();

  const { stage, studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedNode = stage?.findOne(`#${selectedElement.id}`);

  const [position, setPosition] = useState({
    x: selectedNode?.x(),
    y: selectedNode?.y(),
  });

  const onChangePositionX = (event) => {
    const x = parseInt(event?.target?.value);
    setPosition({ ...position, x: x });
    selectedNode?.x(x);
  };

  const onChangePositionY = (event) => {
    const y = parseInt(event?.target?.value);
    setPosition({ ...position, y: y });
    selectedNode?.y(y);
  };

  useEffect(() => {
    if (!selectedElement) return;
    setPosition({
      x: parseInt(selectedNode?.x()),
      y: parseInt(selectedNode?.y()),
    });
  }, [selectedNode?.x(), selectedNode?.y()]);

  return (
    <Grid container alignItems="center" spacing={1} py={1}>
      <Grid item xs={4}>
        <span className="text-sm">position</span>
      </Grid>
      <Grid item xs>
        <TextField
          value={position.x}
          size="small"
          label="X position"
          variant="outlined"
          type="number"
          onChange={onChangePositionX}
        />
      </Grid>
      <Grid item xs>
        <TextField
          value={position.y}
          size="small"
          label="Y position"
          variant="outlined"
          type="number"
          onChange={onChangePositionY}
        />
      </Grid>
    </Grid>
  );
};

export default PropertiesPanelPosition;
