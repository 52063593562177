// import canvasRecord from "canvas-record";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

import { useEffect, useState, useRef } from "react";

import { RiDownloadCloud2Line } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";

import DownloadSaveButton from "./DownloadSaveButton";

import { downloadURI } from "../../utils";
import { CanvasRecorder } from "../../utils/CanvasRecorder";
import {
  resetStudioTransformer,
  setStudioPlay,
  addStudioElement,
  removeStudioElement
} from "../../store/studioSlice";
import { updateStudioLayout } from "../../store/studioSlice"; // Ensure this import is added

const HeaderDownloadButton = () => {
  const dispatch = useDispatch();
  const { stage, studioLayout, studioDuration } = useSelector(
    (state) => state.studio.present
  );
  const [loading, setLoading] = useState(false);


  // humayoun
    // Function to temporarily reset zoom to 100% before download
    const resetZoomBeforeDownload = (callback) => {
      // Set the zoomScale to 100% to ensure the template covers the container
      dispatch(updateStudioLayout({ ...studioLayout, zoomScale: 100 }));
    
      // Ensure the layout and zoom changes are applied before proceeding
      setTimeout(() => {
        callback(); // Execute the download logic after resetting the zoom
      }, 100); // Small delay to ensure the zoom changes are applied
    };
    
    
  // humayoun
  
  const [isFfmpegLoaded, setFfmpegLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg({ log: true }));

  const recorderRef = useRef();

  const loadFFMEG = async () => {
    // const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd"; // single thread
    const baseURL = "https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm"; // multi thread
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on("log", ({ message }) => {
      console.log(message);
    });
    // toBlobURL is used to bypass CORS issue, urls with the same domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
      workerURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.worker.js`,
        "text/javascript"
      )
    });
    setFfmpegLoaded(true);
  };


  const transcode = async ({ webm, fileName, frameRate, width, height }) => {
    if (!isFfmpegLoaded) {
      await loadFFMEG();
    }

    console.log("ffmeg in transcode : ",fileName);
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.writeFile(`${fileName}.webm`, await fetchFile(webm));
    /* await ffmpeg.exec([
      "-i",
      `${fileName}.webm`,
      "-vf", // Apply video filter (second stage)
      `scale=${width}:${height}`, // Scale to final width and height
      "-preset",
      "medium",
      "-crf",
      "23",
      "-b:v",
      "1M",
    "-r",
    `${frameRate}`,
    "-threads",
    "4",
      `${fileName}.mp4`
    ]); */
    await ffmpeg.exec([
      "-i",
      `${fileName}.webm`,
      "-vf",
      `scale=${width}:${height}`,
      "-preset",
      "medium",
      "-crf",
      "23",
      "-b:v",
      "1M",
      "-r",
      `${frameRate}`,
      "-c:v",
      "libx264",
      "-pix_fmt",
      "yuv420p",
      "-movflags",
      "faststart", // Ensures MP4 file is optimized for streaming
      "-threads",
      "4",
      `${fileName}.mp4`
    ]);
    // await ffmpeg.exec(['-i', `${fileName}.webm`, `${fileName}.mp4`]);

    const data = await ffmpeg.readFile(`${fileName}.mp4`);
    const url = URL.createObjectURL(
      new Blob([data.buffer], { type: "video/mp4" })
    );
    // console.log(url);
    console.log("transcode fileName : ",fileName);
    downloadLinkToVideo(url, fileName);
  };

  const downloadAsImage = ({ fileName, fileType }) => {

    resetZoomBeforeDownload(() => {

    const mainGroup = stage.findOne("#studio-group");
    const pureMainGroup = mainGroup.clone({ scaleX: 1, scaleY: 1 });
    const dataURL = pureMainGroup.toDataURL({
      width: studioLayout.width,
      height: studioLayout.height,
      mimeType: `image/${fileType.format}`,
      x: (stage.width() - studioLayout.width * mainGroup.scaleX()) / 2,
      y: (stage.height() - studioLayout.height * mainGroup.scaleY()) / 2
    });
    downloadURI(dataURL, `${fileName}.${fileType.format}`);

  });

  };


  function ensureEvenDimension(widthOrHeight) {
    return Math.ceil(widthOrHeight) % 2 === 0
      ? Math.ceil(widthOrHeight)
      : Math.ceil(widthOrHeight) - 1;
  }

  /* 
  await ffmpeg.exec([
    "-i", // Input video
    `${fileName}.webm`,
    // "-vf", // Apply video filter (first stage)
    // `scale=trunc(iw/2)*2:trunc(ih/2)*2`, // Scale to dimensions divisible by 2
    "-vf", // Apply video filter (second stage)
    `scale=${width}:${height}`, // Scale to final width and height
    "-c:v", // Specify video codec (optional, defaults to libx264)
    "libx264", // Efficient H.264 video codec
    "-b:v", // Set bitrate for desired quality (adjust as needed)
    "3500k", // 3.5 Mbps bitrate (adjust for desired quality)
    "-an", // Disable audio encoding (no audio in final video)
    "-vsync",
    "2",
    "-r",
    `${frameRate}`,
    "-preset", // Use faster preset
    "fast",
    `${fileName}.mp4` // Output video
  ]);

  */
  const dummyTextId = "dummy-but-important-element-for-webm-mp4-video";
  function addDummyElementToRecordFullDuration() {
    /* This is for recording the canvas until the total given duration in second even the main animation finished*/
    dispatch(
      addStudioElement({
        id: dummyTextId,
        name: "test",
        fontSize: 0,
        scaleX: 0,
        scaleY: 0,
        opacity: 0,
        fill: "#000",
        rotation: 0,
        type: "text",
        stroke: "#969696",
        strokeWidth: 0,
        draggable: false,
        text: "Test",
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
        x: 0,
        y: 0,
        animation: {
          start: 0,
          duration: {
            key: "long",
            number: studioDuration
          },
          key: "big-slide"
        }
      })
    );
  }

  function getSupportedCodec() {
    let supportedType = null;
    let types = [
      "video/webm; codecs=vp9", // Generally considered highest quality
      "video/webm; codecs=h264", // Widely supported with decent quality
      "video/webm; codecs=vp8",
      "video/webm", // Generic webm
      "video/webm; codecs=daala", // Less common
      "video/mpeg",
      "video/mp4" // Most widely supported
    ];

    for (let i in types) {
      if (MediaRecorder.isTypeSupported(types[i])) {
        supportedType = types[i];
        break;
      }
    }
    return supportedType;
  }
  const interrupt = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const downloadAsVideo = async function ({ fileName, fileType }) {

    console.log("filename in downloadAsVideo : ",fileName);
    
    resetZoomBeforeDownload(async () => {
          addDummyElementToRecordFullDuration();
          console.log("filename in downloadAsVideo after resetZoom : ",fileName);
   
          
    const width = ensureEvenDimension(studioLayout.width); // Width of the output video
    const height = ensureEvenDimension(studioLayout.height); // Height of the output video
    const frameRate = 25; // Frame rate of the output video
    let duration = studioDuration * 1000; // Duration of the output video in seconds
    const outputFormat = fileType.format; // Output format ('mp4' or 'webm')

    const canvas = document.querySelector("canvas"),
      ctx = canvas.getContext("2d", { willReadFrequently: true });

    try {
      setLoading(true);

      if (!recorderRef.current) {
        recorderRef.current = new CanvasRecorder(canvas);
      }
      recorderRef.current.start();
      dispatch(setStudioPlay(true));
      await interrupt(duration);

      dispatch(setStudioPlay(false));
      recorderRef.current.stop();

      dispatch(removeStudioElement({ id: dummyTextId }));

      if (outputFormat === "webm") {
        // recorderRef.current.save();
        recorderRef.current.save(fileName);
        setLoading(false);
      } else if (outputFormat === "mp4") {
        var blob = new Blob(recorderRef.current.recordedBlobs, {
          type: getSupportedCodec()
        });
        // recorderRef.current.save();
        console.log("filename where transcode call : ",fileName);
        await transcode({ webm: blob, fileName, frameRate, width, height });
        setLoading(false);
      } else {
        alert("Please select WebM or MP4 format only");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  });

  };
  const downloadLinkToVideo = (url, filename) => {
    console.log(filename,'filename22222333333333333300002---')
    const a = document.createElement("a");
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const onDownload = ({ fileName, fieldType }) => {
    console.log("filename in onDownload : ",fileName);
    resetZoomBeforeDownload(() => {
      if (fieldType.type === "video") {
        downloadAsVideo({ fileName, fileType: fieldType });
      } else {
        downloadAsImage({ fileName, fileType: fieldType });
      }
    });
  };
  
  
  return (
    <DownloadSaveButton
      buttonTitle="Download"
      buttonIcon={<RiDownloadCloud2Line size={22} />}
      headerIcon={<RiDownloadCloud2Line size={22} />}
      headerTitle="Download"
      fieldNameLabel="File name"
      fieldTypeLabel="File Type"
      actionTitle="Download"
      onAction={onDownload}  // Pass fileName here
      className="icon_btn"
      loading={loading}
    />
  );
};

export default HeaderDownloadButton;
