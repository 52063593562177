import { v4 as uuid } from "uuid";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState,useRef } from "react";
import { FiClipboard } from "react-icons/fi";
import { RiPencilLine } from "react-icons/ri";
import { BiSearch } from "react-icons/bi";
import { TbClipboardText } from "react-icons/tb";
import { AiOutlineDelete } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { MdImage, MdColorLens } from "react-icons/md";
import { RiDownloadCloud2Line } from "react-icons/ri";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Modal,
  Button,
  Divider,
  TextField,
  IconButton,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";

import { useAdminRole } from "../../hooks";
import request, { baseURL } from "../../services/request";
import { downloadURI } from "../../utils";
import { getToken } from "../../services/localStorage";
import { setToolsDragElement } from "../../store/toolsSlice";
import EditToolsContentMyContentFilesUpload from "./EditToolsContentMyContentFilesUpload";
import { setTargetEndpoint } from "../../store/requestSlice";
import axios from "axios";

const EditToolsContentMyContentFiles = () => {
  const dispatch = useDispatch();
  const isAdmin = useAdminRole();
  const [modal, setModal] = useState("");
  const [fileName, setFileName] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [selectedContent, setSelectedContent] = useState(null);
  const [clipboardContent, setClipboardContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [wasLastList, setWasLastList] = useState(false);
  const [files, setFiles] = useState([]);
  const [getTempalteFiles,setTemplateFiles] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const { contentScrollEnd } = useSelector((state) => state.tools);
  const { targetEndpoint } = useSelector((state) => state.request);
  const [searchTemplate,setSearchTemplate] = useState("");
  const selectedContentImage = `${baseURL}/${isAdmin ? selectedContent?.template_path : selectedContent?.file_path
    }`;

  const openModal = (mode = "") => setModal(mode);

  const closeModal = () => setModal("");

  const onFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const onContentClick = (content) => {
    setSelectedContent(content);
  };

  const onClipboardContentClick = () => {
    setClipboardContent(selectedContent);
  };

  const onPasteClick = () => { };

  const onDownload = async () => {
    const image = await fetch(`${baseURL}/${selectedContent.file_path}`);
    const blob = await image.blob();
    const dataURL = URL.createObjectURL(blob);

    downloadURI(dataURL, selectedContent.name);
  };

  const onEditClick = () => {
    const fileNameWithoutExtenstion =
      selectedContent.name?.split(".")[0] || selectedContent.name;
    setFileName(fileNameWithoutExtenstion);
    openModal("edit");
  };

  const onDelete = () => {
    openModal("delete");
  };

  const onPreview = () => {
    openModal("preview");
  };

  const deleteFile = async () => {
    setActionLoading(true);

    const token = getToken();

    const url = isAdmin
      ? `templates/delete/${selectedContent.id}`
      : `/files/delete/${selectedContent.id}`;

    try {
      console.log('delete calll.......');
      const res = await request({
        url,
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setFiles(files.filter(item=>item.id!==selectedContent.id))
      }
      console.log(res,'resresres');
      closeModal();
      // setTemplateFiles(!getTempalteFiles);
    } catch (error) {
    } finally {
      setActionLoading(false);
    }
  };

  const editFile = async () => {
    setActionLoading(true);

    let fileNameToRename = "";

    const token = getToken();

    const isTemplate = Number(selectedContent?.template_id) ? true : false;

    const categoryFromStorage = localStorage.getItem("category");

    const category = categoryFromStorage
      ? JSON.parse(categoryFromStorage)
      : null;

    const path = isTemplate
      ? selectedContent.template_path
      : selectedContent.file_path;

    const imageRes = await fetch(`${baseURL}/${path}`);
    const imageFile = await imageRes.blob();

    const newFile = isTemplate
      ? { ...selectedContent.props }
      : { ...selectedContent.props, src: `${baseURL}/${path}` };

    const json = JSON.stringify(newFile);
    let jsonFile = new Blob([json], {
      type: "application/json",
    });

    if (isTemplate) {
      fileNameToRename = fileName;
    } else {
      const lastDot = selectedContent.name.lastIndexOf(".");
      const ext = selectedContent.name.substring(lastDot + 1);
      fileNameToRename = `${fileName}.${ext}`;
    }

    const url = isAdmin
      ? `templates/update/${selectedContent.id}`
      : `/files/update/${selectedContent.id}`;

    const data = new FormData();

    if (isAdmin) {
      data.append("name", fileNameToRename || `template-${uuid()}`);
      data.append("template", imageFile, "template-image.png");
      data.append("template_json", jsonFile, "template-json.json");
      data.append("layout", selectedContent.props.layout.id || 1);
      data.append("category", selectedContent.category || 1);
      data.append("premium", selectedContent.premium || 0);
    } else {
      data.append("template_id", selectedContent.template_id || 0);
      data.append("name", fileNameToRename || `template-${uuid()}`);
      data.append("file", imageFile, "template-image.png");
      data.append("file_json", jsonFile, "template-json.json");
    }

    try {
      const res = await request({
        url,
        data,
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.status === 200) {
        setFiles(files.map(item=>{
          if(item.id === res.data.data.id){
            return {...item, name:res.data.data.name}
          }
          return item
        }))
      }
      closeModal();
      // setTemplateFiles(!getTempalteFiles);
      // await getFiles();
    } catch (error) {
    } finally {
      setActionLoading(false);
    }
  };
  useEffect(() => {
    console.log(files, "templates files");
  }, [files]);
  const getFilesOld = async () => {
    setFileName("");
    setSelectedContent(null);

    setLoading(true);

    const token = getToken();

    const url = isAdmin ? "templates/my" : "/files/my";

    try {
      const res = await request({
        url,
        method: "get",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const templateList = res?.data?.data;
      const promises = templateList.map((item) => getJSONTemplates(item.id));
      const allData = await Promise.allSettled(promises);
      console.log(allData, "allData");
      const templatesData = allData.map((item) => item.data);
      console.log(templatesData, "templatesData");

      const promisesJson = templateList
        ?.filter((item) => item?.template_json != undefined)
        .map(
          (item) => getJSON(item)
          // item
          // getJSON(item?.template_json)
        );
      const allDataJson = await Promise.allSettled(promisesJson);
      console.log("haider ali", allDataJson);
      const templatesDataJson = allDataJson.map((item) => item.data);

      const templates = templateList.map((item, index) => ({
        ...item,
        props: templatesData[index],
        fileJson: templatesDataJson[index],
      }));

      setFiles(templates);
    } catch (error) {
      console.log(error, "Get Files Error");
      setFiles([]);
    } finally {
      setLoading(false);
    }
  };
  const getFiles = async () => {
    setFileName("");
    setSelectedContent(null);
    setLoading(true);

    const token = getToken();

    let url = isAdmin ? "templates/my" : "/files/my";
    if (searchTemplate) {
      url = `files/search?search=${searchTemplate}`;
  }

    try {
      const res = await request({
        url,
        method: searchTemplate? "post": "get",
        params: {
          page: searchTemplate? 1: currentPage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(res, "res");
      const templateList = res?.data?.data?.data;
      // const promises = templateList.map((item) => getJSONTemplates(item.id));
      // const allData = await Promise.all(promises);
      // const templatesData = allData.map((item) => item.data);
      // const templates = templateList.map((item, index) => ({
      //   ...item,
      //   props: templatesData[index],
      // }));
      if (!templateList.length) {
        setWasLastList(true);
        return;
      }

      if(searchTemplate){
        setFiles(templateList)
        setPrevPage(0)
        return;
      }
      if(currentPage ===1){
        setFiles(templateList);
        setPrevPage(currentPage)
        return
      }

      setFiles([...files,...templateList]);
      setPrevPage(currentPage);
    } catch (error) {
      console.log(error, "Get Files Error");
      setFiles([]);
    } finally {
      setLoading(false);
    }
  };

  const getJSON = async (item) => {
    let fileJson;
    // debugger
    console.log(item, "getJSON item");
    // const url = `${baseURL}/${isAdmin ? item.template_path : item.file_json}`;
    const url = `${baseURL}/${isAdmin ? item.template_json : item.file_json}`;

    // fetch(`https://70.35.197.229:3000/${isAdmin ? item.template_path : item.file_json}`)
    //   .then((result) => {
    //     result.json().then((json) => {
    //       fileJson = json
    //       // console.log("fileJson", typeof fileJson, fileJson)
    //     })
    //   })
    //   .catch((error) => {
    //     console.log("error fileJson", typeof error, error)
    //   })
    //   .finally(() => {
    //     // console.log('Finally')
    //   })
    return axios({
      url,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };
  const getJSONTemplates = async (id) => {
    return request({
      url: `getJson/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
  };

  const getTemplate = async () => {
    const res = await fetch('https://jsonplaceholder.typicode.com/todos/1');
    console.log(res,'this res..');
  };

  const CheckFileType = (type) => {

    switch (type) {
      case 'png':
      case 'jpg': return (
        <MdImage color="white" size={"1.5em"} />
      )
      case 'mp4':
      case 'webm': return (
        <MdOutlineOndemandVideo color="white" size={"1.5em"} />
      );
      default : <MdImage color="white" size={"1.5em"} />
    }
  };

  useEffect(() => {
    if (targetEndpoint === "get-files") {
      getFiles();
      dispatch(setTargetEndpoint(""));
    }
  }, [targetEndpoint]);

  useEffect(() => {
    getFiles();
  }, [getTempalteFiles]);

  useEffect(() => {
    if (contentScrollEnd) {
      setCurrentPage(currentPage + 1);
    }
  }, [contentScrollEnd]);

  useEffect(() => {
    if (!wasLastList && prevPage !== currentPage) {
      getFiles();
    }
  }, [currentPage, prevPage, wasLastList]);

  return (
    <>
      <Box mb={2}>
        <EditToolsContentMyContentFilesUpload />
      </Box>
      <Box mb={2}>
      <form className="searchForm" onSubmit={(e)=>{
                    e.preventDefault(); getFiles()}}>
        <div className="SearchItem">
          <input onKeyDown={(e) => {
            if(e.key === "Enter"){
              getTemplate();
            }
          }} type="text" placeholder="Search Template Here" value={searchTemplate} onChange={(e) => {
            setSearchTemplate(e?.target?.value);
          }}></input>
          <button onClick={getTemplate}>
            <IconButton size="small" color="inherit">
              <BiSearch size={"1.5em"} />
            </IconButton>
          </button>
        </div>
        </form>
      </Box>

      {/* <Box>
        <Typography variant="h6" color="white">
          My Content
        </Typography>
      </Box> */}

      <Box mt={2} mb={2}>
        <Divider sx={{ background: "#39444e" }} />
        <Box py={0.5}>
          <Grid container spacing={1} wrap="nowrap" color="white">
            {/* <Grid item>
              <IconButton
                color="inherit"
                onClick={onClipboardContentClick}
                disabled={!selectedContent}
              >
                <FiClipboard size={"1em"} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                onClick={onPasteClick}
                disabled={!selectedContent}
              >
                <TbClipboardText size={"1em"} />
              </IconButton>
            </Grid> */}
            <Grid item>
              <IconButton
                // color="inherit"
                onClick={onEditClick}
                disabled={!selectedContent}
              >
                <RiPencilLine size={"1em"} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                // color="inherit"
                onClick={onDelete}
                disabled={!selectedContent}
              >
                <AiOutlineDelete size={"1em"} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                // color="inherit"
                onClick={onDownload}
                disabled={
                  !selectedContent || selectedContent?.id == 0
                }
              >
                <RiDownloadCloud2Line size={"1em"} />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                // color="inherit"
                onClick={onPreview}
                disabled={!selectedContent}
              >
                <MdOutlineVisibility size={"1em"} />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ background: "#39444e" }} />
      </Box>

      <Box>
        <Grid container spacing={1.5}>
          {console.log("files:::", files)}
          {files.length
            ? files.map((item) => {
              const type = item?.file_type
              const fileImage = `${baseURL}/${isAdmin ? item.template_path : ( item.video_path ? item.video_path :item.file_path)
                }`;
                const getExt = fileImage.split('.').pop()
              return (
                <Grid item xs={6} key={item.id}>
                  <Grid
                    draggable
                    container
                    direction="column"
                    wrap="nowrap"
                    sx={{
                      height: 134,
                      borderRadius: 2,
                    }}
                    className={`flex justify-center flex-col hover:bg-gray-600 cursor-pointer ${selectedContent?.id === item.id
                      ? "border-2 border-gray-300 bg-gray-600"
                      : "border-2 border-transparent bg-gray-700"
                      }`}
                    onDragStart={(e) => {
                      if (item?.props.elements && item?.props.layout) {
                        dispatch(
                          setToolsDragElement({
                            type: "template",
                            // id: `template-${item.id}-${uuid()}`,
                            ...item.props,
                            id: `${item.id}`,
                          })
                        );
                      } else if (item?.props?.type == "image") {
                        console.log({
                          ...item.props,
                          src: fileImage,
                        });
                        dispatch(
                          setToolsDragElement({
                            scaleX: 1,
                            scaleY: 1,
                            opacity: 1,
                            rotation: 0,
                            animation: null,
                            lock: false,
                            draggable: true,
                            ...item,
                            ...item.props,
                            type:
                              item.file_type == "png" ||
                                item.file_type == "jpg" ||
                                item.file_type == "jpeg"
                                ? "image"
                                : "",
                            ...item.props,
                            src: fileImage,
                          })
                        );
                      } else if (item.props.type == "video") {
                        dispatch(
                          setToolsDragElement({
                            scaleX: 1,
                            scaleY: 1,
                            opacity: 1,
                            rotation: 0,
                            type: "video",
                            animation: null,
                            lock: false,
                            draggable: true,

                            // name: `by ${video.user.name}`,
                            // id: `video-${video.id}-${uuid()}`,
                            ...item.props,
                            // ...item.fileJson,
                            src: "https://static.videezy.com/system/resources/previews/000/012/245/original/Fireworks_69_-_4K_res.mp4",
                            width: 200,
                            height: 200,
                          })
                        );
                      } else {
                        dispatch(
                          setToolsDragElement({
                            scaleX: 1,
                            scaleY: 1,
                            opacity: 1,
                            rotation: 0,
                            type: "image",
                            animation: null,
                            lock: false,
                            draggable: true,
                            // width: item.width / 6,
                            // height: item.height / 6,
                            height:
                              fileImage.naturalWidth || item.width || 480,
                            width:
                              fileImage.naturalHeight || item.height || 880,
                            ...item,
                            ...item.props,
                            src: fileImage,
                            // id: Number(item.template_id) || item.props.id,
                            id: `image-${item.id}-${uuid()}`,
                            type:
                              item.file_type == "png" ||
                                item.file_type == "jpg" ||
                                item.file_type == "jpeg"
                                ? "image"
                                : "",
                            // type: Number(item.template_id)
                            //   ? "template"
                            //   : item.props.type,
                          })
                        );
                      }
                    }}
                    onDragEnd={(e) => {
                      dispatch(setToolsDragElement(null));
                    }}
                    onClick={(e) => onContentClick(item)}
                  >
                    <Grid item xs={9}>
                      <Grid
                        item
                        container
                        justifyContent="center"
                        alignContent="center"
                        width="100%"
                        height="100%"
                      >
                       {getExt === 'mp4'?<video src={fileImage} style={{objectFit: "contain", maxHeight: 90}}></video>:
                        <img
                          src={fileImage}
                          alt={item?.name}
                          style={{
                            objectFit: "contain",
                            maxHeight: 90,
                          }}
                        />}
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid
                        item
                        container
                        px={1}
                        alignItems="center"
                        height="100%"
                      >
                        <Grid item>
                          {Number(item.template_id) ? (
                            <MdColorLens color="white" size={"1.5em"} />
                          ) : CheckFileType(type)}
                        </Grid>
                        <Grid item xs zeroMinWidth>
                          <Typography
                            ml={0.75}
                            noWrap={true}
                            color="white"
                            variant="body2"
                          >
                            {item.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
            : []}
        </Grid>
        {loading &&
          <Grid container justifyContent="center" py={2}>
            <CircularProgress />
          </Grid>}
      </Box>

      <Modal open={modal ? true : false} onClose={closeModal}>
        <Box
          px={2}
          py={2}
          sx={{
            top: "50%",
            left: "50%",
            width: modal === "preview" ? 600 : 300,
            boxShadow: 24,
            borderRadius: 4,
            position: "absolute",
            bgcolor: "background.paper",
            transform: "translate(-50%, -50%)",
          }}
        >
          {modal === "delete" && (
            <>
              <Box mb={4} px={1}>
                <Typography mb={0.5} variant="h5" fontWeight="normal">
                  Delete item
                </Typography>
                <Typography variant="body2" fontWeight="normal">
                  Are you sure you wish to delete this item?
                </Typography>
              </Box>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={closeModal}
                    sx={{
                      borderRadius: 2,
                      marginRight: 1.5,
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={actionLoading}
                    variant="contained"
                    onClick={deleteFile}
                    sx={{
                      boxShadow: 0,
                      borderRadius: 2,
                      textTransform: "none",
                    }}
                  >
                    Delete
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          )}

          {modal === "edit" && (
            <>
              <Box mb={4} px={1}>
                <Typography mb={2} variant="h5" fontWeight="normal">
                  Rename File
                </Typography>
                <FormControl fullWidth>
                  <TextField
                    value={fileName}
                    size="small"
                    label="Name"
                    variant="outlined"
                    onChange={onFileNameChange}
                  />
                </FormControl>
              </Box>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={closeModal}
                    sx={{
                      borderRadius: 2,
                      marginRight: 1.5,
                      textTransform: "none",
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={actionLoading}
                    variant="contained"
                    onClick={editFile}
                    sx={{
                      boxShadow: 0,
                      borderRadius: 2,
                      textTransform: "none",
                    }}
                  >
                    Rename
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          )}

          {modal === "preview" && (
            <>
              <Box mb={3} px={1}>
                <Typography mb={0.5} variant="h5" fontWeight="normal">
                  {selectedContent.name}
                </Typography>
                <img src={selectedContentImage} alt="" />
              </Box>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Button
                    variant="text"
                    size="large"
                    onClick={closeModal}
                    sx={{
                      textTransform: "none",
                    }}
                  >
                    Ok
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default EditToolsContentMyContentFiles;
