import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { BiCheckbox } from "react-icons/bi";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { FiImage } from "react-icons/fi";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoText } from "react-icons/io5";
import { RxVideo } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import {
  removeStudioElement,
  removeStudioGroupedElements,
  resetStudioCurrentTarget,
  resetStudioTransformer,
  setStudioCurrentTarget,
  updateStudioElement,
  updateStudioElements,
  updateStudioLayout,
  updateStudioTransformer,
} from "../../store/studioSlice";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { TbLock, TbLockOpen } from "react-icons/tb";
import { AiOutlineDelete } from "react-icons/ai";
import request from "../../services/request";
import { getToken } from "../../services/localStorage";
const extractValueFromElement = (element) => {
  switch (element.type) {
    case "text":
      return element.text;
    case "menu":
      return element?.rows[0]?.elements[0]?.props?.text;
    case "video":
      return element.name;
    case "image":
      return element.name;
    default:
      return element?.type;
  }
};
const extractIconFromElement = (element) => {
  switch (element.type) {
    case "text":
      return (
        <Box sx={{ width: "1em", height: "1em", aspectRatio: 1 / 1 }}>
          <IoText size={20} cursor="pointer" />
        </Box>
      );
    case "menu":
      return (
        <Box sx={{ width: "1em", height: "1em", aspectRatio: 1 / 1 }}>
          <BsFillMenuButtonWideFill size={20} cursor="pointer" />
        </Box>
      );
    case "video":
      return (
        <Box sx={{ width: "1em", height: "1em", aspectRatio: 1 / 1 }}>
          <RxVideo size={20} cursor="pointer" />
        </Box>
      );
    case "image":
      return (
        <Box sx={{ width: "1em", height: "1em", aspectRatio: 1 / 1 }}>
          <FiImage size={20} cursor="pointer" />
        </Box>
      );
    default:
      return (
        <Box sx={{ width: "1em", height: "1em", aspectRatio: 1 / 1 }}>
          <BiCheckbox size={20} cursor="pointer" />
        </Box>
      );
  }
};
const EditToolsContentLayers = () => {
  const dispatch = useDispatch();
  const { studioId, studioElements, studioCurrentTarget, studioTransformer } =
    useSelector((state) => state.studio.present);
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const [edit, setedit] = useState(true);
  const selectedElementType = selectedElement?.type;
  const selectedElementDraggable = selectedElement?.draggable;
  const selectedElementVisibility = selectedElement?.visible;
  const selectedGroupElements = studioTransformer.nodes;
  const isGroupSelected = selectedGroupElements.length > 1;
  const isSelectedElementSetAsBackground = selectedElement?.setAsBackground;

  const handleOnLock = (elementId) => {
    const updatedElements = studioElements.map((element) => {
      if (element.id === studioCurrentTarget) {
        return {
          ...element,
          draggable: !element.draggable,
        };
      }
      return element;
    });

    dispatch(updateStudioElements(updatedElements));
  };

  const handleVisible = (elementId) => {
    const updatedElements = studioElements.map((element) => {
      if (element.id === elementId) {
        return {
          ...element,
          visible: !element.visible,
        };
      }
      return element;
    });

    dispatch(updateStudioElements(updatedElements));
  };

  const onDelete = (id) => {
    if (studioCurrentTarget === studioId) {
      dispatch(updateStudioLayout({ fill: "#fff" }));
    }

    if (isGroupSelected) {
      const selectedGroupElementsIds = selectedGroupElements.map((item) =>
        item.id()
      );
      dispatch(removeStudioGroupedElements(selectedGroupElementsIds));
    } else {
      dispatch(removeStudioElement());
    }
    dispatch(resetStudioCurrentTarget());
    dispatch(resetStudioTransformer());
  };

  const studio = useSelector((state) => state.studio.present);
  const [layers, setLayers] = useState([]);

  // useEffect(() => {
  //   console.log("layer useEffect", layers)
  //   layers?.forEach((el, i) => {
  //     if (el.type != 'shape') {
  //       request({
  //         url: el.type === 'video' && el.video_img || el.src,
  //         headers: {
  //           Authorization: `Bearer ${getToken()}`
  //         }
  //       }).then(res => {
  //         console.log(i, el.type, res, "layer el response");
  //       }).catch(err => {
  //         console.log(i, el.type, err, "layer el error");
  //       }).finally((fin) => {
  //         console.log(i, el.type, fin, "layer el finally");
  //       })

  //     }
  //   })
  // }, [layers])
  useEffect(() => {
    let elements = [];
    studioElements.map((el, i) => {
      const initializedElement = {
        ...el,
        id: el.id,
        type: el.type,
        name: el.name,
        value: extractValueFromElement(el),
        icon: extractIconFromElement(el),
        visible: el.visible !== undefined ? el.visible : true, // Initialize visible property if not set
      };
      elements.push(initializedElement);
    });
    setLayers(elements);
    console.log({ elements })
  }, [studioElements]);

  const onClickItem = (e, ele, ref) => {
    const elementNode = studio.stage.findOne(`#${ele.id}`);
    dispatch(updateStudioTransformer({ nodes: [elementNode] }));
    dispatch(setStudioCurrentTarget(ele?.id));
    const selectionNode = studio.stage.findOne("#studio-selection");
    const selectionBoxClient = selectionNode.getClientRect();
    if (!selectionNode.visible()) return;
    selectionNode.visible(false);
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const updatedLayers = [...layers].reverse();
    const [draggedItem] = updatedLayers.splice(source.index, 1);
    updatedLayers.splice(destination.index, 0, draggedItem);
    const reversedLayers = updatedLayers.reverse();
    const updatedElements = reversedLayers.map((el) => {
      const originalElement = studioElements.find((item) => item.id === el.id);

      if (originalElement) {
        return {
          ...originalElement,
        };
      }
      return el;
    });
    dispatch(updateStudioElements(updatedElements));
    dispatch(resetStudioTransformer());
  };
  return (
    <>
      <Box pb={1.5}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Grid
                item
                xs
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {layers &&
                  layers
                    ?.slice()
                    .reverse()
                    .map((el, i) => (
                      <Draggable
                        key={el.id + ""}
                        draggableId={el.id + ""}
                        index={i}
                      >
                        {(provided) => (
                          <Box
                            className='drag-section'
                            mt={1}
                            px={1}
                            onClick={(e) => onClickItem(e, el)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={provided.draggableProps.style}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              borderRadius: 1,
                              backgroundColor:
                                selectedElement?.id === el?.id
                                  ? "#1F2937"
                                  : "#fff",
                              color: selectedElement?.id === el?.id ? "#fff" : "",
                              border: studioCurrentTarget === el?.id ? "1px solid red" : "1px solid #ededed",
                              "&:hover": {
                                // backgroundColor: "#1F293710",
                                border: "1px solid red",
                              },
                            }}
                          >
                            {(el?.visible) ? (
                              <FaEye
                                size={30}
                                className="cursor-pointer"
                                onClick={() => handleVisible(el.id)}
                              />
                            ) : (
                              <FaEyeSlash
                                size={30}
                                className="cursor-pointer"
                                onClick={() => handleVisible(el.id)}
                              />
                            )}
                            {
                              <IconButton
                                disabled={selectedElement?.id !== el?.id}
                                size="small"
                                onClick={() => handleOnLock(el.id)}
                              >
                                {!el?.draggable ? (
                                  <TbLock
                                    color={
                                      selectedElement?.id === el?.id
                                        ? "#fff"
                                        : "#000"
                                    }
                                    className="cursor-pointer"
                                    size="1.2em"
                                  />
                                ) : (
                                  <TbLockOpen
                                    color={
                                      selectedElement?.id === el?.id
                                        ? "#fff"
                                        : "#000"
                                    }
                                    className="cursor-pointer"
                                    size="1.2em"
                                  />
                                )}
                              </IconButton>
                            }
                            <Box sx={{ cursor: "pointer", width: "100%" }}>
                              <Typography variant="body2" fontWeight={"bold"}>
                                {el.type?.toUpperCase()}
                              </Typography>
                              <Typography className="itemName" paddingLeft={1} variant="body2">
                                {el?.value}
                              </Typography>
                            </Box>
                            {
                              el.type !== "text" ?
                                <Box
                                  component="img"
                                  sx={{
                                    height: "3em",
                                    width: "3em",
                                    padding: ".3em",
                                    minHeight: '3em',
                                    minWidth: '3em',
                                    // maxHeight: { xs: "3em", md: "3em" },
                                    // maxWidth: { xs: "3em", md: "3em" },
                                    borderRadius: '0.5em'
                                  }}
                                  alt="Image"
                                  src={el.type === 'video' && el.video_img || el.src}
                                /> : null
                            }
                            <Box sx={{ paddingRight: ".3em" }}>{el.icon}</Box>
                            <IconButton
                              className="deleteicon"
                              disabled={selectedElement?.id !== el?.id}
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                onDelete(el?.id);
                              }}
                            >
                              <AiOutlineDelete
                                color={
                                  selectedElement?.id === el?.id ? "#fff" : "#000"
                                }
                                className="cursor-pointer hover-icon"
                                size="1.2em"
                              />
                            </IconButton>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </>
  );
};

export default EditToolsContentLayers;
