import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {configureStore} from "@reduxjs/toolkit";
import undoable, {excludeAction} from "redux-undo";

import auth from "./authSlice";
import tools from "./toolsSlice";
import studio from "./studioSlice";
import request from "./requestSlice";
import menu from "./menuSlice";

const reducers = combineReducers({
    auth,
    tools,
    menu,
    request,
    studio: undoable(studio, {
        filter: excludeAction([
            "studio/setStage",
            "studio/setStudioPlay",
            "studio/setStudioWidth",
            "studio/setStudioHeight",
            "studio/updateStudioTransformer",
        ]),
    }),
});

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({serializableCheck: false}),
});
