import {
  Grid,
  Menu,
  Button,
  Slider,
  MenuItem,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { AiOutlineZoomIn } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { TfiAngleUp, TfiAngleDown } from "react-icons/tfi";

import { layoutScaleRelativeToPoint } from "../../utils";
import { updateStudioLayout } from "../../store/studioSlice";

const ZOOM_SCALES = [
  {
    key: 25,
    name: "25%",
  },
  {
    key: 50,
    name: "50%",
  },
  {
    key: 75,
    name: "75%",
  },
  {
    key: 90,
    name: "90%",
  },
  {
    key: 100,
    name: "100%",
  },
  {
    key: 125,
    name: "125%",
  },
  {
    key: 150,
    name: "150%",
  },
  {
    key: 200,
    name: "200%",
  },
  {
    key: 120,
    name: "Full Height",
  },
  {
    key: 85,
    name: "Full Visible",
  },
];

const StudioZoom = () => {
  const dispatch = useDispatch();
  const { stage, studioLayout } = useSelector((state) => state.studio.present);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [zoom, setZoom] = useState(studioLayout.zoomScale);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onZoomScaleChange = (value) => {
    closeMenu();
    setZoom(value.key);
    updateLayoutScale({ changeImmediately: false, zoomScale: value.key });
  };

  const onSliderChange = (value) => {
    setZoom(value);
    updateLayoutScale({ changeImmediately: true, zoomScale: value });
  };

  const onSliderChangeCommitted = (value) => {
    updateLayoutScale({ changeImmediately: false, zoomScale: value });
  };

  const updateLayoutScale = ({ changeImmediately, zoomScale }) => {
    const groupNode = stage?.findOne("#studio-group");

    const point = layoutScaleRelativeToPoint({
      stage,
      node: groupNode,
      changeImmediately,
      scaleBy: zoomScale,
      layout: studioLayout,
    });

    if (changeImmediately) return;

    dispatch(
      updateStudioLayout({
        zoomScale,
        id: studioLayout.id,
        width: studioLayout.width,
        height: studioLayout.height,
        x: (stage.width() - studioLayout.width) / 2,
        y: (stage.height() - studioLayout.height) / 2,
        group: {
          x: point.position.x,
          y: point.position.y,
          scaleX: point.scale.x,
          scaleY: point.scale.y,
        },
      })
    );
  };

  useEffect(() => {
    setZoom(studioLayout.zoomScale);
  }, [studioLayout]);

  return (
    <Box
      className="absolute bottom-5 right-0 left-0 m-auto"
      sx={{
        width: 100,
        background: "#d1cbcb",
        borderRadius: 2,
        color:"white",
        filter: "drop-shadow(0px 3px 5px rgba(0,0,0,0.075))",
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={5}>
          <Button
            fullWidth
            onClick={openMenu}
            startIcon={<AiOutlineZoomIn />}
            sx={{
              color: "#333",
              paddingLeft: 2,
              textTransform: "none",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              fontWeight="bold"
              variant="subtitle1"
              sx={{ marginLeft: 0.5, marginRight: 1.5 }}
            >
              {parseInt(zoom, 10)}%
            </Typography>
            {open ? (
              <TfiAngleDown size={"0.8em"} />
            ) : (
              <TfiAngleUp size={"0.8em"} />
            )}
          </Button>
          <Menu
            open={open}
            anchorEl={anchorEl}
            onClose={closeMenu}
            marginThreshold={65}
            PaperProps={{
              elevation: 0,
              sx: {
                borderRadius: "0.5rem",
                filter: "drop-shadow(0px 3px 5px rgba(0,0,0,0.16))",
              },
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            {ZOOM_SCALES.map((item, index) => (
              <MenuItem
                value={item}
                key={item.key}
                onClick={() => onZoomScaleChange(item)}
              >
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
        {/* <Grid item xs pt={0} mb={-1} pr={2.5}>
          <Slider
            min={25}
            max={400}
            step={10}
            size="small"
            style={{ color: "#5DBBE4" }}
            disableSwap
            value={zoom}
            onChange={(e, value) => onSliderChange(value)}
            onChangeCommitted={(e, value) => onSliderChangeCommitted(value)}
            className="properties-animation-slider"
          />
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default StudioZoom;
