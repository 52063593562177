import { Box, Grid, Stack } from "@mui/material";

import TextFontSize from "./TextFontSize";
import TextFontFamily from "./TextFontFamily";
import TextFontWeight from "./TextFontWeight";
import TextFontItalic from "./TextFontItalic";
import TextFontUnderline from "./TextFontUnderline";
import SideBarHeading from "../../Heading";
import { useSelector } from "react-redux";

const PropertiesPanelText = () => {
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  // console.log("INdex.selectedElement", selectedElement);
  return (
    <Box pt={1} pl={1}>
      <Grid
        justifyContent={"space-between"}
        container
        alignItems="center"
        spacing={1}
        mb={2}
      >
        <SideBarHeading content={"Size"} />
        <Grid item>
          <TextFontSize />
        </Grid>
      </Grid>

      <Grid
        justifyContent={"space-between"}
        container
        alignItems="center"
        spacing={1}
        mb={2}
      >
        <SideBarHeading
          content={`${selectedElement.type === "menu" ? "Font 1" : "Font"}`}
        />
        <TextFontFamily
          dialect={`${selectedElement.type === "menu" ? "odd" : ""}`}
        />
      </Grid>

      <Grid
        justifyContent={"space-between"}
        container
        alignItems="center"
        spacing={1}
        mb={2}
      >
        <SideBarHeading
          content={`${selectedElement.type === "menu" ? "Style 1" : "Style"}`}
        />
        <Grid alignItems="center">
          <TextFontWeight
            dialect={`${selectedElement.type === "menu" ? "odd" : ""}`}
          />
          <TextFontItalic
            dialect={`${selectedElement.type === "menu" ? "odd" : ""}`}
          />
          <TextFontUnderline
            dialect={`${selectedElement.type === "menu" ? "odd" : ""}`}
          />
        </Grid>
      </Grid>

      {selectedElement.type === "menu" ? (
        <Grid
          justifyContent={"space-between"}
          container
          alignItems="center"
          spacing={1}
          mb={2}
        >
          <SideBarHeading content={"Font 2"} />
          <TextFontFamily dialect="even" />
        </Grid>
      ) : null}

      {selectedElement.type === "menu" ? (
        <Grid
          justifyContent={"space-between"}
          container
          alignItems="center"
          spacing={1}
        >
          <SideBarHeading content={"Style 2"} />
          <Grid alignItems="center">
            <TextFontWeight dialect="even" />
            <TextFontItalic dialect="even" />
            <TextFontUnderline dialect="even" />
          </Grid>
        </Grid>
      ) : null}
    </Box>
  );
};

export default PropertiesPanelText;
