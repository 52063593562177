import { v4 as uuid } from "uuid";
import { useCallback, useEffect, useRef } from "react";
import { Stage, Layer, Group, Rect, Circle } from "react-konva";
import { useSelector, useDispatch } from "react-redux";

import Header from "../Header";
import EditTools from "../EditTools";
import StudioZoom from "./StudioZoom";
import Properties from "../Properties";
import StudioElements from "./StudioElements";
import StudioTemplate from "./StudioTemplate";
import StudioSelection from "./StudioSelection";
import StudioLayoutGrid from "./StudioLayoutGrid";
import StudioTransformer from "./StudioTransformer";
import StudioSetAsBackground from "./StudioSetAsBackground";
import {
  setStage,
  updateStudio,
  addStudioElement,
  resetStudioTransformer,
} from "../../store/studioSlice";
import {
  initStudio,
  onStageZoom,
  onStageClick,
  onStageMouseUp,
  onStageMouseDown,
  onStageMouseMove,
  updateStudioFrame,
} from "./Studio.logic";
import { TEXT_ELEMENT_DEFAULT_PROPS } from "../../constants";

import AppStyle from "../../App.module.scss";
import StudioStyle from "./Studio.module.scss";
import {
  setMenuList,
  setSelectedMenuGroup,
  setShowAllMenu,
} from "../../store/menuSlice";
import StudioTemplates from "./StudioTemplates";
import request from "../../services/request";

const Studio = () => {
  const { menu } = useSelector((state) => state);
  const { menuList } = menu;
  // console.log(menuList, "playebleElements");
  const stage = useRef("");
  const groupRef = useRef();
  const dispatch = useDispatch();
  const studio = useSelector((state) => state.studio.present);
  const {
    studioLayout,
    studioElements,
    studioCurrentTarget,
    studioLayoutGrid,
    studioDuration,
  } = studio;
  const { dragElement } = useSelector((state) => state.tools);
  const clonedDragElement = { ...dragElement };
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const getJSONTemplates = useCallback(async (id) => {
    const res = await request({
      url: `getJson/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (res.data) {
      dispatch(
        updateStudio({
          templateId: id,
          ...res.data,
        })
      );
    } else {
      // console.log(res.data);
    }
  }, []);

  const onTemplateDetect = useCallback(() => {
    let url = new URL(window.location.href);
    let searchParams = new URLSearchParams(url.search);
    const templateId = searchParams.get("templates");
    if (templateId !== null) {
      getJSONTemplates(templateId);
    }
  }, []);
  useEffect(onTemplateDetect, []);
  const checkSetAsBackground = (e) => {
    stage.current.setPointersPositions(e);
    // console.log(clonedDragElement, "clonedDragElement");
    const isValidType = ["video", "image"].includes(clonedDragElement?.type);
    const pointerPosition = groupRef.current.getRelativePointerPosition();
    const setAsBackgroundElement = stage.current.findOne(
      "#studio-set-as-background-rect"
    );

    // Check if an element droped on set as background element
    if (
      isValidType &&
      pointerPosition.x >= 0 &&
      pointerPosition.x <= setAsBackgroundElement.width() &&
      pointerPosition.y >= 0 &&
      pointerPosition.y <= setAsBackgroundElement.height()
    ) {
      dispatch(
        addStudioElement({
          ...clonedDragElement,
          setAsBackground: true,
        })
      );
    } else {
      let x = 0;
      let y = 0;
      let width = 0;
      let height = 0;
      let element = null;
      x = pointerPosition.x;
      y = pointerPosition.y;
      const gridRatio =
        studioLayoutGrid?.width > 1
          ? 1 / studioLayoutGrid?.width
          : 1 / studioLayoutGrid?.height;

      if (isValidType) {
        const fullScale = Math.min(
          studioLayout.width / clonedDragElement.width,
          studioLayout.height / clonedDragElement.height
        );
        width = clonedDragElement.width * fullScale * (gridRatio || 1) * 0.9;
        height = clonedDragElement.height * fullScale * (gridRatio || 1) * 0.9;
      } else {
        width = clonedDragElement.width;
        height = clonedDragElement.height;
      }

      if (clonedDragElement.type === "menu") {
        clonedDragElement.rows = [
          ...clonedDragElement.rows.map((item, index) => ({
            elements: [
              ...item?.elements.map((element) => {
                const props = {
                  ...TEXT_ELEMENT_DEFAULT_PROPS,
                  ...element.props,
                  name: "TextMenu",
                  id: `text-menu-${uuid()}`,
                };

                dispatch(
                  addStudioElement({
                    ...props,
                  })
                );

                return { props };
              }),
            ],
          })),
        ];
      } else {
        if (clonedDragElement?.type === "text") {
          width = clonedDragElement.width;
          height = clonedDragElement.height;
        } else {
          width =
            clonedDragElement.width * (gridRatio < 0.5 ? gridRatio / 0.5 : 1);
          height =
            clonedDragElement.height * (gridRatio < 0.5 ? gridRatio / 0.5 : 1);
        }
      }

      dispatch(
        addStudioElement({
          ...clonedDragElement,
          x,
          y,
          width,
          height,
        })
      );
    }
  };

  const updateLayoutFromStorageOnPageLoad = () => {
    const templateFromStorage = localStorage.getItem("template");
    const template = templateFromStorage
      ? JSON.parse(templateFromStorage)
      : null;

    if (template) {
      if (template.menuList !== undefined) {
        dispatch(setMenuList(template?.menuList));
      }
      dispatch(
        updateStudio({
          grid: template.grid,
          layout: template.layout,
          elements: template.elements,
          duration: template.duration,
          menuList: template.elements.length === 0 ? [] : template?.menuList,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setStage(stage.current));
    initStudio({ dispatch });
    updateStudioFrame({ studio, dispatch });
    updateLayoutFromStorageOnPageLoad();
  }, []);

  useEffect(() => {
    window.onbeforeunload = function () {
      localStorage.setItem(
        "template",
        JSON.stringify({
          layout: studioLayout,
          grid: studioLayoutGrid,
          elements: studioElements,
          duration: studioDuration,
          menuList: studioElements.length === 0 ? [] : menuList,
        })
      );
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [
    studioElements,
    studioLayout,
    studioLayoutGrid,
    studioDuration,
    menuList,
  ]);
  useEffect(() => {
    console.log(studioElements, "studioElements for now");
  }, [studioElements]);

  const handleDoubleClick = (e) => {
    // console.log("selectedElement=>>inside ::::", selectedElement);
    if (selectedElement?.type === "menu") {
      dispatch(setShowAllMenu(true));
      dispatch(setSelectedMenuGroup(selectedElement));
      dispatch(resetStudioTransformer());
    }
  };

  return (
    <main className={AppStyle.main}>
      <div className={AppStyle.mainContentWrapper}>
        <Header />
        <div className={AppStyle.mainContent}>
          <EditTools />
          <div
            className="studio-main-container"
            style={{ width: "100%", maxWidth: 1088 }}
          >
            <div
              className={`${StudioStyle.wrapper} studio-container`}
              id="studio-element"
              style={{ width: studioLayout.width, height: studioLayout.height }}
              onDrop={(e) => {
                e.preventDefault();
                if (dragElement.type === "template") {
                  const layout = {
                    ...dragElement.layout,
                    width:
                      dragElement.layout.width * (studioLayout.zoomScale / 100),
                    height:
                      dragElement.layout.height *
                      (studioLayout.zoomScale / 100),
                  };
                  // console.log({
                  //   layout,
                  //   templateId: dragElement.id,
                  //   elements: dragElement.elements,
                  //   duration: dragElement.duration,
                  //   category: dragElement.category,
                  // });
                  dispatch(
                    updateStudio({
                      layout,
                      templateId: dragElement.id,
                      elements: dragElement.elements,
                      duration: dragElement.duration,
                      category: dragElement.category,
                    })
                  );
                } else {
                  checkSetAsBackground(e);
                }
              }}
              onDragOver={(e) => {
                e.preventDefault();
              }}
            >
              <Stage
                ref={stage}
                id="studio-template"
                width={studioLayout.width}
                height={studioLayout.height}
                onWheel={(e) => onStageZoom(e, { dispatch })}
                onClick={(e) => {
                  onStageClick(e, { studio, dispatch });
                }}
                onMouseUp={(e) => {
                  if (e.target.attrs.type !== "shape") {
                    onStageMouseUp(e, { studio, dispatch });
                  }
                }}
                onMouseMove={(e) => onStageMouseMove(e, { studio, dispatch })}
                onMouseDown={(e) => onStageMouseDown(e, { studio, dispatch })}
              >
                <Layer id="studio-layer">
                  <Group
                    ref={groupRef}
                    id="studio-group"
                    clipX={0}
                    clipY={0}
                    clipWidth={studioLayout.width}
                    clipHeight={studioLayout.height}
                    onDblClick={handleDoubleClick}
                    // x={
                    //   (studio.width -
                    //     studioLayout.width * studioLayout.group.scaleX) /
                    //   2
                    // }
                    // y={
                    //   (studio.height -
                    //     studioLayout.height * studioLayout.group.scaleY) /
                    //   2
                    // }
                    // scaleX={studioLayout.group.scaleX}
                    // scaleY={studioLayout.group.scaleY}
                  >
                    <StudioTemplate />
                    <StudioElements />
                    <StudioSetAsBackground />
                    <StudioLayoutGrid />
                  </Group>
                  <StudioSelection />
                  <StudioTransformer />
                </Layer>
              </Stage>
              <StudioZoom />
            </div>
          </div>
        </div>
      </div>
      <Properties />
    </main>
  );
};

export default Studio;
