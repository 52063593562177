import { TbStack2 } from "react-icons/tb";
import {
  MdFlipToBack,
  MdFlipToFront,
  MdOutlineFilter1,
  MdOutlineFilter2,
} from "react-icons/md";

import PropertiesDropdown from "./PropertiesDropdown";

import { moveStudioElement } from "../../store/studioSlice";

const PropertiesPanelOrder = () => {
  const flipItems = [
    {
      text: "Forward",
      icon: <MdOutlineFilter2 size={16} />,
      onChange: ({ studio, dispatch }) => {
        const { studioElements, studioTransformer } = studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            dispatch(moveStudioElement({ element: item, to: "next" }));
          }
        });
      },
    },
    {
      text: "Backward",
      icon: <MdOutlineFilter1 size={16} />,
      onChange: ({ studio, dispatch }) => {
        const { studioElements, studioTransformer } = studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            dispatch(moveStudioElement({ element: item, to: "prev" }));
          }
        });
      },
    },
    {
      text: "To front",
      icon: <MdFlipToFront size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { studioElements, studioTransformer } = studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            dispatch(moveStudioElement({ element: item, to: "last" }));
          }
        });
      },
    },
    {
      text: "To back",
      icon: <MdFlipToBack size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { studioElements, studioTransformer } = studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            dispatch(moveStudioElement({ element: item, to: "first" }));
          }
        });
      },
    },
  ];

  return (
    <PropertiesDropdown
      items={flipItems}
      buttonText="Order"
      buttonIcon={<TbStack2 />}
    />
  );
};

export default PropertiesPanelOrder;
