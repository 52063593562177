import Konva from "konva";
import useImage from "use-image";
import { v4 as uuid } from "uuid";
import { Group, Image as KonvaImage, Line } from "react-konva";
import { useSelector, useDispatch } from "react-redux";
import { useState, useRef, useEffect, useMemo } from "react";

import { getCrop } from "../../utils";
import animations from "../../animations";
import Border from "../../filters/Border";
import TextElement from "./StudioElementText";
import MenuElement from "./StudioMenuElement";
import {
  addStudioElement,
  moveStudioElement,
  removeStudioElement,
  updateStudioElement,
  updateStudioSelection,
  resetStudioTransformer,
  setStudioCurrentTarget,
  updateStudioColorPicker,
  deselectStudioGroupedElements,
} from "../../store/studioSlice";
import { Box } from "@mui/material";
import { Html } from "react-konva-utils";

const ARROW_KEYS = ["ArrowLeft", "ArrowUp", "ArrowRight", "ArrowDown"];

const LineHorizontalElement = ({ x = 0, y = 0 }) => {
  const { studioLayout } = useSelector((state) => state.studio.present);
  return (
    <Line
      x={x}
      y={y}
      dash={[7, 7]}
      stroke="#1565c0"
      strokeWidth={2}
      points={[0, 0, studioLayout.width, 0]}
    />
  );
};

const LineVerticalElement = ({ x = 0, y = 0 }) => {
  const { studioLayout } = useSelector((state) => state.studio.present);
  return (
    <Line
      x={x}
      y={y}
      dash={[5, 5]}
      stroke="#1565c0"
      strokeWidth={2}
      points={[0, 0, 0, studioLayout.height]}
    />
  );
};

const StudioElement = ({ attributes, itemId }) => {
  const dispatch = useDispatch();
  const elementRef = useRef();
  const elementType = attributes.type;
  const [tween, setTween] = useState();
  const [altKey, setAltKey] = useState(false);
  const [ctrlKey, setCtrlKey] = useState(false);
  const [imageSrc] = useImage(attributes.src, "anonymous");
  // console.log({ imageSrc, attributes }, attributes.src)
  const studio = useSelector((state) => state.studio.present);
  const {
    stage,
    studioCurrentTarget,
    studioElements,
    studioLayout,
    studioPlay,
    studioDuration,
  } = studio;
  const isText = elementType === "text" ? true : false;
  const isShape = elementType === "shape" ? true : false;
  const isImage = elementType === "image" ? true : false;
  const isVideo = elementType === "video" ? true : false;
  const isMenu = elementType === "menu" ? true : false;
  const isWidget = elementType === "widget" ? true : false;
  const videoSrc = isVideo ? attributes.src : "";
  const widgetLink = isWidget ? attributes.link : "";
  const selectedNode = stage?.findOne(`#${attributes.id}`);
  const selectedElement = studioElements.find(
    (item) => item.id === attributes.id
  );
  const currentElementAnimation = selectedElement?.animation;
  const layer = stage?.findOne("#studio-layer");
  const isSelectedElementSetAsBackground = selectedElement?.setAsBackground;
  const [lineHorizontalElementProps, setLineHorizontalElementProps] = useState({
    x: 0,
    y: 0,
    show: false,
  });

  const [lineVerticalElementProps, setLineVerticalElementProps] = useState({
    x: 0,
    y: 0,
    show: false,
  });
  // console.log("New shapeee", elementRef.current);
  // console.log("New shapeee", elementRef.current);
  const playVideo = () => {
    videoElement.play();
    const animation = new Konva.Animation(() => { }, layer);
    animation.start();
    return () => animation.stop();
  };

  const isImageOrVideo = isImage || isVideo;

  const isElementDraggable = elementRef?.current?.attrs?.draggable;



  const stopVideo = () => {
    videoElement.pause();
    videoElement.currentTime = 1;
  };

  const startAnimation = () => {
    dispatch(deselectStudioGroupedElements());
    dispatch(resetStudioTransformer());

    if (currentElementAnimation) {
      if (currentElementAnimation.key === "none") return;
      const elementAnimation = animations.find(
        (item) => item.key === currentElementAnimation.key
      );
      const currentElementAnimationDelay = currentElementAnimation?.start || 0;

      const elementAnimationTween = elementAnimation?.animation({
        studio,
        node: selectedNode,
        duration: studioDuration,
      });

      setTimeout(() => {
        elementAnimationTween?.play();
        setTween(elementAnimationTween);
      }, currentElementAnimationDelay * 1000);
    }

    if (videoElement) {
      playVideo();
    }
  };

  const stopAnimation = () => {
    if (currentElementAnimation) {
      if (currentElementAnimation.key === "none") return;
      tween?.finish();
    }

    if (videoElement) {
      stopVideo();
    }
  };

  const onContextMenu = (event) => {
    const isCtrlKeyPressed = event.ctrlKey;
    if (isCtrlKeyPressed) {
      event.preventDefault();
    }
  };

  const onKeyDownEvent = (event) => {
    const isAltKeyPressed = event.altKey;
    const isCtrlKeyPressed = event.ctrlKey;
    if (isAltKeyPressed) setAltKey(true);
    else setAltKey(false);

    if (isCtrlKeyPressed) {
      setCtrlKey(true);
      document.getElementById("root").style.cursor = "pointer";
    } else {
      setCtrlKey(false);
      document.getElementById("root").style.cursor = "default";
    }
  };

  const onKeyUpEvent = () => {
    setAltKey(false);
    setCtrlKey(false);
    document.getElementById("root").style.cursor = "default";
  };

  useEffect(() => {
    if (studioPlay) startAnimation();
    else if (studioPlay === false) stopAnimation();
  }, [studioPlay]);

  useEffect(() => {
    if (isShape || isWidget) {
      selectedNode?.clearCache();
      selectedNode?.cache();
      layer?.draw();
    }
  }, [attributes]);

  useEffect(() => {
    dispatch(updateStudioColorPicker(""));
  }, [studioCurrentTarget]);

  useEffect(() => {
    const isValidType = ["video", "image"].includes(attributes?.type);

    if (!isValidType) return;

    dispatch(resetStudioTransformer());

    if (isSelectedElementSetAsBackground) {
      const oldBackgroundElement = studioElements.find(
        (item) => item.setAsBackground && item.id !== selectedElement.id
      );

      if (oldBackgroundElement) {
        dispatch(removeStudioElement({ id: oldBackgroundElement?.id }));
        dispatch(setStudioCurrentTarget(selectedElement.id));
      }

      const fillScale = Math.max(
        studioLayout.width / selectedElement.width,
        studioLayout.height / selectedElement.height
      );

      dispatch(moveStudioElement({ element: selectedElement, to: "first" }));

      dispatch(
        updateStudioElement({
          id: attributes.id,
          width: studioLayout.width,
          height: studioLayout.height,
          x: studioLayout.width / 2,
          y: studioLayout.height / 2,
        })
      );
    }
  }, [isSelectedElementSetAsBackground, studioLayout]);

  useEffect(() => {
    document.addEventListener("contextmenu", onContextMenu);
    document.addEventListener("keydown", onKeyDownEvent);
    document.addEventListener("keyup", onKeyUpEvent);

    return () => {
      document.removeEventListener("contextmenu", onContextMenu);
      document.removeEventListener("keydown", onKeyDownEvent);
      document.removeEventListener("keyup", onKeyUpEvent);
    };
  }, []);

  const videoElement = useMemo(() => {
    if (!videoSrc) return "";
    const element = document.createElement("video");
    element.src = videoSrc;
    element.muted = true;
    element.crossOrigin = "Anonymous";
    return element;
  }, [videoSrc]);

  useEffect(() => {
    if (!videoElement) return;
    const onload = function () {
      playVideo();
      stopVideo();
    };
    videoElement.addEventListener("loadeddata", onload);
    return () => {
      videoElement.removeEventListener("loadeddata", onload);
    };
  }, [videoElement]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!ARROW_KEYS.includes(e.code)) return;
      e.preventDefault();

      if (selectedNode?.id() !== studioCurrentTarget) return;

      const distance = 5;
      const newPosition = { ...selectedNode?.position() };

      switch (e.code) {
        case "ArrowLeft":
          selectedNode.x(newPosition.x - distance);
          break;
        case "ArrowUp":
          selectedNode.y(newPosition.y - distance);
          break;
        case "ArrowRight":
          selectedNode.x(newPosition.x + distance);
          break;
        case "ArrowDown":
          selectedNode.y(newPosition.y + distance);
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [studioCurrentTarget]);

  const onTransform = (e) => {
    if (!isVideo && !isImage) return;
    const node = e.target;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    node.clearCache();

    node.setAttrs({
      scaleX: scaleX < 0 ? -1 : 1,
      scaleY: scaleY < 0 ? -1 : 1,
      width: node.width() * node.scaleX(),
      height: node.height() * node.scaleY(),
      offsetX: (node.width() * node.scaleX()) / 2,
      offsetY: (node.height() * node.scaleY()) / 2,
    });

    layer?.draw();
  };

  const onTransformEnd = () => {
    const node = elementRef.current;
    const scaleX = node.scaleX();
    const scaleY = node.scaleY();

    dispatch(
      updateStudioElement({
        x: node.x(),
        y: node.y(),
        id: attributes.id,
        rotation: node.rotation(),
        width: Math.max(node.width() * scaleX),
        height: Math.max(node.height() * scaleY),
      })
    );

    const nodeScale = node.scale();

    node.scale({ x: nodeScale.x < 0 ? -1 : 1, y: nodeScale.y < 0 ? -1 : 1 });

    layer?.draw();
  };

  const onDragEnd = (e) => {
    const node = e.target;
    // console.log(attributes, { x: node.x(), y: node.y() }, "ahmadali");

    dispatch(
      updateStudioElement({
        x: node.x(),
        y: node.y(),
        id: attributes.id,
      })
    );

    setLineHorizontalElementProps({
      ...lineHorizontalElementProps,
      show: false,
    });
    setLineVerticalElementProps({ ...lineVerticalElementProps, show: false });
  };

  const onClick = (event) => {
    if (isWidget && widgetLink && ctrlKey) {
      window.open(widgetLink, "_blank", "noreferrer");
    }

    dispatch(setStudioCurrentTarget(attributes.id));
    dispatch(updateStudioSelection({ visible: false }));
  };

  const onDragStart = () => {
    document.getElementById("root").style.cursor = "default";
    if (!altKey) return;
    const cloneNode = elementRef?.current?.clone();
    dispatch(
      addStudioElement({
        ...cloneNode.attrs,
        id: `${elementType}-${uuid()}`,
      })
    );
  };

  const onDragMove = (e) => {
    const Y_THRESHOLD = 1;
    const X_THRESHOLD = 1;
    const node = e.target;
    const gridLines = stage.find(".GridLine");
    const gridLinesClientRect = gridLines.map((item) => ({
      x: item.x(),
      y: item.y(),
      width: item.width(),
      height: item.height(),
    }));
    const elements = [...studioElements, ...gridLinesClientRect];

    const elementsTopY = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.y - item.height / 2 - (node.y() - node.height() / 2)) <
        Y_THRESHOLD
      );
    });

    const elementsTopAndBottomY = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.y - item.height / 2 - (node.y() + node.height() / 2)) <
        Y_THRESHOLD
      );
    });

    const elementsCenterY = elements.find((item) => {
      return item.id !== node.id() && Math.abs(item.y - node.y()) < Y_THRESHOLD;
    });

    const elementsBottomY = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.y + item.height / 2 - (node.y() + node.height() / 2)) <
        Y_THRESHOLD
      );
    });

    const elementsBottomAndTopY = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.y + item.height / 2 - (node.y() - node.height() / 2)) <
        Y_THRESHOLD
      );
    });

    const elementsLeftX = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.x - item.width / 2 - (node.x() - node.width() / 2)) <
        X_THRESHOLD
      );
    });

    const elementsLeftAndRightX = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.x - item.width / 2 - (node.x() + node.width() / 2)) <
        X_THRESHOLD
      );
    });

    const elementsCenterX = elements.find((item) => {
      return item.id !== node.id() && Math.abs(item.x - node.x()) < X_THRESHOLD;
    });

    const elementsRightX = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.x + item.width / 2 - (node.x() + node.width() / 2)) <
        X_THRESHOLD
      );
    });

    const elementsRightAndLeftX = elements.find((item) => {
      return (
        item.id !== node.id() &&
        Math.abs(item.x + item.width / 2 - (node.x() - node.width() / 2)) <
        X_THRESHOLD
      );
    });

    if (elementsTopY || elementsBottomAndTopY) {
      setLineHorizontalElementProps({
        ...lineHorizontalElementProps,
        show: true,
        y: node.y() - node.height() / 2,
      });

      node.y(node.y());
    } else if (elementsCenterY) {
      setLineHorizontalElementProps({
        ...lineHorizontalElementProps,
        show: true,
        y: node.y(),
      });

      node.y(node.y());
    } else if (elementsBottomY || elementsTopAndBottomY) {
      setLineHorizontalElementProps({
        ...lineHorizontalElementProps,
        show: true,
        y: node.y() + node.height() / 2,
      });

      node.y(node.y());
    } else {
      setLineHorizontalElementProps({
        ...lineHorizontalElementProps,
        show: false,
      });
    }

    if (elementsLeftX || elementsRightAndLeftX) {
      setLineVerticalElementProps({
        ...lineVerticalElementProps,
        show: true,
        y: 0,
        x: node.x() - node.width() / 2,
      });

      node.x(node.x());
    } else if (elementsCenterX) {
      setLineVerticalElementProps({
        ...lineVerticalElementProps,
        show: true,
        y: 0,
        x: node.x(),
      });

      node.x(node.x());
    } else if (elementsRightX || elementsLeftAndRightX) {
      setLineVerticalElementProps({
        ...lineVerticalElementProps,
        show: true,
        y: 0,
        x: node.x() + node.width() / 2,
      });

      node.x(node.x());
    } else {
      setLineVerticalElementProps({ ...lineVerticalElementProps, show: false });
    }
  };

  const color = isShape
    ? {
      red: attributes.red,
      blue: attributes.blue,
      green: attributes.green,
    }
    : { fill: attributes.fill };

  const strokeProp = isShape
    ? {
      borderColor: attributes.borderColor,
      borderWidth: attributes.borderWidth,
    }
    : { stroke: attributes.stroke, strokeWidth: attributes.strokeWidth };
  const is3d =
    isShape && elementRef?.current?.attrs?.svgType === "3d" ? true : false || isWidget ? true : false

  // console.log(elementRef?.current?.attrs, "ATTRSApple");
  const elementProps = {
    ...attributes,
    ...{ color },
    ...{ strokeProp },
    onClick,
    onDragEnd,
    onDragMove,
    onDragStart,
    onTransform,
    onTransformEnd,
    ref: elementRef,
    image: isVideo ? videoElement : imageSrc,
    offsetX: isText ? 0 : attributes.width / 2,
    offsetY: isText ? 0 : attributes.height / 2,
    filters: !is3d ? [Konva.Filters.RGBA, Border] : [],
    filters: !is3d ? [Konva.Filters.RGBA, Border] : [],
    draggable: attributes.setAsBackground ? false : attributes.draggable,

  };
  // const elementProps = {
  //   ...attributes,
  //   ...{ color },
  //   ...{ strokeProp },
  //   onClick,
  //   onDragEnd,
  //   onDragMove,
  //   onDragStart,
  //   onTransform,
  //   onTransformEnd,
  //   ref: elementRef,
  //   image: isVideo ? videoElement : imageSrc,
  //   offsetX: isText ? 0 : attributes.width / 2,
  //   offsetY: isText ? 0 : attributes.height / 2,
  //   filters: !is3d ? [Konva.Filters.RGBA, Border] : [],
  //   draggable:
  //     (isShape || isImage || isVideo) && isElementDraggable && !attributes.setAsBackground,
  // };

  // console.log("::::", elementProps, "elementProps");
  return (
    <>
      {isText && <TextElement {...elementProps} />}
      {isMenu && <MenuElement {...attributes} />}
      {/* {(isWidget) && console.log("isWidget ", elementProps)} */}
      {(isShape || isImage || isVideo) && (
        <KonvaImage {...elementProps} />
      )}
      {(isWidget) && (
        <KonvaImage {...elementProps}
        // image={"https://70.35.197.229:3000/widget/internet.png"}
        />
      )}
      {/* {(isWidget) && (
        <>
          <Image {...elementProps} src="https://www.pexels.com/photo/scenic-cliff-at-stokksnes-on-iceland-18273081/" />
          <Html>
            <Box
              component="img"
              src={elementProps?.src}
              {...elementProps}
              sx={{
                ...elementProps
              }}
            />

          </Html>
        </>
      )} */}
      {lineHorizontalElementProps.show && (
        <LineHorizontalElement {...lineHorizontalElementProps} />
      )}
      {lineVerticalElementProps.show && (
        <LineVerticalElement {...lineVerticalElementProps} />
      )}
    </>
  );
};

export default StudioElement;
