import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector, useDispatch } from "react-redux";

import { updateStudioElement } from "../../../store/studioSlice";

const TextFontFamily = ({ dialect }) => {
  const dispatch = useDispatch();
  const [fonts, setFonts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  const selectedElementFontFamily =
    dialect === "odd"
      ? selectedElement.rows[0].elements[0].props.fontFamily
      : dialect === "even"
      ? selectedElement.rows[1].elements[0].props.fontFamily
      : selectedElement.fontFamily;
  const onFontChange = async (e, value) => {
    await document.fonts.load(`${selectedElement.fontSize || 15}px ${value}`);
    dispatch(updateStudioElement({ fontFamily: value, dialect }));
  };

  const onInputChange = (e, value) => {
    dispatch(updateStudioElement({ fontFamily: value, dialect }));
  };

  const getFonts = async () => {
    setLoading(true);

    try {
      document.fonts.ready.then(() => {
        const fontsArray = Array.from(document.fonts)?.map(
          (font, index) => font.family
        );
        const uniqueFontsArray = [...new Set(fontsArray)];

        uniqueFontsArray.sort();

        if (!uniqueFontsArray.includes["Arial"]) {
          uniqueFontsArray.unshift("Arial");
        }

        setFonts(uniqueFontsArray);
      });
    } catch (error) {
      setFonts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getFonts();
  }, []);

  return (
    <Autocomplete
      // fullWidth
      size="small"
      disablePortal
      options={fonts}
      disableClearable
      loading={loading}
      onChange={onFontChange}
      onInputChange={onInputChange}
      value={selectedElementFontFamily}
      inputValue={selectedElementFontFamily}
      className="properties-autocomplete-tiny"
      style={{ paddingTop: 0, paddingBottom: 0, width: 160, borderWidth: 0 }}
      renderInput={(params) => <TextField {...params} />}
      isOptionEqualToValue={(option, value) => option === value}
    />
  );
};

export default TextFontFamily;
