import React from "react";
import { Transformer } from "react-konva";

export const TransformerLayout = ({
  handleTransformNew,
  trRef,
  textWidth,
  zoomScale,
  templateWidth,
  frameOnScreenWidth,
}) => {
  const defaultZoomScale = 85;
  const ratio =
    (frameOnScreenWidth / templateWidth) * (zoomScale / defaultZoomScale);
  return (
    <>
      <Transformer
        onTransform={handleTransformNew}
        rotateEnabled={false}
        ref={trRef}
        width={100}
        padding={10}
        boundBoxFunc={(oldBoundBox, newBoundBox) => {
          const node = trRef.current;
          const cropPosition = node.getActiveAnchor();
          console.log(
            textWidth,
            ratio,
            zoomScale,
            "Math.max(...textHeightArray())"
          );
          if (
            cropPosition === "middle-right" ||
            cropPosition === "middle-left"
          ) {
            if (newBoundBox.width < textWidth * ratio + 20) {
              return oldBoundBox;
            }
            return newBoundBox;
          }
          if (newBoundBox.width < 100) {
            return oldBoundBox;
          } else {
            return newBoundBox;
          }
        }}
      />
    </>
  );
};
