import {useState} from "react";
import {Box} from "@mui/system";
import {Link} from "react-router-dom";
import {LoadingButton} from "@mui/lab";
import {Button, Typography, TextField} from "@mui/material";

import request from "../services/request";
import AuthBox from "../components/Auth/AuthBox";

const Signin = () => {
    const [loading, setLoading] = useState(false);
    const [form, setForm] = useState({
        user_name: "",
        eamil: "",
        password: "",
        first_name: "",
        last_name: "",
    });

    const onSubmit = async () => {
        if (loading) return;
        setLoading(true);

        const formData = new FormData();
        formData.append("username", form.user_name);
        formData.append("password", form.password);
        formData.append("email", form.email);
        formData.append("first_name", form.first_name);
        formData.append("last_name", form.last_name);

        try {
            const res = await request({
                method: "post",
                url: "/register",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: formData,
            });
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <AuthBox>
            <Typography mb={2} variant="h6" textAlign="center">
                Create your account
            </Typography>

            <Box component="form">
                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={form.first_name}
                    label="First name"
                    sx={{marginBottom: 2.5}}
                    onChange={(e) => setForm({...form, first_name: e.target.value})}
                />

                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={form.last_name}
                    label="Last name"
                    sx={{marginBottom: 2.5}}
                    onChange={(e) => setForm({...form, last_name: e.target.value})}
                />

                <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={form.user_name}
                    label="Username"
                    sx={{marginBottom: 2.5}}
                    onChange={(e) => setForm({...form, user_name: e.target.value})}
                />

                <TextField
                    fullWidth
                    type="email"
                    size="small"
                    variant="outlined"
                    value={form.email}
                    label="Email"
                    sx={{marginBottom: 2.5}}
                    onChange={(e) => setForm({...form, email: e.target.value})}
                />

                <TextField
                    fullWidth
                    size="small"
                    label="Password"
                    variant="outlined"
                    value={form.password}
                    sx={{marginBottom: 2.5}}
                    onChange={(e) => setForm({...form, password: e.target.value})}
                />

                <LoadingButton
                    loading={loading}
                    onClick={onSubmit}
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={{
                        boxShadow: "0",
                        marginBottom: 2,
                        borderRadius: 1.5,
                        textTransform: "none",
                    }}
                >
                    Sign up
                </LoadingButton>

                <Typography
                    variant="caption"
                    textAlign="center"
                    sx={{display: "block"}}
                >
                    Already signed up?{" "}
                    <Link to="/signin">
                        <span className="text-blue-500 cursor-pointer">Log in</span>
                    </Link>
                </Typography>
            </Box>
        </AuthBox>
    );
};

export default Signin;
