import { Button, Grid } from "@mui/material";

import HeaderRedoUndo from "./HeaderRedoUndo";
import HeaderGridButton from "./HeaderGridButton";
import HeaderSaveButton from "./HeaderSaveButton";
import HeaderMoreActions from "./HeaderMoreActions";
import HeaderDownloadButton from "./HeaderDownloadButton";
import HeaderLoginButton from "./HeaderLoginButton";
import HeaderStyle from "./Header.module.scss";
import HeaderLayerButton from "./HeaderLayerButton";
import { useEffect, useState } from "react";

const Header = () => {

  const [data, setData] = useState("")

  useEffect(() => {
    setData(localStorage.getItem("token"))
  })
  return (
    <Grid
      container
      alignItems="center"
      id="header-element"
      className={HeaderStyle.header}
    >
      <Grid container item xs alignItems="center" spacing={0.5}>
        <Grid item>
          <HeaderGridButton />
        </Grid>
        <Grid item>
          <HeaderRedoUndo />
        </Grid>
        <Grid item ml={1}>
          <HeaderMoreActions />
        </Grid>
      </Grid>
      <Grid container spacing={1} item xs justifyContent="flex-end">
        {!data ?
          (
            <Grid item>
              <HeaderLoginButton />
            </Grid>
          )
          :
          (
            <>
              <Grid item>
                <HeaderDownloadButton />
              </Grid>
              <Grid item>
                <HeaderSaveButton />
              </Grid>
            </>
          )
        }
      </Grid>
    </Grid>
  );
};

export default Header;
