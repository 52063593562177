import { Box } from "@mui/material";
import { ChromePicker } from "react-color";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// TODO: Use webpack alias instead of relative path
import { updateStudioLayout } from "../../store/studioSlice";

const PropertiesPanelBackground = () => {
  const dispatch = useDispatch();
  const [colorPickerWidth, setColorPickerWidth] = useState(0);
  const { studioLayout } = useSelector((state) => state.studio.present);

  useEffect(() => {
    if (!colorPickerWidth) {
      const propertiesPanelWidth = document.querySelector(
        "#style-settings-element"
      )?.offsetWidth;
      setColorPickerWidth(propertiesPanelWidth - 36);
    }
  }, []);

  const onChange = (color) => {
    dispatch(
      updateStudioLayout({
        fill: color.hex,
      })
    );
  };

  return (
    <Box p={2}>
      <ChromePicker
        disableAlpha
        onChange={onChange}
        width={colorPickerWidth}
        color={studioLayout.fill}
      />
    </Box>
  );
};

export default PropertiesPanelBackground;
