import { createSlice } from "@reduxjs/toolkit";
import { FaShapes } from "react-icons/fa";
import { BsPlayBtn } from "react-icons/bs";
import { IoImagesOutline } from "react-icons/io5";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { FiLayers } from "react-icons/fi";
import { MdOutlineFormatColorText } from "react-icons/md";
import { TbResize, TbTemplate, TbUserCircle } from "react-icons/tb";

const initialState = {
  menu: [
    {
      key: "resize",
      title: "Resize",
      icon: TbResize,
      selected: true,
    },
    {
      key: "templates",
      title: "Templates",
      icon: TbTemplate,
      selected: false,
    },
    {
      key: "images",
      title: "Images",
      icon: IoImagesOutline,
      selected: false,
    },
    {
      key: "videos",
      title: "Videos",
      icon: BsPlayBtn,
      selected: false,
    },
    {
      key: "my-content",
      title: "My Content",
      icon: TbUserCircle,
      selected: false,
    },
    {
      key: "text",
      title: "Text",
      icon: MdOutlineFormatColorText,
      selected: false,
    },
    {
      key: "elements",
      title: "Elements",
      icon: FaShapes,
      selected: false,
    },
    {
      key: "widgets",
      title: "Widgets",
      icon: HiOutlineRocketLaunch,
      selected: false,
    },
    {
      key: "layers",
      title: "Layers",
      icon: FiLayers,
      selected: false,
    },
  ],
  dragElement: null,
  contentScrollEnd: false,
};

export const toolsSlice = createSlice({
  name: "tools",
  initialState,
  reducers: {
    setToolsMenu(state, action) {
      state.menu = state.menu.map((item) => ({
        ...item,
        selected:
          item.key === action.payload.key ? action.payload.selected : false,
      }));
    },
    setToolsDragElement(state, action) {
      state.dragElement = action.payload;
    },
    setToolsContentScrollEnd(state, action) {
      state.contentScrollEnd = action.payload;
    },
    setTextContentSelected(state, action) {
      state.textContentSelected = action.payload;
    },
    setTemplateElement(state, action) {
      state.templateElement(action.state);
    },
  },
});

// this is for dispatch
export const {
  setToolsMenu,
  setToolsDragElement,
  setToolsContentScrollEnd,
  setTextContentSelected,
  setTemplateElement,
} = toolsSlice.actions;

// this is for configureStore
export default toolsSlice.reducer;
