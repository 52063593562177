import { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Divider, Switch, Typography } from "@mui/material";

// TODO: Use webpack alias instead of relative path
import { updateStudioElement } from "../../store/studioSlice";

const PropertiesPanelIcon = () => {
  const dispatch = useDispatch();
  const [colorPickerWidth, setColorPickerWidth] = useState(null);

  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  const selectedElementFill =

    selectedElement?.type === "shape"
      ? `rgb(${selectedElement.red || 0}, ${selectedElement.green || 0}, ${selectedElement.blue || 0} )`
      : selectedElement?.fill || "#000";

  const selectedElementHasFill =
    selectedElement?.type === "shape" ? selectedElement.red !== null : selectedElement?.fill !== null;

  const [colorPicker, setColorPicker] = useState(selectedElementFill || "#000");
  const [colorPickerSwitch, setColorPickerSwitch] = useState(
    selectedElementHasFill ? true : false
  );

  useEffect(() => {
    if (colorPickerWidth == null) {
      const propertiesPanelWidth = document.querySelector(
        "#style-settings-element"
      )?.offsetWidth;
      setColorPickerWidth(propertiesPanelWidth - 36);
    }
  }, []);

  const onChangeColorPickerFill = (color) => {
    setColorPicker(color);

    if (!colorPickerSwitch) {
      setColorPickerSwitch(true);
    }

    if (selectedElement?.type === "shape") {
      dispatch(
        updateStudioElement({
          red: color.rgb.r || 0,
          blue: color.rgb.b || 0,
          green: color.rgb.g || 0,
        })
      );
    } else {
      dispatch(updateStudioElement({ Iconfill: color.hex }));
    }
  };

  const onSwitchChange = (event) => {
    const checked = event.target.checked;
    setColorPickerSwitch(checked);
    if (checked) return;

    if (selectedElement?.type === "shape") {
      dispatch(
        updateStudioElement({
          red: null,
          blue: null,
          green: null,
        })
      );
    } else {
      dispatch(updateStudioElement({ Iconfill: null }));
    }
  };

  return (
    <Box px={2}>
      <Box>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="body1">Icon Fill</Typography>
          </Grid>
          <Grid item>
            <Switch checked={colorPickerSwitch} onChange={onSwitchChange} />
          </Grid>
        </Grid>
        <Divider light />
      </Box>

      <Box mt={2}>
        <ChromePicker
          disableAlpha
          color={colorPicker}
          width={colorPickerWidth}
          onChange={onChangeColorPickerFill}
        />
      </Box>
    </Box>
  );
};

export default PropertiesPanelIcon;
