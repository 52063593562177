import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { MdClose } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  Box,
  Menu,
  Grid,
  Select,
  Button,
  Divider,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  CircularProgress,
} from "@mui/material";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
const loading_processing = <p>Processing{" "}<CircularProgress color="inherit" size={16} /></p>
const loading_processing_default = <CircularProgress color="inherit" size={16} />

const FILE_MIME_TYPES = [
  {
    type: "video",
    format: "webm",
    title: "Video WebM",
  },
  {
    type: "video",
    format: "mp4",
    title: "Video MP4",
  },
  // {
  //   type: "image",
  //   format: "jpg",
  //   title: "Image JPG",
  // },
  {
    type: "image",
    format: "png",
    title: "Image PNG",
  },
];

const DownloadSaveButton = ({
  loading = false,
  loading2 = false,
  buttonIcon,
  buttonTitle,
  headerIcon,
  headerTitle,
  fieldNameLabel,
  fieldTypeLabel,
  actionTitle,
  onAction,
}) => {
  const { studioElements, studioLayout } = useSelector(
    (state) => state.studio.present
  );
  const [anchorEl, setAnchorEl] = useState();
  const isDownloadMenuOpen = Boolean(anchorEl);
  const [fileName, setFileName] = useState("Hello World");
  const [fileMimeType, setFileMimeType] = useState(FILE_MIME_TYPES[0]);

  const onFileNameChange = (event) => {
    setFileName(event.target.value);
  };

  const onFileTypeChange = (event) => {
    setFileMimeType(event.target.value);
  };

  const onCloseClick = () => {
    setAnchorEl(null);
  };

  const onButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onActionClick = () => {
    onAction({ fileName: fileName, fieldType: fileMimeType });
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{ borderRadius: 2 }}
        onClick={onButtonClick}
        disabled={!studioElements.length}
        startIcon={buttonIcon}
        className="icon_btn"
      >
        <span className="mbl-hide">{buttonTitle}</span>
      </Button>
      <Menu
        open={isDownloadMenuOpen}
        anchorEl={anchorEl}
        onClose={onCloseClick}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 2.5,
            width: "270px",
            borderRadius: "0.5rem",
            filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.16))",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box px={2} py={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container item xs alignItems="center">
              {headerIcon}
              <Typography fontWeight="bold" ml={1}>
                {headerTitle}
              </Typography>
            </Grid>
            <Grid item>
              <MdClose size={20} cursor="pointer" onClick={onCloseClick} />
            </Grid>
          </Grid>
        </Box>
        <Divider light />
        <Box px={2} pt={3} pb={1.5}>
          <FormControl fullWidth>
            <TextField
              value={fileName}
              size="small"
              label={fieldNameLabel}
              variant="outlined"
              onChange={onFileNameChange}
            />
            <Grid container alignItems="center" mt={2}>
              <Grid item xs>
                <Typography variant="body2">{fieldTypeLabel}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption" color="#ccc">
                  {studioLayout.width ? parseInt(studioLayout.width, 10) : ""}{" "}
                  {studioLayout.width && studioLayout.height ? "x" : '"'}{" "}
                  {studioLayout.height ? parseInt(studioLayout.height, 10) : ""}{" "}
                  {studioLayout.ratio
                    ? studioLayout.ratio >= 1
                      ? "Landscape"
                      : "Portrait"
                    : ""}
                </Typography>
              </Grid>
            </Grid>
            <Select
              size="small"
              value={fileMimeType}
              onChange={onFileTypeChange}
            >
              {FILE_MIME_TYPES.map((mime, index) => (
                <MenuItem value={mime} key={index}>
                  {mime.title}
                </MenuItem>
              ))}
            </Select>
            <LoadingButton
              loading={loading}
              loadingIndicator={loading2 ? loading_processing : loading_processing_default}
              // load
              variant="contained"
              color="primary"
              sx={{ borderRadius: 1.5, marginTop: 2 }}
              size="large"
              onClick={onActionClick}
            >
              {actionTitle}
            </LoadingButton>
          </FormControl>
        </Box>
      </Menu>
    </>
  );
};

export default DownloadSaveButton;
