import { createSlice, current } from "@reduxjs/toolkit";

const initialState = {
  width: 0,
  height: 0,
  stage: null,
  studioElements: [],
  menuList: [],
  studioTemplateId: null,
  studioSelection: {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
    visible: false,
    id: "studio-selection",
    fill: "rgba(0,0,255,0.1)",
  },
  studioTransformer: {
    nodes: [],
    keepRatio: true,
    id: "studio-transformer",
    shouldOverdrawWholeArea: false,
  },
  studioLayout: {
    x: 0,
    y: 0,
    group: {
      x: 0,
      y: 0,
      scaleX: 1,
      scaleY: 1,
    },
    id: null,
    ratio: 0,
    scaleX: 1,
    scaleY: 1,
    layout: "",
    height: 676,
    width: 1200,
    fill: "#fff",
    zoomScale: 100,
  },
  studioLayoutGrid: {
    id: 1,
    width: 1,
    height: 1,
  },
  studioPlay: null,
  studioDuration: 1,
  studioTemplates: [],
  studioCategory: null,
  studioColorPicker: "", // 'fill' or 'stroke'
  studioCurrentTarget: "",
  studioId: "studio-template",
  text_edit: false,
  any_target_change: false,
};

export const studioSlice = createSlice({
  name: "studio",
  initialState,
  reducers: {
    setTextEdit(state, action) {
      // console.log(action.payload, "action.payload");
      state.text_edit = action.payload;
    },
    updateStudio(state, action) {
      state.studioCurrentTarget = "";
      state.studioLayout = action.payload.layout;
      state.studioLayoutGrid = action.payload.grid;
      state.studioElements = action.payload.elements;
      state.studioDuration = action.payload.duration;
      state.studioCategory = action.payload.category;
      state.studioTemplateId = action.payload.templateId;
      state.menuList = action.payload.menuList;
      state.studioTransformer = { ...state.studioTransformer, nodes: [] };
    },
    clearStudio(state) {
      state.studioElements = [];
      state.studioCurrentTarget = "";
      state.studioTransformer = { ...state.studioTransformer, nodes: [] };
    },
    setStage(state, action) {
      state.stage = action.payload;
    },
    setStudioWidth(state, action) {
  // Adjust studio width to match the container width (1088px at max or based on zoom)
  const maxWidth = 1088; // Max width for the container
  state.studioLayout.width = Math.min(maxWidth, action.payload); // Set width based on the payload but don't exceed maxWidth
},
    setStudioPlay(state, action) {
      state.studioPlay = action.payload;
    },
    setStudioDuration(state, action) {
      state.studioDuration = action.payload;
    },
    updateStudioElements(state, action) {
      state.studioElements = action.payload;
    },
    addStudioElement(state, action) {
      // console.log("AddElements==>", action.payload);
      const isExistElement = state.studioElements.find(
        (item) => item.id === action.payload.id
      );
      if (isExistElement) return;
      state.studioElements.push(action.payload);
    },
    moveStudioElement(state, action) {
      const { element, to } = action.payload;
      const studioElementsArray = [...state.studioElements];
      const fromIndex = studioElementsArray.findIndex(
        (item) => item.id === element.id
      );

      studioElementsArray.splice(fromIndex, 1);

      switch (to) {
        case "next":
          studioElementsArray.splice(fromIndex + 1, 0, element);
          break;

        case "prev":
          if (fromIndex === 0) return;
          studioElementsArray.splice(fromIndex - 1, 0, element);
          break;

        case "first":
          studioElementsArray.splice(0, 0, element);
          break;

        case "last":
          studioElementsArray.splice(studioElementsArray.length, 0, element);
          break;

        default:
          break;
      }

      state.studioElements = studioElementsArray;
    },
    setStudioHeight(state, action) {
      state.height = action.payload;
    },
    setStudioCurrentTarget(state, action) {
      state.any_target_change = !state.any_target_change;
      state.studioCurrentTarget = action.payload;
    },
    resetStudioCurrentTarget(state) {
      state.studioCurrentTarget = "";
    },
    updateStudioElement(state, action) {
      const targetId = action.payload?.id || state.studioCurrentTarget;
      const newElements = state.studioElements.map((element) => {
        let newElement = { ...element };

        if (element.id === targetId && newElement.type !== "menu") {
          newElement = { ...element, ...action.payload };
        }

        if (element.id === targetId && newElement.type === "menu") {
          newElement = { ...element, ...action.payload };
          // console.log("newElement=>>", newElement);

          newElement.rows = newElement.rows.map((item, index) => {
            const elements = [
              ...item?.elements.map((rowsElement) => {
                const payload =
                  targetId === rowsElement.props.id ? action.payload : {};

                if (action.payload.dialect === "even") {
                  if (index % 2 === 1) {
                    const props = {
                      ...rowsElement.props,
                      ...payload,
                      ...action.payload,
                    };
                    return { props };
                  } else {
                    const props = {
                      ...rowsElement.props,
                    };
                    return { props };
                  }
                } else if (action.payload.dialect === "odd") {
                  if (index % 2 !== 1) {
                    const props = {
                      ...rowsElement.props,
                      ...payload,
                      ...action.payload,
                    };
                    return { props };
                  } else {
                    const props = {
                      ...rowsElement.props,
                    };
                    return { props };
                  }
                } else {
                  const props = {
                    ...rowsElement.props,
                    ...payload,
                    ...action.payload,
                    index,
                  };
                  return { props };
                }
              }),
            ];

            return { elements };
          });
        }

        return newElement;
      });
      state.studioElements = newElements;
    },
    removeStudioElement(state, action) {
      const targetId = action.payload?.id || state.studioCurrentTarget;
      // console.log("targetId::", targetId);
      const newElements = state.studioElements.filter(
        (element) => element.id !== targetId
      );
      // console.log("newElements::", newElements);
      state.studioElements = newElements;
    },
    removeStudioGroupedElements(state, action) {
      const groupedElementsIds =
        action.payload ||
        state.studioTransformer.nodes.map((item) => item.id());
      const newElements = state.studioElements.filter(
        (item) => !groupedElementsIds.includes(item.id)
      );
      state.studioElements = newElements;
      state.studioCurrentTarget = "";
    },
    deselectStudioGroupedElements(state) {
      state.studioTransformer = { ...state.studioTransformer, nodes: [] };
    },
    updateStudioTransformer(state, action) {
      state.studioTransformer = {
        ...state.studioTransformer,
        ...action.payload,
        fill: "red",
      };
    },
    resetStudioTransformer(state) {
      state.studioTransformer = {
        ...state.studioTransformer,
        nodes: [],
      };
    },
    updateStudioSelection(state, action) {
      state.studioSelection = {
        ...state.studioSelection,
        ...action.payload,
      };
    },
    updateStudioColorPicker(state, action) {
      state.studioColorPicker = action.payload;
    },
    addStudioTemplates(state, action) {
      state.studioTemplates.push(action.payload);
    },
    updateStudioLayout(state, action) {
  const ratio = action.payload?.width / action.payload?.height;
  const layout = ratio < 1 ? "Portrait" : "Landscape";
  state.studioLayout = {
    ...state.studioLayout,
    ...action.payload,
    ratio,
    layout,
  };
},
    setStudioCategory(state, action) {
      state.studioCategory = {
        ...state.studioCategory,
        ...action.payload,
      };
    },
    setStudioLayoutGrid(state, action) {
      state.studioLayoutGrid = {
        ...state.studioLayoutGrid,
        ...action.payload,
      };
    },
  },
});

// this is for dispatch
export const {
  setStage,
  clearStudio,
  setTextEdit,
  updateStudio,
  setStudioPlay,
  setStudioWidth,
  setStudioHeight,
  addStudioElement,
  setStudioDuration,
  setStudioCategory,
  moveStudioElement,
  addStudioTemplates,
  updateStudioLayout,
  updateStudioElement,
  removeStudioElement,
  setStudioLayoutGrid,
  updateStudioElements,
  updateStudioSelection,
  resetStudioTransformer,
  setStudioCurrentTarget,
  updateStudioTransformer,
  updateStudioColorPicker,
  resetStudioCurrentTarget,
  removeStudioGroupedElements,
  deselectStudioGroupedElements,
} = studioSlice.actions;

// this is for configureStore
export default studioSlice.reducer;
