import { useSelector } from "react-redux";
import { Box, Grid, Divider } from "@mui/material";

import PropertiesPanelFlip from "./PropertiesPanelFlip";
import PropertiesPanelOrder from "./PropertiesPanelOrder";
import PropertiesPanelText from "./PropertiesPanelText/index";
import PropertiesPanelOpacity from "./PropertiesPanelOpacity";
import PropertiesPanelPosition from "./PropertiesPanelPosition";
import PropertiesPanelDelayTime from "./PropertiesPanelDelayTime";
import PropertiesPanelAlignment from "./PropertiesPanelAlignment";
import PropertiesPanelShapeColor from "./PropertiesPanelShapeColor";
import PropertiesPanelLayer from "./PropertiesPanelLayer";

const PropertiesPanelSettings = () => {
  const studio = useSelector((state) => state.studio.present);
  const { studioCurrentTarget, studioElements, studioTransformer } = studio;
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedElementType = selectedElement?.type;
  const isGroupSelected = studioTransformer.nodes.length > 1;
  const isSelectedElementSetAsBackground = selectedElement?.setAsBackground;

  const is3d =
    selectedElementType === "shape" && selectedElement?.svgType === "3d"
      ? true
      : false;

  // console.log("Hello", selectedElement?.svgType);

  // console.log("Dilshadd", is3d);
  return (
    <Box px={2}>
      {!isSelectedElementSetAsBackground && (
        <>
          <Grid container alignItems="center" spacing={1} py={1}>
            {selectedElementType !== "widget" && (
              <Grid item xs>
                <PropertiesPanelOrder />
              </Grid>
            )}

            <Grid item xs>
              <PropertiesPanelAlignment />
            </Grid>
          </Grid>
          <Divider light />
        </>
      )}
      {selectedElementType !== "text" &&
        selectedElementType !== "menu" &&
        selectedElementType !== "widget" &&
        !isGroupSelected && (
          <Grid container alignItems="center" spacing={1} py={1}>
            <Grid item xs>
              <PropertiesPanelFlip />
            </Grid>
          </Grid>
        )}

      {(selectedElementType === "text" || selectedElementType === "menu") && (
        <>
          <Divider light />
          <Grid container alignItems="center" spacing={1} py={1}>
            <Grid item xs>
              <PropertiesPanelText />
            </Grid>
          </Grid>
        </>
      )}
      {(!is3d ||
        selectedElementType === "text" ||
        selectedElementType === "menu") && (
        <>
          <Divider light />
          <Grid container alignItems="center" spacing={1}>
            {/* <Grid item xs> */}
            <PropertiesPanelShapeColor />
            {/* </Grid> */}
          </Grid>
        </>
      )}

      {selectedElementType === "widget" && (
        <>
          <Divider light />
          <Grid container alignItems="center">
            <Grid item xs>
              <PropertiesPanelDelayTime />
            </Grid>
          </Grid>
          <Divider light />
          <Grid container alignItems="center">
            <Grid item xs>
              <PropertiesPanelPosition />
            </Grid>
          </Grid>
        </>
      )}

      {!isSelectedElementSetAsBackground && (
        <>
          <Divider light />
          <Grid container alignItems="center">
            <Grid item xs>
              <PropertiesPanelOpacity />
            </Grid>
          </Grid>
        </>
      )}

      {/* {!isSelectedElementSetAsBackground && (
        <>
        <Divider light />
        <Grid container alignItems="center" spacing={1} py={1}>
          <Grid item xs>
            <PropertiesPanelLayer/>
          </Grid>
        </Grid>
        
        
        </>
      )} */}
    </Box>
  );
};

export default PropertiesPanelSettings;
