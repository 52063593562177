import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Typography,
} from "@mui/material";
// import { v4 as uuid } from "uuid";
import { RxDragHandleDots2 } from "react-icons/rx";
//layout photo for selection
import layout1 from "../../layouts/layout-1.png";
import layout2 from "../../layouts/layout-2.png";
import layout3 from "../../layouts/layout-3.png";
import layout4 from "../../layouts/layout-4.png";
import layout5 from "../../layouts/layout-5.png";
import layout6 from "../../layouts/layout-6.png";

import menuElements from "./menu-elements.json";
import { useDispatch, useSelector } from "react-redux";
import BlockIcon from "@mui/icons-material/Block";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SearchIcon from "@mui/icons-material/Search";
import { BsPencil } from "react-icons/bs";
import React, { useState } from "react";
import {
  setCurrentTargetMenu,
  setMenuList,
  setShowEditMenu,
} from "../../store/menuSlice";
import DraggableMenuList from "./DraggableMenuList";
import MenuIconPopUp from "./MenuIconPopUp";
import { IoBanOutline } from "react-icons/io5";

const GridElementsRows = ({ data, dataIndex }) => {
  return data.map((rows, index) => (
    <Grid
      item
      container
      key={`rows-${index}`}
      xs={!rows.elements && rows.xs}
      sx={{
        borderTop: rows.borderTop && rows.borderTop,
        borderLeft: rows.borderLeft && rows.borderLeft,
        borderRight: rows.borderRight && rows.borderRight,
        borderBottom: rows.borderBottom && rows.borderBottom,
        paddingTop: rows.paddingTop && rows.paddingTop,
        borderTopLeftRadius:
          rows.borderTopLeftRadius && rows.borderTopLeftRadius,
        borderBottomLeftRadius:
          rows.borderBottomLeftRadius && rows.borderBottomLeftRadius,
        borderBottomRightRadius:
          rows.borderBottomRightRadius && rows.borderBottomRightRadius,
        borderTopRightRadius:
          rows.borderTopRightRadius && rows.borderTopRightRadius,
      }}
    >
      {rows.elements && <GridElementsRows data={rows.elements} />}
      {!rows.elements && (
        <Box
          sx={{ padding: rows.props.padding && rows.props.padding }}
          className={`w-full rounded-md text-white`}
        >
          {
            <Typography
              sx={{ paddingTop: "0" }}
              color={rows.props.fill}
              variant={rows.props.variant}
              fontSize={rows.props.fontSize}
              fontWeight={rows.props.fontWeight}
              textAlign={rows.align || "center"}
            >
              {rows.props.text}
            </Typography>
          }
        </Box>
      )}
    </Grid>
  ));
};
const initialMenuItem = {
  itemName: "",
  itemPrice: "",
  description: "",
  icon: "",
  sizes: [
    {
      size: "",
      price: "",
    },
  ],
  addOns: [
    {
      addOn: "",
      price: "",
    },
  ],
};
export const GridFirst = ({
  showEditMenu,
  setLayout,
  changeMenuList,
  changeTargetMenu,
}) => {
  const { menu, studio } = useSelector((state) => state);
  const { studioElements } = studio?.present;
  const { showAllMenu, menuList, currentTargetMenu, selectedMenuGroup } = menu;
  const layout = [
    {
      id: 1,
      layout: layout1,
      style: {
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        fill: null,
        rotation: 0,
        type: "text",
        stroke: null,
        strokeWidth: 0,
        animation: null,
        draggable: true,
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
    },
    {
      id: 12,
      layout: layout2,
      style: {
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        fill: null,
        rotation: 0,
        type: "text",
        stroke: null,
        strokeWidth: 0,
        animation: null,
        draggable: true,
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
    },
    {
      id: 13,
      layout: layout3,
      style: {
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        fill: null,
        rotation: 0,
        type: "text",
        stroke: null,
        strokeWidth: 0,
        animation: null,
        draggable: true,
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
    },
    {
      id: 4,
      layout: layout4,
      style: {
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        fill: null,
        rotation: 0,
        type: "text",
        stroke: null,
        strokeWidth: 0,
        animation: null,
        draggable: true,
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
    },
    {
      id: 5,
      layout: layout5,
      style: {
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        fill: null,
        rotation: 0,
        type: "text",
        stroke: null,
        strokeWidth: 0,
        animation: null,
        draggable: true,
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
    },
    {
      id: 6,
      layout: layout6,
      style: {
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        fill: null,
        rotation: 0,
        type: "text",
        stroke: null,
        strokeWidth: 0,
        animation: null,
        draggable: true,
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
      },
    },
  ];
  const [layouts, setLayouts] = React.useState(
    layout?.map((x) => {
      if (selectedMenuGroup?.layoutId === x?.id) {
        return { ...x, selected: true };
      }
      return { ...x };
    })
  );

  const handleItemSelected = (layout, index) => {
    let copy = [...layouts];
    const temp = { ...layout };
    copy = copy?.map((e) => ({ ...e, selected: false }));

    temp.selected = true;
    copy[index] = temp;
    setLayouts(copy);
    setLayout(copy[index]);
  };
  return (
    <Box
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        height: 650,
        overflow: "hidden",
        overflowY: !showEditMenu ? "auto" : "hidden",
        padding: "0px 20px 0px 0px",
        position: "relative",
      }}
    >
      {showEditMenu ? (
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "rgb(255,255,255,0.5)",
            height: 650,
            zIndex: "1400",
            top: "5px",
            width: "100%",
          }}
        />
      ) : null}
      <Typography paragraph sx={{ fontSize: "15px", paddingTop: "10px" }}>
        Select a style
      </Typography>
      <Box item={true} xs>
        {layouts?.map((item, index) => (
          <button
            onClick={() => handleItemSelected(item, index)}
            key={index}
            className={`flex border-[1px] ${item?.selected ? "border-blue-200 border-2 bg-blue-100" : ""
              } border-gray-300 bg-gray-100 p-6 mb-3 rounded-lg cursor-pointer focus:border-2 focus:border-blue-200 focus:bg-blue-100`}
            style={{
              borderColor:
                item?.selected ||
                  (!layouts?.some((l) => l?.selected) &&
                    index === layouts?.length - 1)
                  ? "#3fbce7"
                  : "",
              background:
                item?.selected ||
                  (!layouts?.some((l) => l?.selected) &&
                    index === layouts?.length - 1)
                  ? "#ecf8fd"
                  : "",
            }}
          >
            <Grid container spacing={1} className="justify-center items-center">
              {/* {item.rows && <GridElementsRows data={item.rows} dataIndex={index} />} */}
              <img className="w-[12rem] h-25" src={item.layout} />
            </Grid>
          </button>
        ))}
      </Box>
    </Box>
  );
};

export const GridSecond = ({ changeMenuList, changeTargetMenu }) => {
  const dispatch = useDispatch();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const { menu } = useSelector((state) => state);
  const { showEditMenu, currentTargetMenu, menuList } = menu;
  const changeModalVisibility = (paylaod) => {
    dispatch(setShowEditMenu(paylaod));
  };

  console.log(menuList, "Menu List=>>>");
  const handleChange = (e) => {
    changeTargetMenu({ ...currentTargetMenu, [e.target.name]: e.target.value });
  };

  const handleSearch = (query) => {
    setSearchTerm(query);
  };

  const handleSizeChange = (e, item, index) => {
    let sizeItem = { ...item };
    sizeItem[[e.target.name]] = e.target.value;
    var copyMenuItem = {
      ...currentTargetMenu,
      sizes: [...currentTargetMenu?.sizes],
    };
    let sizes = [...copyMenuItem.sizes];
    sizes[index] = sizeItem;
    copyMenuItem.sizes = sizes;
    // console.log("copyMenuItem=>>" + `${index}`, currentTargetMenu);
    changeTargetMenu(copyMenuItem);
  };
  const handleAddOnsChange = (e, item, index) => {
    let addOn = { ...item };
    addOn[[e.target.name]] = e.target.value;
    var copyMenuItem = {
      ...currentTargetMenu,
      addOns: [...currentTargetMenu?.addOns],
    };
    let addOns = [...copyMenuItem.addOns];
    addOns[index] = addOn;
    copyMenuItem.addOns = addOns;
    changeTargetMenu(copyMenuItem);
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        // const iconUrl = e.target.result;
        changeTargetMenu({ ...currentTargetMenu, icon: e.target.result });
        // Use the iconUrl as the source for the Konva.Image component
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      {!showEditMenu ? (
        <>
          {menuList.length !== 0 ? (
            <Box
              sx={{
                width: 460,
                mb: 2,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                padding: "0 0 0 32px",
              }}
            >
              <Typography
                paragraph
                sx={{ fontSize: "15px", paddingTop: "10px" }}
              >
                All Menu Items
              </Typography>
              <Box item xs sx={{ gap: "0.5rem" }}>
                <Box>
                  <TextField
                    name="searchTerm"
                    // onChange={(e) => setSearchTerm(e?.target?.value)}
                    onChange={(e) => handleSearch(e.target.value)}
                    sx={{ width: "90%", height: "50%" }}
                    placeholder="Filter menu items"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: "0.5rem", marginTop: "10px" }}>
                  <Box>
                    <Button
                      onClick={() => {
                        changeTargetMenu(initialMenuItem);

                        changeModalVisibility(true);
                      }}
                      variant="primary"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#3fbce7",
                        padding: "10px 20px",
                        gap: "0.5rem",
                      }}
                    >
                      <AddIcon sx={{ color: "#fff", width: "16px" }} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          marginBottom: "0",
                        }}
                      >
                        Add item
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>

              {menuList.length > 0 ? (
                <>
                  <Box className="flex items-center">
                    <Checkbox
                      style={{ color: "#3fbce7" }}
                      onChange={(e) => {
                        // console.log("e---->", e?.target?.checked);
                        let copy = [...menuList];
                        const checked = copy?.every(
                          (x) => x?.selected === true
                        );
                        const newList = checked
                          ? copy?.map((x) => ({ ...x, selected: false }))
                          : copy?.map((x) => ({ ...x, selected: true }));

                        console.log("newList:::", newList);
                        dispatch(setMenuList(newList));
                      }}
                      checked={menuList?.every((x) => x?.selected === true)}
                    />
                    <Typography>Select All</Typography>
                  </Box>
                  <DraggableMenuList
                    // menuList={menuList}
                    // menuList={
                    //   !searchTerm
                    //     ? menuList
                    //     : menuList?.filter((item) =>
                    //       item.itemName.toLowerCase().includes(searchTerm.toLowerCase()))
                    // }
                    searchTerm={searchTerm}
                    changeTargetMenu={changeTargetMenu}
                    changeModalVisibility={changeModalVisibility}
                  />
                </>
              ) : null}
            </Box>
          ) : (
            <Box
              sx={{
                width: 460,
                mb: 2,
                display: "flex",
                flexDirection: "column",
                height: 650,
                overflow: "hidden",
                padding: "0px 0px 0px 32px",
              }}
            >
              <Typography
                paragraph
                sx={{ fontSize: "15px", paddingTop: "10px" }}
              >
                All Menu Items
              </Typography>
              <Box
                item
                xs
                sx={{
                  height: 600,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  flexDirection: "column",
                  gap: "0.5rem",
                }}
              >
                <Box>
                  <BlockIcon sx={{ color: "#0089ff" }} />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "12px", color: "#b5b5b5" }}>
                    No item created
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                  <Box>
                    <Button
                      onClick={() => changeModalVisibility(true)}
                      variant="primary"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        backgroundColor: "#3fbce7",
                        padding: "10px 20px",
                        gap: "0.5rem",
                      }}
                    >
                      <AddIcon sx={{ color: "#fff", width: "16px" }} />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "12px",
                          marginBottom: "0",
                        }}
                      >
                        Add item
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box>
          <Box
            sx={{
              width: 450,
              mb: 2,
              display: "flex",
              flexDirection: "column",
              height: 650,
              overflow: "hidden",
              padding: "0px 7px 0px 32px",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                paddingTop: "10px",
              }}
            >
              <Box
                onClick={() => changeModalVisibility(false)}
                button
                sx={{ color: "#000" }}
              >
                <ArrowBackIcon sx={{ alignContent: "center", width: "20px" }} />
              </Box>
              <Typography paragraph sx={{ fontSize: "15px" }}>
                Add items
              </Typography>
            </Box>
            <Box
              item
              xs
              sx={{ height: 600, display: "flex", flexDirection: "column" }}
            >
              <Box sx={{ paddingRight: "10px" }}>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <Box>
                    <FormLabel sx={{ fontSize: "12px" }}>Item Name</FormLabel>
                    <TextField
                      value={currentTargetMenu?.itemName}
                      name="itemName"
                      onChange={(e) => handleChange(e)}
                      size="small"
                      placeholder="Item Name"
                      sx={{ paddingTop: "5px", width: "16rem" }}
                    ></TextField>
                  </Box>
                  <Box>
                    <FormLabel sx={{ fontSize: "12px" }}>Price</FormLabel>
                    <TextField
                      value={currentTargetMenu?.itemPrice}
                      name="itemPrice"
                      onChange={(e) => handleChange(e)}
                      size="small"
                      placeholder="Price"
                      sx={{ paddingTop: "5px" }}
                    ></TextField>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <FormLabel sx={{ fontSize: "12px" }}>Description</FormLabel>
                  <TextField
                    value={currentTargetMenu?.description}
                    name="description"
                    onChange={(e) => handleChange(e)}
                    size="small"
                    placeholder="Description"
                    multiline
                    rows={2}
                  ></TextField>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  {/* <FormLabel sx={{ fontSize: "12px" }}>Icon</FormLabel> */}
                  <MenuIconPopUp
                    selectedEmoji={currentTargetMenu?.icon}
                    setSelectedEmoji={(icon) => {
                      changeTargetMenu({ ...currentTargetMenu, icon: icon });
                    }}
                  />
                  <Button
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      borderWidth: "0.1rem",
                      borderStyle: "dashed",
                      color: "#b3b3b3",
                      minWidth: "140px",
                      width: "135px",
                      padding: "8px",
                    }}
                    component="label"
                    onClick={() => { }}
                  >
                    <AddIcon sx={{ width: "15px" }} />
                    <Typography sx={{ fontSize: "12px" }}>
                      Import icon
                    </Typography>
                    <input type="file" hidden onChange={handleFileChange} />
                    <img src={selectedEmoji} height={20} width={20} />
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel sx={{ fontSize: "12px" }}>Sizes</FormLabel>
                    {currentTargetMenu?.sizes?.map((sizeItem, index) => {
                      return (
                        <TextField
                          value={sizeItem?.size}
                          name="size"
                          key={index}
                          onChange={(e) => handleSizeChange(e, sizeItem, index)}
                          size="small"
                          placeholder="Sizes"
                          sx={{
                            paddingTop: "5px",
                            width: "16rem",
                            "& fieldset": {
                              borderLeft: "1px solid #B8B8B8",
                              borderTop: "1px solid #B8B8B8",
                              borderBottom: "1px solid #B8B8B8",
                              borderRight: "0",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            },
                          }}
                        ></TextField>
                      );
                    })}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel sx={{ fontSize: "12px" }}>Price</FormLabel>
                    {currentTargetMenu?.sizes?.map((sizeItem, index) => {
                      return (
                        <TextField
                          value={sizeItem?.price}
                          name="price"
                          key={index}
                          onChange={(e) => handleSizeChange(e, sizeItem, index)}
                          size="small"
                          placeholder="Price"
                          sx={{
                            paddingTop: "5px",
                            width: "80px",
                            "& fieldset": {
                              borderLeft: "1px solid #B8B8B8",
                              borderTop: "1px solid #B8B8B8",
                              borderBottom: "1px solid #B8B8B8",
                              borderRight: "1px solid #B8B8B8",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                              borderBottomLeftRadius: "0",
                              borderTopLeftRadius: "0",
                            },
                          }}
                        ></TextField>
                      );
                    })}
                  </Box>
                  <Box>
                    {currentTargetMenu?.sizes?.map((sizeItem, index) => {
                      return (
                        <Button
                          disabled={index === 0 ? true : false}
                          key={index}
                          sx={{
                            width: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            color: "red",
                            borderTop: "1px solid #B8B8B8",
                            borderRight: "1px solid #B8B8B8",
                            borderBottom: "1px solid #B8B8B8",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            marginTop: index === 0 ? "22px" : "5px",
                          }}
                        >
                          <DeleteForeverIcon
                            onClick={() => {
                              const copy = [...currentTargetMenu.sizes];
                              copy?.splice(index, 1);
                              changeTargetMenu({
                                ...currentTargetMenu,
                                sizes: copy,
                              });
                              // handleDeletePrice(sizeItem, index)
                            }}
                            sx={{ height: "26px" }}
                          />
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      const copy = [...currentTargetMenu.sizes];
                      copy?.push({
                        size: "",
                        price: "",
                      });
                      changeTargetMenu({ ...currentTargetMenu, sizes: copy });
                      //  handleAddPriceSize();
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      borderWidth: "0.1rem",
                      borderStyle: "dashed",
                      color: "#b3b3b3",
                      padding: "8px",
                      marginTop: "10px",
                    }}
                    component="label"
                  >
                    <AddIcon sx={{ width: "15px" }} />
                    <Typography sx={{ fontSize: "12px" }}>Add Size</Typography>
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "20px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel sx={{ fontSize: "12px" }}>Add-ons</FormLabel>
                    {currentTargetMenu?.addOns?.map((addOnItem, index) => {
                      return (
                        <TextField
                          value={addOnItem?.addOn}
                          key={index}
                          name="addOn"
                          onChange={(e) =>
                            handleAddOnsChange(e, addOnItem, index)
                          }
                          size="small"
                          placeholder="Add-on"
                          sx={{
                            paddingTop: "5px",
                            width: "16rem",
                            "& fieldset": {
                              borderLeft: "1px solid #B8B8B8",
                              borderTop: "1px solid #B8B8B8",
                              borderBottom: "1px solid #B8B8B8",
                              borderRight: "0",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                            },
                          }}
                        ></TextField>
                      );
                    })}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormLabel sx={{ fontSize: "12px" }}>Price</FormLabel>
                    {currentTargetMenu?.addOns?.map((addOnItem, index) => {
                      return (
                        <TextField
                          value={addOnItem?.price}
                          key={index}
                          name="price"
                          onChange={(e) =>
                            handleAddOnsChange(e, addOnItem, index)
                          }
                          size="small"
                          placeholder="Price"
                          sx={{
                            paddingTop: "5px",
                            width: "80px",
                            "& fieldset": {
                              borderLeft: "1px solid #B8B8B8",
                              borderTop: "1px solid #B8B8B8",
                              borderBottom: "1px solid #B8B8B8",
                              borderRight: "1px solid #B8B8B8",
                              borderTopRightRadius: "0",
                              borderBottomRightRadius: "0",
                              borderBottomLeftRadius: "0",
                              borderTopLeftRadius: "0",
                            },
                          }}
                        ></TextField>
                      );
                    })}
                  </Box>
                  {console.log(
                    "currentTargetMenu?.addOns=>>",
                    currentTargetMenu?.addOns
                  )}
                  <Box>
                    {currentTargetMenu?.addOns?.map((addonPrice, index) => {
                      return (
                        <Button
                          disabled={index === 0 ? true : false}
                          key={index}
                          onClick={() => {
                            const copy = [...currentTargetMenu.addOns];
                            copy?.splice(index, 1);
                            changeTargetMenu({
                              ...currentTargetMenu,
                              addOns: copy,
                            });
                            // handleDeleteAddOnsPrice(addonPrice, index)
                          }}
                          sx={{
                            width: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            alignContent: "center",
                            color: "red",
                            borderTop: "1px solid #B8B8B8",
                            borderRight: "1px solid #B8B8B8",
                            borderBottom: "1px solid #B8B8B8",
                            borderTopLeftRadius: "0",
                            borderBottomLeftRadius: "0",
                            marginTop: index === 0 ? "22px" : "5px",
                          }}
                        >
                          <DeleteForeverIcon sx={{ height: "26px" }} />
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      const copy = [...currentTargetMenu?.addOns];
                      copy?.push({
                        addOn: "",
                        price: "",
                      });
                      changeTargetMenu({ ...currentTargetMenu, addOns: copy });
                      //  handleAddAddOnsPrice()
                    }}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      borderWidth: "0.1rem",
                      borderStyle: "dashed",
                      color: "#b3b3b3",
                      padding: "8px",
                      marginTop: "10px",
                    }}
                    component="label"
                  >
                    <AddIcon sx={{ width: "15px" }} />
                    <Typography sx={{ fontSize: "12px" }}>
                      Add add-on
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export const GridThird = ({ changeMenuList, changeTargetMenu }) => {
  const dispatch = useDispatch();
  const { menu } = useSelector((state) => state);
  const { showEditMenu, menuList } = menu;

  const changeModalVisibility = (paylaod) => {
    dispatch(setShowEditMenu(paylaod));
  };

  return (
    <Box
      sx={{
        width: 450,
        mb: 2,
        display: "flex",
        flexDirection: "column",
        height: 650,
        overflow: "hidden",
        padding: "0px 0px 0px 32px",
        position: "relative",
      }}
    >
      {showEditMenu ? (
        <Box
          sx={{
            position: "absolute",
            backgroundColor: "rgb(255,255,255,0.5)",
            height: 650,
            zIndex: "1400",
            top: "5px",
            width: "100%",
          }}
        />
      ) : null}
      <div className="flex flex-row">
        <Typography className="w-full">All Menu Items</Typography>
        <div
          onClick={() => {
            changeMenuList(menuList?.map((x) => ({ ...x, selected: false })));
          }}
          style={{ width: "150px" }}
          className="flex flex-row gap-2 items-center"
        >
          <IoBanOutline color="#9c002f" />
          <span
            className="w-auto"
            style={{
              color: "#9c002f",
              fontSize: "14px",
            }}
          >
            Remove all
          </span>
        </div>
      </div>

      <p
        style={{
          color: " #757589",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: "300",
          letterSpacing: "normal",
          lineHeight: "150%",
        }}
        className=""
      >
        You can drag to re-order selected items
      </p>

      {menuList?.some((x) => x?.selected) ? (
        <DraggableMenuList
          menuList={menuList?.filter((x) => x?.selected)}
          changeTargetMenu={changeTargetMenu}
          changeModalVisibility={changeModalVisibility}
        />
      ) : (
        <Box
          item
          xs
          sx={{
            height: 600,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Box>
            <BlockIcon sx={{ color: "#0089ff" }} />
          </Box>
          <Box>
            <Typography sx={{ fontSize: "12px", color: "#b5b5b5" }}>
              No item selected
            </Typography>
          </Box>
          {/* <Box sx={{display: "flex", gap: "0.5rem"}}>
                    <Box>
                        <Button variant="primary" sx={{display: "flex", justifyContent: "center", backgroundColor: "#3fbce7", padding: "10px 20px", gap: "0.5rem"}}>
                            <AddIcon sx={{color: "#fff"}}/>
                            <Typography sx={{color: "#fff", fontSize: "12px"}}>Add item</Typography>
                        </Button>
                    </Box>
                    <Box>
                        <Button variant="primary" sx={{display: "flex", justifyContent: "center", border:"1px solid #b5b5b5", padding: "10px 20px", gap: "0.5rem"}}>
                            <GetAppIcon sx={{color: "#b5b5b5"}}/>
                            <Typography sx={{color: "#b5b5b5", fontSize: "12px"}}>Import from POS</Typography>
                        </Button>
                    </Box>
                </Box> */}
        </Box>
      )}
    </Box>
  );
};
