import { useSelector, useDispatch } from "react-redux";

import { updateStudioElement } from "../../../store/studioSlice";
import PropertiesStepperButton from "../PropertiesStepperButton";

const PropertiesPanelText = () => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  const updateFontSize = (value) => {
    const value1 = value
    dispatch(updateStudioElement({ scalex: value1 ,scaley:value1}));
    // dispatch(updateStudioTransformSizeChange(true));

  };

  return (
    <PropertiesStepperButton
      size="medium"
      onChange={updateFontSize}
      defaultValue={selectedElement.scalex}
    />
  );
};

export default PropertiesPanelText;
