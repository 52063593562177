import { Link } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";

const EditToolsContentMyContentDefault = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="h-full"
    >
      <Grid item px={6} sx={{ textAlign: "center" }}>
        <Typography textAlign="center" variant="h6" color="white" mb={1.3}>
          Using my content
        </Typography>
        <Typography
          textAlign="center"
          fontWeight="light"
          variant="body1"
          color="white"
          mb={2}
        >
          You need to sign up to upload & use your own content and save your
          designs.
        </Typography>
        <Link to="/welcome">
          <Button
            variant="contained"
            color="secondary"
            sx={{ textTransform: "none" }}
          >
            Sign up
          </Button>
        </Link>
      </Grid>
    </Grid>
  );
};

export default EditToolsContentMyContentDefault;
