import {
  MdAlignVerticalTop,
  MdAlignHorizontalLeft,
  MdAlignVerticalCenter,
  MdAlignHorizontalCenter,
  MdAlignVerticalBottom,
  MdAlignHorizontalRight,
} from "react-icons/md";

import PropertiesDropdown from "./PropertiesDropdown";
import { updateStudioElement } from "../../store/studioSlice";

const PropertiesPanelAlignment = () => {
  const alignmentItems = [
    {
      text: "Top",
      icon: <MdAlignVerticalTop size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { studioLayout, studioElements, studioTransformer } = studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            if (item.type === "text" || item.type === "menu") {
              dispatch(updateStudioElement({ id: item.id, y: 0 }));
            } else {
              dispatch(
                updateStudioElement({ id: item.id, y: item.height / 2 })
              );
            }
          }
        });
      },
    },
    {
      text: "Left",
      icon: <MdAlignHorizontalLeft size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { studioLayout, studioElements, studioTransformer } = studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            if (item.type === "text" || item.type === "menu") {
              dispatch(updateStudioElement({ id: item.id, x: 0 }));
            } else {
              dispatch(updateStudioElement({ id: item.id, x: item.width / 2 }));
            }
          }
        });
      },
    },
    {
      text: "Middle",
      icon: <MdAlignVerticalCenter size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { stage, studioLayout, studioElements, studioTransformer } =
          studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            const selectedNode = stage.findOne(`#${item.id}`);
            if (item.type === "text" || item.type === "menu") {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  y: (studioLayout.height - selectedNode.height()) / 2,
                })
              );
            } else {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  y: studioLayout.height / 2,
                })
              );
            }
          }
        });
      },
    },
    {
      text: "Center",
      icon: <MdAlignHorizontalCenter size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { stage, studioLayout, studioElements, studioTransformer } =
          studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            const selectedNode = stage.findOne(`#${item.id}`);
            if (item.type === "text" || item.type === "menu") {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  x: (studioLayout.width - selectedNode.width()) / 2,
                })
              );
            } else {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  x: studioLayout.width / 2,
                })
              );
            }
          }
        });
      },
    },
    {
      text: "Bottom",
      icon: <MdAlignVerticalBottom size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { stage, studioLayout, studioElements, studioTransformer } =
          studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );
        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            const selectedNode = stage.findOne(`#${item.id}`);
            if (item.type === "text" || item.type === "menu") {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  y: studioLayout.height - selectedNode.height(),
                })
              );
            } else {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  y: studioLayout.height - selectedNode.height() / 2,
                })
              );
            }
          }
        });
      },
    },
    {
      text: "Right",
      icon: <MdAlignHorizontalRight size={18} />,
      onChange: ({ studio, dispatch }) => {
        const { stage, studioLayout, studioElements, studioTransformer } =
          studio;
        const transformerNodesIds = studioTransformer.nodes.map((item) =>
          item.id()
        );

        studioElements.forEach((item) => {
          if (transformerNodesIds.includes(item.id)) {
            const selectedNode = stage.findOne(`#${item.id}`);
            if (item.type === "text" || item.type === "menu") {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  x: studioLayout.width - selectedNode.width(),
                })
              );
            } else {
              dispatch(
                updateStudioElement({
                  id: item.id,
                  x: studioLayout.width - selectedNode.width() / 2,
                })
              );
            }
          }
        });
      },
    },
  ];

  return (
    <PropertiesDropdown
      size={190}
      isMultipleSeletion={false}
      items={alignmentItems}
      buttonText="Alignment"
      buttonIcon={<MdAlignHorizontalCenter />}
    />
  );
};

export default PropertiesPanelAlignment;
