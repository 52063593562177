import { ChromePicker } from "react-color";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Divider, Switch, Typography, Slider } from "@mui/material";

// TODO: Use webpack alias instead of relative path
import { updateStudioElement } from "../../store/studioSlice";

const PropertiesPanelStroke = () => {
  const dispatch = useDispatch();
  const [colorPickerWidth, setColorPickerWidth] = useState(0);
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedElementStroke =
    selectedElement?.type === "shape"
      ? selectedElement.borderColor
      : selectedElement.stroke;

  const selectedElementStrokeWidth =
    selectedElement?.type === "shape"
      ? selectedElement.borderWidth
      : selectedElement.strokeWidth;

  const [colorPicker, setColorPicker] = useState(
    selectedElementStroke || "#000"
  );

  const [colorPickerSwitch, setColorPickerSwitch] = useState(
    selectedElementStroke ? true : false
  );

  useEffect(() => {
    if (!colorPickerWidth) {
      const propertiesPanelWidth = document.querySelector(
        "#style-settings-element"
      )?.offsetWidth;
      setColorPickerWidth(propertiesPanelWidth - 36);
    }
  }, []);

  const onChangeColorPickerFill = (color) => {
    setColorPicker(color);

    if (!colorPickerSwitch) {
      setColorPickerSwitch(true);
    }

    if (selectedElement?.type === "shape") {
      dispatch(updateStudioElement({ borderColor: color.hex }));
    } else {
      dispatch(updateStudioElement({ stroke: color.hex }));
    }
  };

  const onSwitchChange = (event) => {
    const checked = event.target.checked;
    setColorPickerSwitch(checked);
    if (checked) return;

    if (selectedElement?.type === "shape") {
      dispatch(updateStudioElement({ borderColor: null, borderWidth: 0 }));
    } else {
      dispatch(updateStudioElement({ stroke: null, strokeWidth: 0 }));
    }
  };

  const onStrokeWidthChange = (event) => {
    const value = event.target.value;

    if (selectedElement?.type === "shape") {
      dispatch(updateStudioElement({ borderWidth: value }));
    } else {
      dispatch(updateStudioElement({ strokeWidth: value }));
    }
  };

  return (
    <Box px={2}>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="body1">Stroke</Typography>
        </Grid>
        <Grid item>
          <Switch checked={colorPickerSwitch} onChange={onSwitchChange} />
        </Grid>
      </Grid>
      <Divider light />

      <Box mt={2} mb={1}>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="caption">Thickness</Typography>
          </Grid>
          <Grid item xs px={2}>
            <Box mt={1}>
              <Slider
                min={0}
                max={10}
                step={0.2}
                size="small"
                onChange={onStrokeWidthChange}
                value={selectedElementStrokeWidth}
                disabled={!colorPickerSwitch ? true : false}
              />
            </Box>
          </Grid>
          <Grid width={40}>
            <Typography variant="caption">{`${selectedElementStrokeWidth} px`}</Typography>
          </Grid>
        </Grid>

        <ChromePicker
          disableAlpha
          color={colorPicker}
          width={colorPickerWidth}
          onChange={onChangeColorPickerFill}
        />
      </Box>
    </Box>
  );
};

export default PropertiesPanelStroke;
