import Konva from "konva";
import { ImShrink } from "react-icons/im";
import { CgEditFade } from "react-icons/cg";
import { MdMultipleStop } from "react-icons/md";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { TbArrowBigRightLines } from "react-icons/tb";
import { IoBanOutline, IoFlash } from "react-icons/io5";
import { HiOutlineSwitchVertical } from "react-icons/hi";

const baseAttributes = ({ studio, node, duration }) => {
  const { studioElements } = studio;
  const selectedElement = studioElements.find((item) => item.id === node.id());
  const nodeDelay = selectedElement?.animation?.start || 0;
  const nodeDuration = selectedElement?.animation?.duration?.number || duration;

  node.opacity(0);

  return {
    node,
    opacity: 1,
    duration: nodeDuration - nodeDelay,
    easing: Konva.Easings.EaseInOut,
  };
};

const getSlideFromBound = ({ studio, node, mode = "slide" }) => {
  const { studioLayout, studioElements } = studio;
  const selectedElement = studioElements.find((item) => item.id === node.id());
  const nodeX = node.x();
  const nodeY = node.y();
  const movementAmount = 100;
  const from = selectedElement?.animation?.from;

  switch (from) {
    case "bottom":
      if (mode === "slide") {
        node.y(nodeY + movementAmount);
      } else if (mode === "big-slide") {
        node.y(studioLayout.height);
      }
      break;

    case "left":
      if (mode === "slide") {
        node.x(nodeX - movementAmount);
      } else if (mode === "big-slide") {
        node.x(0);
      }
      break;

    case "top":
      if (mode === "slide") {
        node.y(nodeY - movementAmount);
      } else if (mode === "big-slide") {
        node.y(0);
      }
      break;

    case "right":
      if (mode === "slide") {
        node.x(nodeX + movementAmount);
      } else if (mode === "big-slide") {
        node.x(studioLayout.width);
      }
      break;

    default:
      // default is left movement
      if (mode === "slide") {
        node.x(nodeX - movementAmount);
      } else if (mode === "big-slide") {
        node.x(0);
      }
      break;
  }

  return {
    x: nodeX,
    y: nodeY,
  };
};

/* 
  *******************
  Animation Functions
  *******************
*/

const FadeAnimation = ({ studio, node, duration, options = {} }) => {
  const attributes = baseAttributes({ studio, node, duration });

  return new Konva.Tween({
    ...attributes,
    ...options,
  });
};

const SlideAnimation = ({ studio, node, duration, options = {} }) => {
  const attributes = baseAttributes({ studio, node, duration });
  const { x, y } = getSlideFromBound({ studio, node });

  return new Konva.Tween({
    ...attributes,
    ...options,
    x,
    y,
  });
};

const BigSlideAnimation = ({ studio, node, duration, options = {} }) => {
  const attributes = baseAttributes({ studio, node, duration });
  const { x, y } = getSlideFromBound({ studio, node, mode: "big-slide" });

  return new Konva.Tween({
    ...attributes,
    ...options,
    x,
    y,
  });
};

const ShrinkAnimation = ({ studio, node, duration, options = {} }) => {
  const attributes = baseAttributes({ studio, node, duration });
  const nodeScaleX = node.scaleX();
  const nodeScaleY = node.scaleY();

  node.scaleX(nodeScaleX + 0.3);
  node.scaleY(nodeScaleY + 0.3);

  return new Konva.Tween({
    ...attributes,
    ...options,
    scaleX: nodeScaleX,
    scaleY: nodeScaleY,
  });
};

const ExpandAnimation = ({ studio, node, duration, options = {} }) => {
  const attributes = baseAttributes({ studio, node, duration });
  const nodeScaleX = node.scaleX();
  const nodeScaleY = node.scaleY();

  node.scaleX(nodeScaleX - 0.3);
  node.scaleY(nodeScaleY - 0.3);

  return new Konva.Tween({
    ...attributes,
    ...options,
    scaleX: nodeScaleX,
    scaleY: nodeScaleY,
  });
};

const FlipAnimation = ({ studio, node, duration, options = {} }) => {
  const attributes = baseAttributes({ studio, node, duration });
  const nodeScaleY = node.scaleY();

  node.scaleY(nodeScaleY - 0.3);

  return new Konva.Tween({
    ...attributes,
    ...options,
    scaleY: nodeScaleY,
  });
};

const FlashAnimation = ({ studio, node, duration, options = {} }) => {
  const attributes = baseAttributes({ studio, node, duration });

  return new Konva.Tween({
    ...attributes,
    ...options,
    easing: Konva.Easings.BackEaseInOut,
  });
};

const ANIMATIONS = [
  {
    key: "none",
    name: "None",
    icon: IoBanOutline,
    animation: () => {},
  },
  {
    key: "fade",
    name: "Fade",
    icon: CgEditFade,
    animation: FadeAnimation,
  },
  {
    key: "slide",
    name: "Slide",
    icon: MdMultipleStop,
    animation: SlideAnimation,
  },
  {
    key: "big-slide",
    name: "Big Slide",
    icon: TbArrowBigRightLines,
    animation: BigSlideAnimation,
  },
  {
    key: "shrink",
    name: "Shrink",
    icon: ImShrink,
    animation: ShrinkAnimation,
  },
  {
    key: "expand",
    name: "Expand",
    icon: FaExpandArrowsAlt,
    animation: ExpandAnimation,
  },
  {
    key: "flip",
    name: "Flip",
    icon: HiOutlineSwitchVertical,
    animation: FlipAnimation,
  },
  {
    key: "flash",
    name: "Flash",
    icon: IoFlash,
    animation: FlashAnimation,
  },
];

export default ANIMATIONS;
