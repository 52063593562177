import { RiVideoLine } from "react-icons/ri";
import { CgFormatText } from "react-icons/cg";
import { AiFillEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { BsCaretUpSquare } from "react-icons/bs";
import { TbLock, TbLockOpen } from "react-icons/tb";
import { HiOutlineSquares2X2 } from "react-icons/hi2";
import { useSelector, useDispatch } from "react-redux";
import { Grid, IconButton, Typography } from "@mui/material";
import { MdOutlineImage, MdClose, MdTexture } from "react-icons/md";

// TODO: Use webpack alias instead of relative path
import {
  updateStudioLayout,
  updateStudioElement,
  removeStudioElement,
  resetStudioTransformer,
  updateStudioColorPicker,
  resetStudioCurrentTarget,
  removeStudioGroupedElements,
} from "../../store/studioSlice";
import { setSelectedMenuGroup, setShowAllMenu } from "../../store/menuSlice";

const HeaderTitle = () => {
  let title = "";
  const { studioId, studioCurrentTarget, studioElements, studioTransformer } =
    useSelector((state) => state.studio.present);
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedGroupElements = studioTransformer.nodes;
  const isGroupSelected = selectedGroupElements.length > 1;
  const selectedElementType = selectedElement?.type;

  if (isGroupSelected) title = `${selectedGroupElements.length} Selected`;
  else {
    if (studioCurrentTarget === studioId) title = "Background Color";
    else if (selectedElementType === "text") title = selectedElement.text;
    else if (selectedElementType === "menu") title = "Menu Element";
    else title = selectedElement?.name;
  }

  return (
    <Typography noWrap={true} variant="body2" ml={0.5}>
      {title}
    </Typography>
  );
};

const HeaderIcon = () => {
  let icon = null;
  const { studioCurrentTarget, studioElements, studioTransformer } =
    useSelector((state) => state.studio.present);
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const isGroupSelected = studioTransformer.nodes.length > 1;

  if (isGroupSelected) {
    icon = <HiOutlineSquares2X2 size="1.3em" />;
  } else {
    switch (selectedElement?.type) {
      case "stage":
        icon = <MdOutlineImage size="1.3em" />;
        break;
      case "shape":
        icon = <BsCaretUpSquare size="1em" />;
        break;
      case "image":
        icon = <MdOutlineImage size="1.3em" />;
        break;
      case "video":
        icon = <RiVideoLine size="1.3em" />;
        break;
      case "text":
        icon = <CgFormatText size="1.3em" />;
        break;
      default:
        icon = <BsCaretUpSquare size="1.3em" />;
        break;
    }
  }

  return icon;
};

const HeaderActions = () => {
  const dispatch = useDispatch();
  const { studioId, studioElements, studioCurrentTarget, studioTransformer } =
    useSelector((state) => state.studio.present);
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedElementType = selectedElement?.type;
  const selectedElementDraggable = selectedElement?.draggable;
  const selectedGroupElements = studioTransformer.nodes;
  const isGroupSelected = selectedGroupElements.length > 1;
  const isSelectedElementSetAsBackground = selectedElement?.setAsBackground;

  const onLock = () => {
    dispatch(updateStudioElement({ draggable: !selectedElementDraggable }));
  };

  const onDelete = () => {
    if (studioCurrentTarget === studioId) {
      dispatch(updateStudioLayout({ fill: "#fff" }));
    }

    if (isGroupSelected) {
      const selectedGroupElementsIds = selectedGroupElements.map((item) =>
        item.id()
      );
      dispatch(removeStudioGroupedElements(selectedGroupElementsIds));
    } else {
      dispatch(removeStudioElement());
    }

    dispatch(resetStudioCurrentTarget());
    dispatch(resetStudioTransformer());
  };
  const onEdit = () => {
    if (selectedElementType === "menu") {
      dispatch(setShowAllMenu(true));
      dispatch(setSelectedMenuGroup(selectedElement));
      dispatch(resetStudioTransformer());
    }
  };

  const onSetAsBackground = () => {
    dispatch(
      updateStudioElement({
        setAsBackground: !isSelectedElementSetAsBackground,
      })
    );
  };

  return (
    <Grid item>
      {!isSelectedElementSetAsBackground &&
        (selectedElementType === "shape" ||
          selectedElementType === "image" ||
          selectedElementType === "widget" ||
          selectedElementType === "video" ||
          selectedElementType === "text") && (
          <IconButton size="small" onClick={onLock}>
            {!selectedElementDraggable ? (
              <TbLock color="black" className="cursor-pointer" size="1.2em" />
            ) : (
              <TbLockOpen
                color="black"
                className="cursor-pointer"
                size="1.2em"
              />
            )}
          </IconButton>
        )}

      {(selectedElementType === "image" || selectedElementType === "video") && (
        <IconButton size="small" onClick={onSetAsBackground}>
          <MdTexture
            color={isSelectedElementSetAsBackground ? "#1976D2" : "black"}
            className="cursor-pointer"
            size="1.2em"
          />
        </IconButton>
      )}
      {selectedElementType === "menu" && (
        <IconButton size="small" onClick={onEdit}>
          <AiFillEdit color="black" className="cursor-pointer" size="1.2em" />
        </IconButton>
      )}
      <IconButton size="small" onClick={onDelete}>
        <AiOutlineDelete
          color="black"
          className="cursor-pointer"
          size="1.2em"
        />
      </IconButton>
    </Grid>
  );
};

const HeaderCloseIcon = () => {
  const dispatch = useDispatch();
  const onBack = () => {
    dispatch(updateStudioColorPicker(""));
  };

  return (
    <IconButton size="small" onClick={onBack}>
      <MdClose size="1.2em" />
    </IconButton>
  );
};

const PropertiesPanelHeader = () => {
  const { studioColorPicker } = useSelector((state) => state.studio.present);

  return (
    <Grid container alignItems="center" px={2} py={1} wrap="nowrap">
      <Grid container item xs={7} alignItems="center">
        <HeaderIcon />
        <Grid item xs zeroMinWidth>
          <HeaderTitle />
        </Grid>
      </Grid>
      <Grid container item xs={5} justifyContent="flex-end">
        {!studioColorPicker ? <HeaderActions /> : <HeaderCloseIcon />}
      </Grid>
    </Grid>
  );
};

export default PropertiesPanelHeader;
