import { v4 as uuid } from "uuid";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  ImageList,
  ImageListItem,
  CircularProgress,
} from "@mui/material";

import request, { baseURL } from "../../services/request";
import { getToken } from "../../services/localStorage";
import { setToolsDragElement } from "../../store/toolsSlice";
import EditToolsContentMyContentDefault from "./EditToolsContentMyContentDefault";
import { useAuthenticated } from "../../hooks";

const EditToolsContentWidgets = () => {
  const isAuthenticated = useAuthenticated();
  const dispatch = useDispatch();
  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const { contentScrollEnd } = useSelector((state) => state.tools);

  const getWidgetList = async () => {
    setLoading(true);

    const token = getToken();

    try {
      const res = await request({
        method: "get",
        url: "/widgets",
        params: {
          page: currentPage,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const widgetList = res.data.data;
      setWidgets(widgetList);
      setPrevPage(currentPage);
    } catch (error) {
      setWidgets([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWidgetList();
  }, []);

  useEffect(() => {
    if (contentScrollEnd) {
      setCurrentPage(currentPage + 1);
    }
  }, [contentScrollEnd]);

  useEffect(() => {
    if (prevPage !== currentPage) {
      getWidgetList();
    }
  }, [currentPage, prevPage]);

  if (!isAuthenticated) {
    return <EditToolsContentMyContentDefault />;
  }
  return loading ? (
    <Grid container justifyContent="center" py={2}>
      <CircularProgress />
    </Grid>
  ) : (
    <ImageList variant="standard" cols={5} gap={10}>
      {widgets.length
        ? widgets.map((widget, index) => {
            const widgetSrc = widget.icon;
            console.log({ widgetSrc });
            return (
              <ImageListItem
                key={`${widget.id}-${index}`}
                draggable
                onDragStart={(e) => {
                  dispatch(
                    setToolsDragElement({
                      scaleX: 1,
                      scaleY: 1,
                      opacity: 1,
                      rotation: 0,
                      type: "widget",
                      src: widgetSrc,
                      draggable: true,
                      animation: null,
                      width: 150,
                      height: 150,
                      name: widget.name,
                      link: widget.link,
                      id: `widget-${uuid()}`,
                    })
                  );
                }}
                onDragEnd={(e) => {
                  dispatch(setToolsDragElement(null));
                }}
              >
                <img src={widgetSrc} alt={widget.name} loading="lazy" />
                <span className="text-center">
                  {widget.name?.length > 10
                    ? widget.name?.slice(0, 10)
                    : widget.name}
                </span>
              </ImageListItem>
            );
          })
        : ""}
    </ImageList>
  );
};

export default EditToolsContentWidgets;
