import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import {
  Box,
  Menu,
  Avatar,
  Divider,
  MenuItem,
  IconButton,
  ListItemIcon,
  Typography,
} from "@mui/material";

import request from "../../services/request";
import { getToken, removeToken } from "../../services/localStorage";
import { setUser } from "../../store/authSlice";

const EditToolsMenuProfile = () => {
  const dispatch = useDispatch();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isProfileMenuOpen = Boolean(anchorEl);

  const openProfileMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeProfileMenu = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    closeProfileMenu();
    removeToken();
    window.location.reload(false);
  };

  const getProfile = async () => {
    setLoading(true);

    const token = getToken();

    try {
      const res = await request({
        method: "get",
        url: "/profile",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const profileData = res?.data?.user;
      const roles = res?.data?.roles;
      setProfile(profileData);
      dispatch(setUser({ roles }));
    } catch (error) {
      setProfile(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <Box
        color="white"
        className="absolute left-0 right-0 bottom-4 mx-auto bg-gray-800 flex justify-center items-center"
        sx={{ width: 46, height: 46, borderRadius: 100 }}
        onClick={openProfileMenu}
      >
        <IconButton color="inherit">
          <AiOutlineUser size={"1em"} />
        </IconButton>
      </Box>
      <Menu
        open={isProfileMenuOpen}
        anchorEl={anchorEl}
        onClose={closeProfileMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            ml: 3,
            width: "310px",
            borderRadius: "0.5rem",
            filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.16))",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={closeProfileMenu}>
          <Avatar
            alt={profile?.name}
            src={profile?.avatar}
            sx={{ marginRight: 1, marginRight: 2 }}
          />
          <Box>
            <Typography variant="body1" fontWeight="bold">
              {profile?.email}
            </Typography>
            <Typography variant="body2">{profile?.name}</Typography>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <MdLogout size={"1.5em"} />
          </ListItemIcon>
          Log Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default EditToolsMenuProfile;
