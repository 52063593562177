import { Box, Divider } from "@mui/material";
import { useSelector } from "react-redux";

import PropertiesPanelFill from "./PropertiesPanelFill";
import PropertiesPanelHeader from "./PropertiesPanelHeader";
import PropertiesPanelStroke from "./PropertiesPanelStroke";
import PropertiesPanelSettings from "./PropertiesPanelSettings";
import PropertiesPanelBackground from "./PropertiesPanelBackground";
import PropertiesPanelIcon from "./PropertiesIconColorEffect"

const PropertiesPanel = () => {
  const studio = useSelector((state) => state.studio.present);
  const {
    studioId,
    studioCurrentTarget,
    studioColorPicker,
    studioTransformer,
  } = studio;
  const isGroupSelected = studioTransformer.nodes.length > 1;

  return (
    <>
      {studioCurrentTarget && <PropertiesPanelHeader />}

      <Divider variant="middle" light />
      {studioCurrentTarget === studioId && !isGroupSelected && (
        <PropertiesPanelBackground />
      )}

      {((studioCurrentTarget !== studioId && !studioColorPicker) ||
        isGroupSelected) && <PropertiesPanelSettings />}
      {studioCurrentTarget !== studioId && studioColorPicker === "fill" && (
        <PropertiesPanelFill />
      )}
      {studioCurrentTarget !== studioId && studioColorPicker === "Iconfill" && (
        <PropertiesPanelIcon />
      )}
      {studioCurrentTarget !== studioId && studioColorPicker === "stroke" && (
        <PropertiesPanelStroke />
      )}
    </>
  );
};

export default PropertiesPanel;
