import { v4 as uuid } from "uuid";
import { MdClose } from "react-icons/md";
import { AiOutlineStop } from "react-icons/ai";
import { FcOpenedFolder } from "react-icons/fc";
import { RiLightbulbLine } from "react-icons/ri";
import { RiUploadCloud2Line } from "react-icons/ri";
import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Box, Button, Typography, Popover, Divider } from "@mui/material";

import { useAdminRole } from "../../hooks";
import request from "../../services/request";
import { getToken } from "../../services/localStorage";
import { setTargetEndpoint } from "../../store/requestSlice";

const EditToolsContentMyContentFilesUpload = () => {
  const fileInput = useRef();
  const dispatch = useDispatch();
  const isAdmin = useAdminRole();
  const [file, setFile] = useState(null);
  const [fileStatus, setFileStatus] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const isUploadenuOpen = Boolean(anchorEl);

  const openUploadMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeUploadMenu = () => {
    setAnchorEl(null);
  };

  const onUploadZoneClick = () => {
    onClearFile();
  };

  const onClearFile = () => {
    fileInput.current.value = "";
    setFile(null);
  };
  const onFileChange = (e) => {
    const [selectedFile] = e.target.files;
    setFile(selectedFile);
    uploadFile(selectedFile);
  };

  const loadImage = (imageSrc) =>
    new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        const height = image.height;
        const width = image.width;
        resolve({ image, width, height });
      };
      image.src = imageSrc;
    });

  const uploadFile = async (selectedFile) => {
    try {
      const [selectedFileType] = selectedFile.type.split("/");
      setFileStatus("Uploading");
      let h = 0,
        w = 0;
      const token = getToken();
      if (selectedFileType !== "image") {
        const videoElement = document.createElement("video");
        videoElement.src = URL.createObjectURL(selectedFile);
        videoElement.onloadedmetadata = () => {
          // Extract dimensions from the video file
          const { videoWidth, videoHeight } = videoElement;
          h = videoHeight;
          w = videoWidth;
        };
      } else {
        const { width, height } = await loadImage(
          URL.createObjectURL(selectedFile)
        );
        h = height;
        w = width;
      }

      const categoryFromStorage = localStorage.getItem("category");

      const category = categoryFromStorage
        ? JSON.parse(categoryFromStorage)
        : null;

      const newFile = {
        width: w,
        height: h,
        src: "",
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        rotation: 0,
        animation: null,
        draggable: true,
        type: selectedFileType,
        name: selectedFile.name,
        id: `${selectedFileType}-${uuid()}`,
      };

      const fileJSONString = JSON.stringify(newFile);
      let fileJSON = new Blob([fileJSONString], {
        type: "application/json",
      });

      const url = isAdmin ? `templates/store` : `files/store`;

      const data = new FormData();

      if (isAdmin) {
        data.append("name", selectedFile.name || `template-${uuid()}`);
        data.append("template_json", fileJSON, "template-json.json");
        data.append("layout", 1);
        data.append("file", selectedFile, selectedFile.name);
        data.append("file_json", selectedFile.size);
        data.append("category", category?.id || 1);
        data.append("premium", 0);
      } else {
        data.append("file_size", selectedFile.size);
        data.append("name", selectedFile.name || `file-${uuid()}`);
        data.append("file", selectedFile, selectedFile.name);
        data.append("file_json", fileJSON, "file-json.json");
      }

      const res = await request({
        url,
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data,
      });

      closeUploadMenu();
      setFileStatus("Uploaded!");
      dispatch(setTargetEndpoint("get-files"));
    } catch (error) {
      setFileStatus("Error!");
    } finally {
    }
  };

  return (
    <>
      <Button
        fullWidth
        size="large"
        color="secondary"
        variant="contained"
        sx={{ borderRadius: 2, textTransform: "none" }}
        onClick={openUploadMenu}
      >
        <RiUploadCloud2Line size={"1.6em"} />
        <Typography sx={{ marginLeft: 1 }}>Upload</Typography>
      </Button>
      <Popover
        open={isUploadenuOpen}
        anchorEl={anchorEl}
        onClose={closeUploadMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            ml: 4,
            width: "420px",
            borderRadius: "0.5rem",
            filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.16))",
          },
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box px={2} py={1.5}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container item xs alignItems="center">
              <RiUploadCloud2Line size={22} />
              <Typography fontSize={16} ml={1}>
                Upload
              </Typography>
            </Grid>
            <Grid item>
              <MdClose size={20} cursor="pointer" onClick={closeUploadMenu} />
            </Grid>
          </Grid>
        </Box>

        <Divider light />

        <Box px={2} py={1.5}>
          <Typography textAlign="center">Add photos & videos</Typography>
        </Box>

        <Box px={2} mb={1.5}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: 150 }}
            className="relative cursor-pointer rounded-xl bg-gray-50 text-gray-500 border-dashed border-gray-200 border-2 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-500"
            onClick={onUploadZoneClick}
          >
            <Grid item>
              <input
                type="file"
                ref={fileInput}
                multiple={false}
                onChange={onFileChange}
                className="absolute top-0 left-0 right-0 bottom-0 opacity-0 cursor-pointer"
              />
              <FcOpenedFolder size={"4em"} className="mx-auto" />
              <Typography textAlign="center" color="inherit" variant="body2">
                Drag & Drop here
              </Typography>
              <Typography textAlign="center" color="inherit" variant="body2">
                mp4, jpg, png or browse
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {file ? (
          <>
            <Divider light />

            <Box px={2} pt={1.5} pb={1}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="#a7aeb3" mb={0.5}>
                    Uploaded file
                  </Typography>
                  <Typography variant="body1" noWrap={true}>
                    {file.name}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="#a7aeb3" mb={0.5}>
                    Status
                  </Typography>
                  <Typography variant="body1">{fileStatus}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="#a7aeb3" mb={0.5}>
                    Clear
                  </Typography>
                  <AiOutlineStop
                    size={"1.5em"}
                    opacity={0.25}
                    onClick={onClearFile}
                    className="cursor-pointer"
                  />
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <Box px={4} pb={1.5}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <RiLightbulbLine size={"1.75em"} color="#2747d7" />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2">
                  <span style={{ color: "#2747d7" }}> Quick Tip: </span>
                  After closing this window, you can drag & drop your files
                  anywhere on the screen to upload.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Popover>
    </>
  );
};

export default EditToolsContentMyContentFilesUpload;
