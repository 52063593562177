// import { Rect } from "react-konva";
// import { useSelector } from "react-redux";

// const StudioTemplate = () => {
//   const { studioLayout } = useSelector((state) => state.studio.present);

//   return (
//     <Rect
//       id="studio-template"
//       fill={studioLayout.fill}
//       width={studioLayout.width}
//       height={studioLayout.height}
//     ></Rect>
//   );
// };

// export default StudioTemplate;



import { Rect } from "react-konva";
import { useSelector } from "react-redux";

const StudioTemplate = () => {
  const { studioLayout } = useSelector((state) => state.studio.present);

  // Ensure the template scales appropriately
  const scaleX = studioLayout.zoomScale / 100;
  const scaleY = studioLayout.zoomScale / 100;

  console.log("scaleX : ",scaleX);
  console.log("studioLayout.width : ",studioLayout.width);
  return (
    <Rect
      id="studio-template"
      fill={ studioLayout.fill} // Set the background color
      width={studioLayout.width} // Maintain original width
      height={studioLayout.height} // Maintain original height
      x={0} // Set x to 0 to align with the top-left corner
      y={0} // Set y to 0 to align with the top-left corner
      scaleX={scaleX} // Dynamically scale based on zoom
      scaleY={scaleY} // Dynamically scale based on zoom
    />
  );
};

export default StudioTemplate;
