import { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { updateStudioElement } from "../../store/studioSlice";

const PropertiesPanelDelayTime = () => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements, studioDuration } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  const selectedElementEffect = selectedElement?.animation;
  const start = selectedElementEffect?.start || 0;

  const onChangeDelayTime = (event, newvalue) => {
    const value = event?.target?.value || newvalue;

    if (value > studioDuration || value < 0) return;

    const animation = {
      ...selectedElementEffect,
      start: value,
    };

    dispatch(updateStudioElement({ animation }));
  };

  useEffect(() => {
    if (start > studioDuration) {
      onChangeDelayTime(undefined, studioDuration);
    }
  }, [studioDuration]);

  return (
    <Grid container alignItems="center" spacing={1} py={1}>
      <Grid item xs={4}>
        <span className="text-sm">delay time</span>
      </Grid>
      <Grid item xs>
        <TextField
          value={start}
          size="small"
          label="seconds"
          variant="outlined"
          type="number"
          onChange={onChangeDelayTime}
        />
      </Grid>
    </Grid>
  );
};

export default PropertiesPanelDelayTime;
