import { useCookies } from "react-cookie";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Welcome from "./pages/Welcome";
import Studio from "./components/Studio";
import Onboarding from "./pages/Onboarding";
import StudioNew from "./components/Studio/index.new";

const App = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["visited"]);

  if (!cookies.visited) {
    setCookie("visited", true);
  }

  return (
    <>
      <Router>
        <Routes>
          {/* <Route path="/" element={<StudioNew />} /> */}
          <Route path="/" element={<Studio />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/onboarding" element={<Onboarding />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
