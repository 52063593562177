import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BsPlayFill, BsStopFill } from "react-icons/bs";
import { Box, Button, Grid, Typography, Slider } from "@mui/material";

import PropertiesStepperButton from "./PropertiesStepperButton";
import { setStudioPlay, setStudioDuration } from "../../store/studioSlice";

const PropertiesAnimationController = () => {
  const dispatch = useDispatch();
  const [slider, setSlider] = useState(0);
  const { studioPlay, studioDuration } = useSelector(
    (state) => state.studio.present
  );
  let [animationInterval, setAnimationInterval] = useState();

  const onDurationChange = (duration) => {
    dispatch(setStudioDuration(duration));
  };

  const onPlay = () => {
    dispatch(setStudioPlay(!studioPlay));
  };

  const startSlider = () => {
    let count = slider;
    const duration = studioDuration || 1;
    const interval = setInterval(() => {
      if (count >= 100) {
        count = 0;
        dispatch(setStudioPlay(false));
        dispatch(setStudioPlay(true));
        setSlider(0);
      }
      setSlider(count++);
    }, duration * 10);
    setAnimationInterval(interval);
  };

  const stopSlider = () => {
    setSlider(0);
    clearInterval(animationInterval);
  };

  useEffect(() => {
    if (studioPlay) {
      startSlider();
    } else if (studioPlay === false) {
      stopSlider();
    }
  }, [studioPlay]);

  return (
    <Box
      px={2}
      pt={2}
      pb={1}
      sx={{
        background: "white",
        borderTopLeftRadius: 30,
        filter: "drop-shadow(0px 3px 15px rgba(0,0,0,0.2))",
      }}
    >
      <Grid alignItems="center" container wrap="nowrap">
        <Grid item xs>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            style={{ backgroundColor: "#5DBBE4" }}
            sx={{
              fontSize: 14,
              paddingTop: 0.65,
              borderRadius: 1.7,
              paddingBottom: 0.65,
            }}
            startIcon={
              studioPlay ? (
                <BsStopFill size={"1.3em"} />
              ) : (
                <BsPlayFill size={"1.3em"} />
              )
            }
            onClick={onPlay}
          >
            {studioPlay ? "Stop" : "Play"}
          </Button>
        </Grid>
        <Grid item>
          <Box mb={0.7}>
            <Typography align="center" fontSize={11}>
              Duration
            </Typography>
            <PropertiesStepperButton
              defaultValue={studioDuration}
              onChange={onDurationChange}
              animation={true}
            />
          </Box>
        </Grid>
      </Grid>
      <Box mt={0.5} px={1}>
        <Slider
          value={slider}
          style={{ color: "#5DBBE4" }}
          disableSwap
          className="properties-animation-slider"
        />
      </Box>
    </Box>
  );
};

export default PropertiesAnimationController;
