import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";

import animations from "../../animations";
import { createReactElement } from "../../utils";
import { updateStudioElement } from "../../store/studioSlice";
import PropertiesAnimationEffectsFrom from "./PropertiesAnimationEffectsFrom";
import PropertiesAnimationEffectsStart from "./PropertiesAnimationEffectsStart";
import PropertiesAnimationEffectsDuration from "./PropertiesAnimationEffectsDuration";
import CustomToolTip from "../CustomToolTip";
import SideBarHeading from "../Heading";
const styles = {
  propertiesHeading: {
    color: "#2c2b44",
    display: "inline-block",
    fontWeight: "300",
    letterSpacing: "1px",
    marginBottom: "8px",
    textOverflow: "clip",
    whiteSpace: "normal",
    fontSize: "14px",
  },
};
const PropertiesAnimationEffects = () => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedElementEffect = selectedElement?.animation;
  const selectedElementEffectDuration = selectedElementEffect?.duration;

  const isSlideAnimation = (key) => {
    return ["slide", "big-slide"].includes(key);
  };

  const updateNodeAnimation = (props) => {
    const animation = {
      ...props,
      duration: selectedElementEffectDuration,
    };
    dispatch(updateStudioElement({ animation }));
  };

  return (
    <Box px={2} pb={1} sx={{}}>
      <CustomToolTip
        content={
          "To implement animations, it is important to carefully select the appropriate animation type, duration, and direction for your desired effect. By choosing the right combination of these parameters, you can create a visually engaging and intuitive user experience."
        }
      >
        {/* <p style={styles.propertiesHeading} mb={2}>
          Intro Animations
        </p> */}
        <SideBarHeading content={"Intro Animations"} />
      </CustomToolTip>

      <PropertiesAnimationEffectsStart />

      <PropertiesAnimationEffectsDuration />

      {isSlideAnimation(selectedElementEffect?.key) && (
        <PropertiesAnimationEffectsFrom />
      )}

      <Grid container alignItems="center" spacing={1}>
        {animations.map((item) => (
          <Grid
            item
            xs={3}
            key={item.name}
            onClick={() =>
              updateNodeAnimation({
                start: 0,
                duration: {
                  key: "",
                  number: 1,
                },
                key: item.key,
              })
            }
            className={`cursor-pointer ${
              selectedElementEffect?.key === item.key
                ? "text-blue-500"
                : "hover:text-blue-500"
            }`}
            sx={{
              color: selectedElementEffect?.key === item.key ? "#5DBBE4" : "",
              "&:hover": {
                color: "#5DBBE4",
              },
            }}
          >
            <Grid
              container
              alignContent="center"
              justifyContent="center"
              sx={{ width: 20, height: 20 }}
              className="bg-gray-100 rounded-lg mx-auto"
            >
              {createReactElement(item.icon, { size: "1.6em" })}
            </Grid>
            <Typography mt={0.25} fontSize={10} textAlign="center">
              {item.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PropertiesAnimationEffects;
