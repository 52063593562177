import { CiText } from "react-icons/ci";
import { TfiText } from "react-icons/tfi";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { BsTriangle, BsTriangleFill } from "react-icons/bs";

import { updateStudioColorPicker } from "../../store/studioSlice";
import SideBarHeading from "../Heading";

const PropertiesPanelShapeColor = () => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedElementFill =
    selectedElement?.type === "shape"
      ? `rgb(${selectedElement.red}, ${selectedElement.green}, ${selectedElement.blue} )`
      : selectedElement?.fill;

  const selectedElementHasFill =
    selectedElement?.type === "shape"
      ? selectedElement.red
      : selectedElement?.fill;

  const selectedElementIconFill =
    selectedElement?.type === "shape"
      ? `rgb(${selectedElement.red}, ${selectedElement.green}, ${selectedElement.blue} )`
      : selectedElement?.Iconfill;

  const selectedElementHasIconFill =
    selectedElement?.type === "shape"
      ? selectedElement.red
      : selectedElement?.Iconfill;

  const selectedElementStroke =
    selectedElement?.type === "shape"
      ? selectedElement.borderColor
      : selectedElement?.stroke;

  const shapeColorBoxStyle = {
    borderWidth: "1px",
    marginTop: "0.2rem",
    borderColor: "#aaa",
    position: "relative",
    borderRadius: "0.15rem",
    backgroundColor: "transparent",
  };

  const shapeColorBoxStyleAfter = {
    left: "0",
    top: "2px",
    content: '""',
    height: "1px",
    width: "100%",
    position: "absolute",
    backgroundColor: "#bbb",
    transform: "rotate(12deg)",
  };

  const onIconClick = (mode) => {
    dispatch(updateStudioColorPicker(mode));
  };

  return (
    <Box width={"100%"} spacing={1} py={1}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        <IconButton
          sx={{
            borderRadius: "0.3rem",
            display: "flex",
            "&:hover": {
              backgroundColor: "transparent", // Override the hover background color
            },
          }}
          onClick={(e) => onIconClick("fill")}
        >
          <div>
            {selectedElement?.type === "text" ||
              selectedElement?.type === "menu" ? (
              // <TfiText size={"0.9em"} style={{ margin: "auto" }} />
              <SideBarHeading content={"Fill"} />
            ) : (
              <BsTriangleFill size={"0.7em"} style={{ margin: "auto" }} />
            )}
          </div>
        </IconButton>
        <Box
          onClick={(e) => onIconClick("fill")}
          width={50}
          height={30}
          sx={{
            ...shapeColorBoxStyle,
            backgroundColor: selectedElementFill,
            "&::after": !selectedElementHasFill ? shapeColorBoxStyleAfter : {},
          }}
        />
      </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        <IconButton
          sx={{
            borderRadius: "0.3rem",
            "&:hover": {
              backgroundColor: "transparent", // Override the hover background color
            },
          }}
          onClick={(e) => onIconClick("stroke")}
        >
          <div>
            {selectedElement?.type === "text" ||
              selectedElement?.type === "menu" ? (
              <SideBarHeading content={"Stroke"} />
            ) : (
              // <Typography variant="body1">Stroke</Typography>
              // <CiText size={"0.9em"} style={{ margin: "auto" }} />
              <BsTriangle size={"0.7em"} style={{ margin: "auto" }} />
            )}
          </div>
        </IconButton>
        <Box
          onClick={(e) => onIconClick("stroke")}
          width={50}
          height={30}
          sx={{
            ...shapeColorBoxStyle,
            backgroundColor: selectedElementStroke,
            "&::after": !selectedElementStroke ? shapeColorBoxStyleAfter : {},
          }}
        />
      </Box>

      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        flexDirection={"row"}
      >
        <IconButton
          sx={{
            borderRadius: "0.3rem",
            display: "flex",
            "&:hover": {
              backgroundColor: "transparent", // Override the hover background color
            },
          }}
          onClick={(e) => onIconClick("Iconfill")}
        >
          <div>
            {selectedElement?.type === "text" ||
              selectedElement?.type === "menu" ? (
              // <TfiText size={"0.9em"} style={{ margin: "auto" }} />
              <SideBarHeading content={"Icon Fill"} />
            ) : (
              <BsTriangleFill size={"0.7em"} style={{ margin: "auto" }} />
            )}
          </div>
        </IconButton>
        <Box
          onClick={(e) => onIconClick("Iconfill")}
          width={50}
          height={30}
          sx={{
            ...shapeColorBoxStyle,
            backgroundColor: selectedElementIconFill,
            "&::after": !selectedElementHasIconFill ? shapeColorBoxStyleAfter : {},
          }}
        />
      </Box>
    </Box>
  );
};

export default PropertiesPanelShapeColor;
