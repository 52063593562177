import { Rect } from "react-konva";

const StudioSelection = () => {
  const selectionProps = {
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0,
    visible: false,
    id: "studio-selection",
    fill: "rgba(0,0,255,0.1)",
  };

  return <Rect {...selectionProps}></Rect>;
};

export default StudioSelection;
