const SideBarHeading = ({ content, style, children }) => {
  const styles = {
    heading: {
      color: "#2c2b44",
      fontSize: "14px",
      fontWeight: "600",
    },
  };
  return <p style={{ ...styles.heading, ...style }}>{content}</p>;
};
export default SideBarHeading;
