import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Group, Rect, Text } from "react-konva";

import animations from "../../animations";

const StudioSetAsBackground = () => {
  const textRef = useRef();
  const backgroundRef = useRef();
  const backgrounGroupRef = useRef();
  const [backgroundVisible, setBackgroundVisible] = useState(false);
  const { dragElement } = useSelector((state) => state.tools);
  const studio = useSelector((state) => state.studio.present);
  const { studioLayout } = studio;
  const isValidType = ["video", "image"].includes(dragElement?.type);

  useEffect(() => {
    const elementAnimation = animations.find((item) => item.key === "slide");
    const elementAnimationTween = elementAnimation.animation({
      studio,
      duration: 0.3,
      node: backgrounGroupRef?.current,
    });

    if (dragElement !== null) {
      if (!isValidType) return;
      setBackgroundVisible(true);
      elementAnimationTween?.play();
    } else {
      setBackgroundVisible(false);
      elementAnimationTween.finish();
    }
  }, [dragElement]);

  return (
    <Group
      x={0}
      y={0}
      ref={backgrounGroupRef}
      id="studio-set-as-background"
      visible={backgroundVisible}
    >
      <Rect
        fill="#000"
        opacity={0.25}
        ref={backgroundRef}
        height={studioLayout.height}
        width={studioLayout?.width / 6.5}
        id="studio-set-as-background-rect"
      />
      <Text
        fill="#fff"
        ref={textRef}
        rotation={-90}
        text="Set as Background"
        offsetX={textRef?.current?.width() / 2 || 0}
        offsetY={textRef?.current?.height() / 2 || 0}
        fontSize={14 / studioLayout?.group?.scaleX}
        x={backgroundRef?.current?.width() / 2 || 0}
        y={
          (backgroundRef?.current?.height() - textRef?.current?.height()) / 2 ||
          0
        }
      />
    </Group>
  );
};

export default StudioSetAsBackground;
