import { Grid } from "@mui/material";
import { Typography } from "@mui/material";

import OnboardingCategories from "../components/Onboarding/OnboardingCategories";

import BackgroundImage from "../images/onboarding-bg.jpeg";

const Onboarding = () => {
  return (
    <main className="h-screen">
      <Grid container className="h-full">
        <Grid
          xs
          item
          container
          alignItems="center"
          justifyContent="center"
          style={{
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${BackgroundImage})`,
          }}
        >
          <Typography variant="h1" color="white" sx={{ maxWidth: 500 }}>
            Tell us a little bit about who you are?
          </Typography>
        </Grid>
        <Grid item xs container justifyContent="center" alignItems="center">
          <Grid item xs={5}>
            <OnboardingCategories />
          </Grid>
        </Grid>
      </Grid>
    </main>
  );
};

export default Onboarding;
