import { createSlice } from "@reduxjs/toolkit";

let initialTargetMenu = {
  itemName: "",
  itemPrice: "",
  description: "",
  icon: "",
  sizes: [
    {
      size: "",
      price: "",
    },
  ],
  addOns: [
    {
      addOn: "",
      price: "",
    },
  ],
  selected: false,
};
const initialState = {
  menuList: [],
  selectedMenuGroup: null,
  currentTargetMenu: initialTargetMenu,
  showEditMenu: false,
  showAllMenu: false,

};

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenuList(state, action) {
      state.menuList = action.payload;
    },
    setCurrentTargetMenu(state, action) {
      state.currentTargetMenu = action.payload;
    },
    setSelectedMenuGroup(state, action) {
      state.selectedMenuGroup = action.payload;
    },
    resetCurrentTargetMenu(state, action) {
      state.currentTargetMenu = initialTargetMenu;
    },
    setShowEditMenu(state, action) {
      state.showEditMenu = action.payload;
    },
    setShowAllMenu(state, action) {
      state.showAllMenu = action.payload;
    },
  },
});

// this is for dispatch
export const { setMenuList, setSelectedMenuGroup, setCurrentTargetMenu, resetCurrentTargetMenu, setShowEditMenu, setShowAllMenu } = menuSlice.actions;

// this is for configureStore
export default menuSlice.reducer;
