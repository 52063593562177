import { v4 as uuid } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  Grid,
  ImageList,
  ImageListItem,
  CircularProgress,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { BiSearch } from "react-icons/bi";

import request from "../../services/request";
import { setToolsDragElement } from "../../store/toolsSlice";
import { useCallback } from "react";

const EditToolsContentVideos = () => {
  const dispatch = useDispatch();
  const [videos, setVideos] = useState([]);
  const [prevPage, setPrevPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [wasLastList, setWasLastList] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searching, setSearching] = useState('');
  const { contentScrollEnd } = useSelector((state) => state.tools);

  const getVideoList = async (isSearch = false) => {
    setLoading(true);

    try {
      if (isSearch === true && searchString.length >= 0 || searchString.length >= 0 && searching === true) {
        console.log({
          searchString, isSearch,
          searching
        }, "searchString");
        if (isSearch === true) {
          setSearching(isSearch);
          setVideos([]);
        }

        const res = await request({
          baseURL: "https://api.pexels.com/videos/",
          method: "get",
          url: "/search",
          params: {
            per_page: 40,
            page: currentPage,
            query: searchString
          },
          headers: {
            // TODO: Replace this key with env variable
            Authorization:
              "dfDBXLJvtxIyYqV2vWdcBCwymJ1kpb9ptxwq6SNtXv2vzoOurU8yfEcb",
          },
        });
        const videoLiest = res.data?.videos;
        console.log(videoLiest, "Search Videos");
        if (!videoLiest.length) {
          setWasLastList(true);
          return;
        }
        setPrevPage(currentPage);
        if (isSearch === true) {
          setVideos([...videoLiest]);
        } else {
          setVideos([...videos, ...videoLiest]);
        }
      } else {
        const res = await request({
          baseURL: "https://api.pexels.com/videos/",
          method: "get",
          url: "/popular",
          params: {
            per_page: 20,
            page: currentPage,
          },
          headers: {
            // TODO: Replace this key with env variable
            Authorization:
              "dfDBXLJvtxIyYqV2vWdcBCwymJ1kpb9ptxwq6SNtXv2vzoOurU8yfEcb",
          },
        });
        const videoLiest = res.data?.videos;
        console.log(videoLiest, "Simple Videos");
        if (!videoLiest.length) {
          setWasLastList(true);
          return;
        }
        setPrevPage(currentPage);
        setVideos([...videos, ...videoLiest]);
      }
    } catch (error) {
      setVideos([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contentScrollEnd) {
      setCurrentPage(currentPage + 1);
    }
  }, [contentScrollEnd]);

  useEffect(() => {
    if (!wasLastList && prevPage !== currentPage) {
      getVideoList();
    }
  }, [currentPage, wasLastList, prevPage, videos, searching]);

  return (
    <>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (searchString.length >= 0) getVideoList(true);
        else {
          setVideos([]);

          setTimeout(() => {
            setSearching(false);
            getVideoList();
          }, 500);
        }
      }}>
        <div className="SearchItem">
          <input type="text" placeholder="Search Videos Here" onChange={(e) => {
            if (e.target.value.length <= 0) {
              if (searching) {
                setVideos([]);
              }
              setTimeout(() => {
                setSearching(false);
                getVideoList();
              }, 500);
            }
            setSearchString(e.target.value);
          }}></input>
          <button type="submit">
            <IconButton size="small" color="inherit">
              <BiSearch size={"1.5em"} />
            </IconButton>
          </button>
        </div>
      </form>
      <ImageList variant="standard" cols={2} gap={5}>
        {videos.length
          ? videos.map((video, index) => (
            <ImageListItem
              key={`${video.id}-${index}`}
              draggable
              onDragStart={(e) => {
                const videoSrc = video.video_files.find(
                  (item) => item.quality === "hd"
                );
                console.log("video:::", video);
                dispatch(
                  setToolsDragElement({
                    scaleX: 1,
                    scaleY: 1,
                    opacity: 1,
                    rotation: 0,
                    type: "video",
                    animation: null,
                    draggable: true,
                    src: videoSrc.link,
                    video_img: video.image,
                    width: video.width / 6,
                    height: video.height / 6,
                    name: `by ${video.user.name}`,
                    id: `video-${video.id}-${uuid()}`,
                  })
                );
              }}
              onDragEnd={(e) => {
                dispatch(setToolsDragElement(null));
              }}
            >
              <img src={video.image} loading="lazy" alt="" />
            </ImageListItem>
          ))
          : ""}
      </ImageList>
      {loading && (
        <Grid container justifyContent="center" py={2}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default EditToolsContentVideos;
