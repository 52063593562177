import {useState, useEffect, useCallback} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Grid, CircularProgress, ImageList, ImageListItem} from "@mui/material";

import request, {baseURL} from "../../services/request";
import {getToken} from "../../services/localStorage";
import {setToolsDragElement} from "../../store/toolsSlice";
import {setTargetEndpoint} from "../../store/requestSlice";
import {IconButton} from "@mui/material";
import {BiSearch} from "react-icons/bi";

const EditToolsContentTemplates = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [wasLastList, setWasLastList] = useState(false);
    const [prevPage, setPrevPage] = useState(0);
    const {targetEndpoint} = useSelector((state) => state.request);
    const {contentScrollEnd} = useSelector((state) => state.tools);
    const [templateName, setTemplateName] = useState("");

    const getTemplates = useCallback(async () => {
        setLoading(true);
        const token = getToken();
        let url = "/templates";

        if (templateName) {
            url = `/templates/search?name=${templateName}`;
        }
        try {
            const res = await request({
                method: "get",
                url,
                params: {
                    page: templateName ? 1 : currentPage,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const templateList = res?.data?.data.data;
            // const promises = templateList.map((item) => getJSONTemplates(item.id));
            // const allData = await Promise.all(promises);
            // const templatesData = allData.map((item) => item.data);
            // const template = templateList.map((item, index) => ({
            //     ...item,
            //     props: templatesData[index],
            // }));
            if (!templateList.length) {
                setWasLastList(true);
                return;
              }
              if(templateName){
                setTemplates(templateList)
                setPrevPage(0)
                return;
              }
              if(currentPage ===1){
                setTemplates(templateList);
                setPrevPage(currentPage)
                return
              }
            setTemplates([...templates, ...templateList]);
            setPrevPage(currentPage);
        } catch (error) {
            setTemplates([]);
        } finally {
            setLoading(false);
            // setTemplateName(""); // Commented out to preserve the search term in the input
        }
    }, [templateName, currentPage]);

    const onTemplateName = (e) => {
        setTemplateName(e.target?.value);
    };

    const getJSONTemplates = async (id) => {
        return request({
            url: `getJson/${id}`,
            headers: {
                "Content-Type": "application/json",
            },
        });
    };

    useEffect(() => {
        if (targetEndpoint === "get-templates") {
            getTemplates();
            dispatch(setTargetEndpoint(""));
        }
    }, [targetEndpoint, currentPage]);

    useEffect(() => {
        getTemplates();
    }, []);

    useEffect(() => {
        if (contentScrollEnd) {
            setCurrentPage(currentPage + 1);
        }
    }, [contentScrollEnd]);

    useEffect(() => {
        if (!wasLastList && prevPage !== currentPage) {
            getTemplates();
        }
    }, [currentPage, prevPage, wasLastList]);

    return  templates.length ? (
        <>
            <div >
                <form className="searchForm" onSubmit={(e)=>{
                    e.preventDefault(); getTemplates()}}>
                    <div className="SearchItem">
                        <input
                            type="text"
                            placeholder="Search Template Here"
                            onChange={onTemplateName}
                        ></input>
                        <button type="submit">
                            <IconButton
                                size="small"
                                color="inherit"
                                // onClick={getTemplates}
                            >
                                <BiSearch size={"1.5em"}/>
                            </IconButton>
                        </button>
                    </div>
                </form>
                {/* <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "10px" }}> */}
                <ImageList variant="standard" cols={2} gap={5}>
                    {templates.map((template, index) => {
                        const templateImage = `${baseURL}/${template.template_path}`;

                        return (
                            // <div key={index} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <ImageListItem style={{
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    textAlign: "center"
                                }}
                                
                                onDragStart={(e) => {
                                    dispatch(
                                        setToolsDragElement({
                                            ...template.props,
                                            id: template.id,
                                            type: "template",
                                        })
                                    );
                                }}
                                onDragEnd={(e) => {
                                    dispatch(setToolsDragElement(null));
                                }}
                            
                                >
                                    <img
                                        alt={""}
                                        src={templateImage}
                                        style={{height: "80px", marginBottom: "6px", objectFit: "cover"}}
                                        />
                                    <span style={{fontSize: '.8rem'}}>{template.name}</span>
                                </ImageListItem>
                            // </div>
                        );
                    })}
                    </ImageList>
                {/* </div> */}
            </div>
            {loading && (
                
                    <Grid container justifyContent="center" py={2}>
                        <CircularProgress/>
                    </Grid>
                
            )}
        </>
    ) : (
        <form className="searchForm" onSubmit={getTemplates}>
            <div className="SearchItem">
                <input
                    type="text"
                    placeholder="Search Template Here"
                    onChange={onTemplateName}
                ></input>
                <button type="submit">
                    <IconButton size="small" color="inherit" onClick={getTemplates}>
                        <BiSearch size={"1.5em"}/>
                    </IconButton>
                </button>
            </div>
        </form>
    );

};

export default EditToolsContentTemplates;
