import Konva from "konva";

import {
  setStudioWidth,
  setStudioHeight,
  updateStudioLayout,
  setStudioCurrentTarget,
  resetStudioTransformer,
  updateStudioTransformer,
  updateStageRef,
} from "../../store/studioSlice";
import { ELEMENT_TYPES } from "../../constants";

const initStudio = ({ dispatch }) => {
  let headerHeight = 0;
  let editToolsWidth = 0;
  let styleSettingsWidth = 0;

  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  headerHeight = document.querySelector("#header-element")?.offsetHeight;
  editToolsWidth = document.querySelector("#edit-tools-element")?.offsetWidth;
  styleSettingsWidth = document.querySelector(
    "#style-settings-element"
  )?.offsetWidth;

  dispatch(setStudioWidth(windowWidth - (editToolsWidth + styleSettingsWidth)));
  dispatch(setStudioHeight(windowHeight - headerHeight));
};

const updateStudioFrame = ({ studio, dispatch }) => {
  let studioWidth = 0;
  let studioHeight = 0;
  studioWidth = document.querySelector("#studio-element")?.offsetWidth;
  studioHeight = document.querySelector("#studio-element")?.offsetHeight;

  const defaultZoomScale = 85;
  const templateWidth = studio.studioLayout.width;
  const templateHeight = studio.studioLayout.height;
  const templateWidthRatio = studioWidth / templateWidth;
  const templateHeightRatio = studioHeight / templateHeight;
  const ratio =
    Math.min(templateWidthRatio, templateHeightRatio) *
    (defaultZoomScale / 100);
  const frameOnScreenWidth = templateWidth * ratio;
  const frameOnScreenHeight = templateHeight * ratio;

  // console.log(
  // {
  //   width: frameOnScreenWidth,
  //   height: frameOnScreenHeight,
  //   templateHeight,
  //   templateWidth,
  //   ratio,
  // },
  //   "ahmadali"
  // );
  dispatch(
    updateStudioLayout({
      width: frameOnScreenWidth,
      height: frameOnScreenHeight,
    })
  );
};

const onStageZoom = (e, { dispatch }) => {
 
  const scaleBy = 1.01;
  const stage = e.target.getStage();
  const mainElement = stage.findOne("#studio-group");

  if (!e.evt.ctrlKey) return;

  e.evt.preventDefault();

  let oldScale = mainElement.scaleX();
  let pointer = {
    x: stage.width() / 2,
    y: stage.height() / 2,
  };

  let mousePointTo = {
    x: (pointer.x - mainElement.x()) / oldScale,
    y: (pointer.y - mainElement.y()) / oldScale,
  };

  let direction = e.evt.deltaY > 0 ? 1 : -1;

  if (e.evt.ctrlKey) {
    direction = -direction;
  }

  let newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

  let newPos = {
    x: pointer.x - mousePointTo.x * newScale,
    y: pointer.y - mousePointTo.y * newScale,
  };

  mainElement.scale({ x: newScale, y: newScale });
  mainElement.position(newPos);

  dispatch(
    updateStudioLayout({
      zoomScale: newScale * 100,
    })
  );


};

const updateSelectionRect = ({ studio, dispatch }) => {
  const selectionNode = studio.stage.findOne("#studio-selection");

  const position = {
    x1: selectionNode.getAttr("x1"),
    x2: selectionNode.getAttr("x2"),
    y1: selectionNode.getAttr("y1"),
    y2: selectionNode.getAttr("y2"),
  };

  selectionNode.setAttrs({
    x: Math.min(position.x1, position.x2),
    y: Math.min(position.y1, position.y2),
    width: Math.abs(position.x1 - position.x2),
    height: Math.abs(position.y1 - position.y2),
  });

  selectionNode.getLayer().batchDraw();
};

const checkDeselectTransformer = (e, { studio, dispatch }) => {
  const clickedOutsideElement =
    e.target.id() === "studio-template" || e.target.attrs?.setAsBackground;

  if (clickedOutsideElement) {
    dispatch(resetStudioTransformer());
  }
};

const onStageMouseDown = (e, { studio, dispatch }) => {
  const { studioId, studioTransformer } = studio;
  const stage = e.target.getStage();
  const position = stage.getPointerPosition();
  const isStage = e.target.id() === studioId;
  const isElement = e.target.id() !== studioId;
  const isTransformer = studioTransformer?.nodes?.length;
  const selectionNode = studio.stage.findOne("#studio-selection");

  if ((isElement || isTransformer) && !e.target.attrs?.setAsBackground) return;

  selectionNode.visible(true);

  selectionNode.setAttrs({
    x1: position.x,
    y1: position.y,
    x2: position.x,
    y2: position.y,
  });

  updateSelectionRect({ studio, dispatch });
};

const onStageMouseMove = (e, { studio, dispatch }) => {
  const stage = e.target.getStage();
  const position = stage.getPointerPosition();
  const selectionNode = studio.stage.findOne("#studio-selection");
  stage.listening(true);

  if (!selectionNode.visible()) return;

  selectionNode.setAttrs({
    x2: position.x,
    y2: position.y,
  });

  updateSelectionRect({ studio, dispatch });
  //dispatch(updateStageRef({ stageRef: e }))
};

const onStageMouseUp = (e, { studio, dispatch }) => {
  const { studioId, studioElements } = studio;
  const target = e.target;
  const stage = target.getStage();
  const selectionNode = studio.stage.findOne("#studio-selection");
  const selectionBoxClient = selectionNode.getClientRect();

  if (!selectionNode.visible()) return;

  selectionNode.visible(false);

  const transformElements = [];

  studioElements.forEach((element) => {
    const elementNode = studio.stage.findOne(`#${element.id}`);
    if (!elementNode) return;
    if (element.id === studioId) return;

    if (elementNode.attrs.setAsBackground) {
      dispatch(setStudioCurrentTarget(target.id()));
      return;
    }
    const elementBoxClient = elementNode?.getClientRect();
    if (Konva.Util.haveIntersection(selectionBoxClient, elementBoxClient)) {
      transformElements.push(elementNode);
    }
  });

  dispatch(updateStudioTransformer({ nodes: transformElements }));
  //  dispatch(updateStageRef({ stageRef: e }))
  stage.listening(false);

  if (transformElements.length === 1) {
    dispatch(setStudioCurrentTarget(transformElements[0].id()));
  }

  updateSelectionRect({ studio, dispatch });
};

const onStageClick = (e, { studio, dispatch }) => {
  let target = e.target;
  // console.log({ target })
  const menuTarget = e.target?.findAncestor(".Menu");

  if (menuTarget) {
    target = menuTarget;
  }

  const targetId = target.id();
  const { studioId } = studio;
  const selectionNode = studio.stage.findOne("#studio-selection");
  const transformer = studio.stage.findOne("#studio-transformer");
  const isValidType = ELEMENT_TYPES.includes(getElementType(target));
  // console.log({ studioId, selectionNode, transformer, isValidType, menuTarget })
  if (!isValidType) return;

  dispatch(setStudioCurrentTarget(targetId));

  if (selectionNode.visible()) return;

  checkDeselectTransformer(e, { studio, dispatch });

  if (targetId === studioId) return;

  const isTransfomerIncludedElement =
    target.name() === "back" || target.attrs?.setAsBackground === true;

  const isPreventToCache = ["image", "video", "text", "menu"].includes(
    getElementType(target)
  );
  console.log(target, "testTarget");

  console.log(target, "parentTarget");
  if (!isTransfomerIncludedElement) {
    if (!isPreventToCache) {
      target.cache();
    }

    if (target.attrs.type === "menu") {
      if (!target.children[1].attrs.disableCondition) {
        dispatch(updateStudioTransformer({ nodes: [target] }));
        transformer.getLayer().batchDraw();
      }
    } else {
      dispatch(updateStudioTransformer({ nodes: [target] }));
      transformer.getLayer().batchDraw();
    }
    console.log(target, "Transformer target");
  }
};

const getElementType = (element) => {
  const id = element.id();
  const type = element.attrs.type;
  if (id === "studio-template") return "stage";
  return type;
};

export {
  initStudio,
  onStageZoom,
  onStageClick,
  onStageMouseUp,
  onStageMouseDown,
  onStageMouseMove,
  updateStudioFrame,
};
