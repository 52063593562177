import { Box, Tab, Tabs, ThemeProvider, createTheme } from "@mui/material";
import { useState } from "react";

import EditToolsContentResizeDisplays from "./EditToolsContentResizeDisplays";
import EditToolsContentResizeSocial from "./EditToolsContentResizeSocial";

const EditToolsContentResize = () => {
  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "#5DBBE4",
          },
        },
      },
    },
  });
  return (
    <>
      <Box>
        <ThemeProvider theme={theme}>
          <Tabs
            value={tab}
            variant="fullWidth"
            textColor="inherit"
            onChange={handleChange}
            // indicatorColor="red"
          >
            {/* <Tab
              label="Displays"
              sx={{
                fontSize: 16,
                textTransform: "none",
              }}
            /> */}
            <Tab
              label="Displays"
              sx={{
                fontSize: 16,
                textTransform: "none",
              }}
            />
          </Tabs>
        </ThemeProvider>
      </Box>
      <Box pt={2}>
        {/* {tab === 1 && <EditToolsContentResizeDisplays />} */}
        {tab === 0 && <EditToolsContentResizeSocial />}
      </Box>
    </>
  );
};

export default EditToolsContentResize;
