import { useAuthenticated } from "../../hooks";
import EditToolsContentMyContentFiles from "./EditToolsContentMyContentFiles";
import EditToolsContentMyContentDefault from "./EditToolsContentMyContentDefault";

const EditToolsContentMyContent = () => {
  const isAuthenticated = useAuthenticated();

  return (
    <>
      {!isAuthenticated && <EditToolsContentMyContentDefault />}
      {isAuthenticated && <EditToolsContentMyContentFiles />}
    </>
  );
};

export default EditToolsContentMyContent;
