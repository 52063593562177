let canvas = document.createElement("canvas");
let tempCanvas = document.createElement("canvas");

// make all pixels opaque 100% (except pixels that 100% transparent)
function removeTransparency(canvas) {
  let ctx = canvas.getContext("2d", { willReadFrequently: true });

  let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let nPixels = imageData.data.length;
  for (let i = 3; i < nPixels; i += 4) {
    if (imageData.data[i] > 0) {
      imageData.data[i] = 255;
    }
  }
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.putImageData(imageData, 0, 0);
  return canvas;
}

function Border(imageData) {
  let nPixels = imageData.data.length;

  let size = this.getAttr("borderWidth") || 0;

  // - first set correct dimensions for canvases
  canvas.width = imageData.width;
  canvas.height = imageData.height;

  tempCanvas.width = imageData.width;
  tempCanvas.height = imageData.height;

  // - the draw original shape into temp canvas
  tempCanvas
    .getContext("2d", { willReadFrequently: true })
    .putImageData(imageData, 0, 0);

  // - then we need to remove alpha chanel, because it will affect shadow (transparent shapes has smaller shadow)
  removeTransparency(tempCanvas);

  let ctx = canvas.getContext("2d", { willReadFrequently: true });
  let color = this.getAttr("borderColor") || "black";

  // 3. we will use shadow as border
  // so we just need apply shadow on the original image
  ctx.save();
  ctx.shadowColor = color;
  ctx.shadowBlur = size;
  ctx.drawImage(tempCanvas, 0, 0);
  ctx.restore();

  // - Then we will dive in into image data of [original image + shadow]
  // and remove transparency from shadow
  let tempImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  let SMOOTH_MIN_THRESHOLD = 3;
  let SMOOTH_MAX_THRESHOLD = 10;

  let val, hasValue;

  let offset = 3;

  for (let i = 3; i < nPixels; i += 4) {
    // skip opaque pixels
    if (imageData.data[i] === 255) {
      continue;
    }

    val = tempImageData.data[i];
    hasValue = val !== 0;
    if (!hasValue) {
      continue;
    }
    if (val > SMOOTH_MAX_THRESHOLD) {
      val = 255;
    } else if (val < SMOOTH_MIN_THRESHOLD) {
      val = 0;
    } else {
      val =
        ((val - SMOOTH_MIN_THRESHOLD) /
          (SMOOTH_MAX_THRESHOLD - SMOOTH_MIN_THRESHOLD)) *
        255;
    }
    tempImageData.data[i] = val;
  }

  // draw resulted image (original + shadow without opacity) into canvas
  ctx.putImageData(tempImageData, 0, 0);

  // then fill whole image with color (after that shadow is colored)
  ctx.save();
  ctx.globalCompositeOperation = "source-in";
  ctx.fillStyle = color;
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.restore();

  // then we need to copy colored shadow into original imageData
  let newImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  let indexesToProcess = [];
  for (let i = 3; i < nPixels; i += 4) {
    let hasTransparentOnTop =
      imageData.data[i - imageData.width * 4 * offset] === 0;
    let hasTransparentOnTopRight =
      imageData.data[i - (imageData.width * 4 + 4) * offset] === 0;
    let hasTransparentOnTopLeft =
      imageData.data[i - (imageData.width * 4 - 4) * offset] === 0;
    let hasTransparentOnRight = imageData.data[i + 4 * offset] === 0;
    let hasTransparentOnLeft = imageData.data[i - 4 * offset] === 0;
    let hasTransparentOnBottom =
      imageData.data[i + imageData.width * 4 * offset] === 0;
    let hasTransparentOnBottomRight =
      imageData.data[i + (imageData.width * 4 + 4) * offset] === 0;
    let hasTransparentOnBottomLeft =
      imageData.data[i + (imageData.width * 4 - 4) * offset] === 0;
    let hasTransparentAround =
      hasTransparentOnTop ||
      hasTransparentOnRight ||
      hasTransparentOnLeft ||
      hasTransparentOnBottom ||
      hasTransparentOnTopRight ||
      hasTransparentOnTopLeft ||
      hasTransparentOnBottomRight ||
      hasTransparentOnBottomLeft;

    // if pixel presented in original image - skip it
    // because we need to change only shadow area
    if (
      imageData.data[i] === 255 ||
      (imageData.data[i] && !hasTransparentAround)
    ) {
      continue;
    }
    if (!newImageData.data[i]) {
      // skip transparent pixels
      continue;
    }
    indexesToProcess.push(i);
  }

  for (let index = 0; index < indexesToProcess.length; index += 1) {
    let i = indexesToProcess[index];

    let alpha = imageData.data[i] / 255;

    if (alpha > 0 && alpha < 1) {
      let aa = 1 + 1;
    }
    imageData.data[i] = newImageData.data[i];
    imageData.data[i - 1] =
      newImageData.data[i - 1] * (1 - alpha) + imageData.data[i - 1] * alpha;
    imageData.data[i - 2] =
      newImageData.data[i - 2] * (1 - alpha) + imageData.data[i - 2] * alpha;
    imageData.data[i - 3] =
      newImageData.data[i - 3] * (1 - alpha) + imageData.data[i - 3] * alpha;

    if (newImageData.data[i] < 255 && alpha > 0) {
      let bb = 1 + 1;
    }
  }
}

export default Border;
