import {
  Box,
  Collapse,
  Typography,
  Grid,
  Button,
  IconButton,
} from "@mui/material";
import { useSelector } from "react-redux";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropertiesAnimationEffects from "./PropertiesAnimationEffects";
import PropertiesAnimationController from "./PropertiesAnimationController";
import SideBarHeading from "../Heading";
import { useState } from "react";

const PropertiesAnimation = () => {
  const [animationToggle, setAnimationToggle] = useState(false);
  const { studioId, studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  const isElementExist = studioCurrentTarget !== studioId;
  const isSelectedElementSetAsBackground = selectedElement?.setAsBackground;

  return (
    <Box
      style={{
        backgroundColor: "white",
        filter: "drop-shadow(0px 3px 15px rgba(0,0,0,0.2))",
        borderTopLeftRadius: 30,
      }}
      className="absolute bottom-0 right-0 left-0 w-full"
    >
      {studioCurrentTarget &&
        isElementExist &&
        !isSelectedElementSetAsBackground && (
          <Box pl={2}>
            <Grid
              justifyContent={"space-between"}
              container
              alignItems="center"
              spacing={1}
              mb={1}
              pl={1}
              pt={2}
            >
              <SideBarHeading
                style={{ fontWeight: "800", fontSize: "16px" }}
                content={"Animation"}
              />
              <IconButton
                onClick={() => {
                  setAnimationToggle(!animationToggle);
                }}
              >
                {animationToggle ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Grid>
          </Box>
        )}
      <Collapse in={animationToggle}>
        {studioCurrentTarget &&
          isElementExist &&
          !isSelectedElementSetAsBackground && <PropertiesAnimationEffects />}
        <PropertiesAnimationController />
      </Collapse>
    </Box>
  );
};

export default PropertiesAnimation;
