import { Tooltip } from "react-tooltip";
const CustomToolTip = ({ content, children }) => {
  const styles = {
    tooltip: {
      maxWidth: "200px",
    },
  };
  return (
    <div>
      <div
        className="item"
        data-tooltip-id="my-tooltip"
        data-tooltip-content={content}
      >
        {children}
      </div>
      <Tooltip style={styles.tooltip} id="my-tooltip" />
    </div>
  );
};
export default CustomToolTip;
