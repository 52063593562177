import { useSelector } from "react-redux";
import { getToken } from "../services/localStorage";

function useAuthenticated() {
  const token = getToken();
  return token ? true : false;
}

function useAdminRole() {
  const { user } = useSelector((state) => state.auth);
  const isAdmin = user?.roles?.find((role) => [1, 2].includes(role));
  return isAdmin ? true : false;
}

export { useAuthenticated, useAdminRole };
