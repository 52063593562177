import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Select, MenuItem } from "@mui/material";

import { updateStudioElement } from "../../store/studioSlice";
import CustomToolTip from "../CustomToolTip";
import SideBarHeading from "../Heading";

const ANIMATION_SLIDE_FROM_LIST = ["bottom", "left", "right", "top"];

const PropertiesAnimationEffectsFrom = () => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedElementEffect = selectedElement?.animation;
  const from = selectedElementEffect?.from || ANIMATION_SLIDE_FROM_LIST[1];

  const onAnimationSlideFromChange = (event) => {
    const { value } = event.target;
    const animation = {
      ...selectedElementEffect,
      from: value,
    };
    dispatch(updateStudioElement({ animation }));
  };

  return (
    <Grid container alignItems="center" mb={2}>
      <Grid item xs={3}>
        <CustomToolTip content={"Set the animation direction from"}>
          {/* <Typography variant="body2">Direction</Typography> */}
          <SideBarHeading content={'Direction'} />
        </CustomToolTip>
      </Grid>
      <Grid item xs>
        <Select
          fullWidth
          size="small"
          sx={{ height: 25, fontSize: 12 }}
          value={from}
          onChange={onAnimationSlideFromChange}
        >
          {ANIMATION_SLIDE_FROM_LIST.map((from, index) => (
            <MenuItem value={from} key={from}>
              {from}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );
};

export default PropertiesAnimationEffectsFrom;
