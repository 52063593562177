import { useSelector } from "react-redux";

import PropertiesPanel from "./PropertiesPanel";
import PropertiesDefault from "./PropertiesDefault";
import PropertiesStyle from "./Properties.module.scss";
import PropertiesAnimation from "./PropertiesAnimation";

const Properties = () => {
  const { studioCurrentTarget } = useSelector((state) => state.studio.present);

  return (
    <aside className={PropertiesStyle.sidebar} id="style-settings-element">
      {studioCurrentTarget ? (
        <>
          <PropertiesPanel />
        </>
      ) : (
        <PropertiesDefault />
      )}

      <PropertiesAnimation />
    </aside>
  );
};

export default Properties;
