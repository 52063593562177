import { IconButton } from "@mui/material";
import { MdFormatUnderlined } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";

import { updateStudioElement } from "../../../store/studioSlice";

const TextFontUnderline = ({ dialect }) => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  let textDecoration =
    dialect === "odd"
      ? selectedElement.rows[0].elements[0].props.textDecoration
      : dialect === "even"
      ? selectedElement.rows[1].elements[0].props.textDecoration
      : selectedElement.textDecoration;
  const hasUnderline = textDecoration?.includes("underline");

  const onClick = () => {
    if (hasUnderline) {
      textDecoration = textDecoration?.replace("underline", "");
    } else {
      textDecoration = textDecoration?.replace("", "underline ").trim();
    }

    dispatch(updateStudioElement({ textDecoration, dialect }));
  };

  return (
    <IconButton color={hasUnderline ? "primary" : "inherit"} onClick={onClick}>
      <MdFormatUnderlined color={hasUnderline ? "#5DBBE4" : ""} />
    </IconButton>
  );
};

export default TextFontUnderline;
