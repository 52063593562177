import EditToolsMenu from "./EditToolsMenu";
import EditToolsContent from "./EditToolsContent";

import EditToolsStyle from "./EditTools.module.scss";

const EditTools = () => (
  <aside className={EditToolsStyle.sidebar} id="edit-tools-element">
    <EditToolsMenu />
    <EditToolsContent />
  </aside>
);

export default EditTools;
