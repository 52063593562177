import DownloadSaveButton from "./DownloadSaveButton"
import { Dialog, Transition } from '@headlessui/react'
import { LoadingButton } from "@mui/lab"
import { Box, Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { Fragment, useState } from 'react'
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import request, { baseURL } from "../../services/request";
import { storeToken } from "../../services/localStorage";
import { useDispatch } from "react-redux";
import { setUser } from "../../store/authSlice";


const HeaderLoginButton = () => {
    const dispatch = useDispatch();
    let [isOpen, setIsOpen] = useState(false)
    const [form, setForm] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [messageBox, setMessageBox] = useState({
        show: false,
        message: "",
    });
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    function closeModal() {
        setIsOpen(false)
    }

    const onSubmit = async () => {
        setLoading(true);

        try {
            const res = await request({
                method: "post",
                url: "/login",
                data: form,
            });

            const token = res?.data?.access_token;
            const roles = res?.data?.roles;

            if (token) {
                storeToken(token);
                dispatch(setUser({ roles }));
                setIsOpen(false)
            }
        } catch (error) {
            setMessageBox({
                ...messageBox,
                message: error.message,
                show: true,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };
    return <>
        <Button
            variant="outlined"
            color="secondary"
            sx={{ borderRadius: 2 }}
            onClick={() => setIsOpen(!isOpen)}
            className="icon_btn"
        >
            <span className="mbl-hide">Save</span>
        </Button>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    <Typography mb={2} variant="h6" textAlign="center">
                                        Log in to your account
                                    </Typography>
                                </Dialog.Title>
                                <Box component="form">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        value={form.email}
                                        label="Email / Username"
                                        sx={{ marginBottom: 2.5 }}
                                        onChange={(e) => setForm({ ...form, email: e.target.value })}
                                    />

                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Password"
                                        variant="outlined"
                                        value={form.password}
                                        sx={{ marginBottom: 2.5 }}
                                        type={showPassword ? "text" : "password"}
                                        onChange={(e) => setForm({ ...form, password: e.target.value })}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <MdVisibilityOff size={"1em"} />
                                                        ) : (
                                                            <MdVisibility size={"1em"} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                    <LoadingButton
                                        loading={loading}
                                        onClick={onSubmit}
                                        fullWidth
                                        size="large"
                                        variant="contained"
                                        sx={{
                                            boxShadow: "0",
                                            marginBottom: 2,
                                            borderRadius: 1.5,
                                            textTransform: "none",
                                        }}
                                    >
                                        Log In
                                    </LoadingButton>

                                    <Typography
                                        variant="caption"
                                        textAlign="center"
                                        sx={{ display: "block" }}
                                    >
                                        New to Media Editor?{" "}
                                        <a
                                            href={`${baseURL}/register`}
                                            className="text-blue-500 cursor-pointer"
                                        >
                                            Sign up
                                        </a>
                                    </Typography>
                                </Box>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>
}
export default HeaderLoginButton