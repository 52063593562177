import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MdSave, MdClose, MdBookmark } from "react-icons/md";
import {
  Box,
  Grid,
  Menu,
  Alert,
  Button,
  Divider,
  Snackbar,
  Typography,
  IconButton,
} from "@mui/material";
import { GiHamburgerMenu } from "react-icons/gi";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import DownloadSaveButton from "./DownloadSaveButton";
import { RiSaveLine } from "react-icons/ri";

import { useAdminRole } from "../../hooks";
import request from "../../services/request";
import { useAuthenticated } from "../../hooks";
import { getToken } from "../../services/localStorage";
import { setTargetEndpoint } from "../../store/requestSlice";
import { resetStudioTransformer, setStudioPlay, addStudioElement,   removeStudioElement } from "../../store/studioSlice";
import { CanvasRecorder } from "../../utils/CanvasRecorder";
import { updateStudioLayout } from "../../store/studioSlice"; // Ensure this import is added
 

const HeaderSaveButton = () => {
  const dispatch = useDispatch();
  const isAdmin = useAdminRole();
  const isAuthenticated = useAuthenticated();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [messageBox, setMessageBox] = useState({
    show: false,
    message: "",
  });
  const {
    stage,
    studioLayout,
    studioElements,
    studioDuration,
    studioTemplateId,
  } = useSelector((state) => state.studio.present);


  const [isFfmpegLoaded, setFfmpegLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg({ log: true }));

  const recorderRef = useRef();

// humayoun code

const resetZoomBeforeSave = (callback) => {
  // Set the zoomScale to 100% before saving the template
  dispatch(updateStudioLayout({ ...studioLayout, zoomScale: 100 }));

  // Ensure the layout and zoom changes are applied before proceeding
  setTimeout(() => {
    callback(); // Execute the save logic after resetting the zoom
  }, 100); // Small delay to ensure the zoom changes are applied
};

// humayoun code



  const downloadUsingLink = useCallback((url) => {
    var link = document.createElement('a');
    // Set the href attribute of the anchor element
    link.href = url;
    // Set the target attribute to '_blank' to open in a new tab
    link.target = '_blank';
    // Set the text content of the anchor element (optional)
    link.textContent = 'Open Link';
    // link.setAttribute('download')
    link.click();
  }, [])

  const closeMessageBox = () => {
    setMessageBox({ ...messageBox, show: false });
  };

  const onMenuCloseClick = () => {
    setAnchorEl(null);
  };
  const mediaRecorderRef = useRef(null);
  const recordedChunksRef = useRef([]);


  const downloadAsVideo = useCallback(async function ({ fileName, fileType }) {
    addDummyElementToRecordFullDuration();
  
    const width = ensureEvenDimension(studioLayout.width); // Width of the output video
    const height = ensureEvenDimension(studioLayout.height); // Height of the output video
    const frameRate = 25; // Frame rate of the output video
    let duration = studioDuration * 1000; // Duration of the output video in seconds
    const outputFormat = fileType.format; // Output format ('mp4' or 'webm')
  
    const canvas = document.querySelector("canvas");
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
  
    return new Promise(async (resolve, reject) => {
      try {
        if (!recorderRef.current) {
          recorderRef.current = new CanvasRecorder(canvas);
        }
        recorderRef.current.start();
        dispatch(setStudioPlay(true));
        await interrupt(duration);
  
        dispatch(setStudioPlay(false));
        recorderRef.current.stop();
  
        dispatch(removeStudioElement({ id: dummyTextId }));
  
        const blob = new Blob(recorderRef.current.recordedBlobs, {
          type: getSupportedCodec(),
        });
  
        if (outputFormat === "webm") {
          resolve(blob);
        } else if (outputFormat === "mp4") {
          const mp4Blob = await transcode({
            webm: blob,
            fileName,
            frameRate,
            width,
            height,
          });
          resolve(mp4Blob);
        } else {
          alert("Please select WebM or MP4 format only");
          reject("Please select WebM or MP4 format only");
        }
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  }, [studioLayout, studioDuration, dispatch]); 


  const loadFFMEG = async () => {
    // const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd"; // single thread
    const baseURL = "https://unpkg.com/@ffmpeg/core-mt@0.12.6/dist/esm"; // multi thread
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on("log", ({ message }) => {
      console.log(message);
    });
    // toBlobURL is used to bypass CORS issue, urls with the same domain can be used directly.
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
      workerURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.worker.js`,
        "text/javascript"
      )
    });
    setFfmpegLoaded(true);
  };

  const transcode = async ({ webm, fileName, frameRate, width, height }) => {
    if (!isFfmpegLoaded) {
      await loadFFMEG();
    }


    const ffmpeg = ffmpegRef.current;
    await ffmpeg.writeFile(`${fileName}.webm`, await fetchFile(webm));
    await ffmpeg.exec([
      "-i",
      `${fileName}.webm`,
      "-vf",
      `scale=${width}:${height}`,
      "-preset",
      "medium",
      "-crf",
      "23",
      "-b:v",
      "1M",
      "-r",
      `${frameRate}`,
      "-c:v",
      "libx264",
      "-pix_fmt",
      "yuv420p",
      "-movflags",
      "faststart", // Ensures MP4 file is optimized for streaming
      "-threads",
      "4",
      `${fileName}.mp4`
    ]);

    const data = await ffmpeg.readFile(`${fileName}.mp4`);
    const videoBlob = new Blob([data.buffer], { type: "video/mp4" });

    return videoBlob
  };

  function ensureEvenDimension(widthOrHeight) {
    return Math.ceil(widthOrHeight) % 2 === 0
      ? Math.ceil(widthOrHeight)
      : Math.ceil(widthOrHeight) - 1;
  }

  const dummyTextId = "dummy-but-important-element-for-webm-to-mp4-video";
  function addDummyElementToRecordFullDuration() {
    /* This is for recording the canvas until the total given duration in second even the main animation finished*/
    dispatch(
      addStudioElement({
        id: dummyTextId,
        name: "test",
        fontSize: 0,
        scaleX: 0,
        scaleY: 0,
        opacity: 0,
        fill: "#000",
        rotation: 0,
        type: "text",
        stroke: "#969696",
        strokeWidth: 0,
        draggable: false,
        text: "Test",
        textDecoration: "",
        fontFamily: "Arial",
        fontStyle: "normal",
        x: 0,
        y: 0,
        animation: {
          start: 0,
          duration: {
            key: "long",
            number: studioDuration
          },
          key: "big-slide"
        }
      })
    );
  }

  function getSupportedCodec() {
    let supportedType = null;
    let types = [
      "video/webm; codecs=vp9", // Generally considered highest quality
      "video/webm; codecs=h264", // Widely supported with decent quality
      "video/webm; codecs=vp8",
      "video/webm", // Generic webm
      "video/webm; codecs=daala", // Less common
      "video/mpeg",
      "video/mp4" // Most widely supported
    ];

    for (let i in types) {
      if (MediaRecorder.isTypeSupported(types[i])) {
        supportedType = types[i];
        break;
      }
    }
    return supportedType;
  }
  const interrupt = (ms) => new Promise((resolve) => setTimeout(resolve, ms));


  const saveTemplateOnServer = useCallback(async ({ fileName, fieldType, try_count = 0 }) => {
    setLoading(true);
    const token = getToken();

    const categoryFromStorage = localStorage.getItem("category");
    const category = categoryFromStorage ? JSON.parse(categoryFromStorage) : null;

    const templateImageFile = await stage.findOne("#studio-group").toBlob({ pixelRatio: 0.5 });

    const newTemplate = {
        layout: studioLayout,
        elements: studioElements,
        duration: studioDuration,
    };
    console.log(newTemplate, "new templates header save button");

    const templateJSON = JSON.stringify(newTemplate);
    const templateJSONFile = new Blob([templateJSON], { type: "application/json" });
    let url = "";
    const data = new FormData();

    const handleVideoError = () => {
        if (try_count <= 2) {
            saveTemplateOnServer({ fileName, fieldType, try_count: try_count + 1 });
        } else {
            setMessageBox({
                ...messageBox,
                message: "Error processing video. Please try again.",
                show: true,
                type: "error",
            });
            setLoading(false);
        }
    };

    if (fieldType.type === "video") {
        try {
          

            const videoBlob = await downloadAsVideo({fileName: fileName, fileType: fieldType});

            const video = document.createElement('video');
            video.style.display = "none";
            const videoURL = URL.createObjectURL(videoBlob);
            video.src = videoURL;
            const videoPromise = new Promise((resolve, reject) => {
                video.onerror = reject;
                video.onloadeddata = resolve;
            });

            await videoPromise;

            data.append("format", `${fieldType.format}`);
            data.append("video", videoBlob, `${fileName}.${fieldType.format}`);
            data.append("height", parseInt(studioLayout.height));
            data.append("width", parseInt(studioLayout.width));
            data.append("duration", parseInt(studioDuration));
        } catch (error) {
            handleVideoError();
            return;
        }
    }

    if (isAdmin) {
        url = "/templates/store";
        data.append("name", fileName || `template-${uuid()}`);
        data.append("template", templateImageFile, `${fileName}.${fieldType.format}`);
        data.append("template_json", templateJSONFile, `${fileName}.json`);
        data.append("layout", studioLayout?.id || 1);
        data.append("category", category?.id || 1);
        data.append("premium", 0);
    } else {
        url = "/templates/store";
        data.append("template_id", studioTemplateId || uuid());
        data.append("name", fileName);
        data.append("template", templateImageFile, `${fileName}.${fieldType.format}`);
        data.append("file_size", templateImageFile.size);
        data.append("template_json", templateJSONFile, `${fileName}.json`);
    }

    try {
        const res = await request({
            url,
            method: "post",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
            data,
        });

        if (res.status === 200) {
            if (res.data?.code == 202 || res.data?.data && typeof res.data?.data === 'string') {
                window.open(res.data?.data, "_blank");
            }
        }
        if (isAdmin) {
            dispatch(setTargetEndpoint("get-templates"));
        } else {
            dispatch(setTargetEndpoint("get-files"));
        }

        setMessageBox({
            ...messageBox,
            message: "The template is saved successfully!",
            show: true,
            type: "success",
        });
    } catch (error) {
        if (error?.response?.status === 500 && try_count === 0) {
            setTimeout(() => {
                saveTemplateOnServer({ fileName, fieldType, try_count: 1 });
                setLoading2(true);
            }, 300);
        } else {
            setMessageBox({
                ...messageBox,
                message: error.message,
                show: true,
                type: "error",
            });
            setLoading(false);
        }
    } finally {
        setLoading(false);
        console.log("Save Template try_count id: ", try_count);
        setLoading2(false);
    }
}, [stage, studioLayout, studioElements, studioDuration, isAdmin, messageBox, downloadAsVideo, studioTemplateId, dispatch]);
  // const videoChunks = [];
  // const videoChunks = useRef(null);

  const onDownload = ({ fileName, fieldType }) => {
    dispatch(resetStudioTransformer([]));

    if (fileName.type === "video") {
      downloadAsVideo({ fileName: fileName, fileType: fieldType });
    } else {
      // downloadAsImage({ fileName: fieldName, fileType: fieldType });
    }
  };

  const onSave = ({ fileName, fieldType }) => {
    // First, reset the zoom before saving the template
    resetZoomBeforeSave(() => {
      // After zoom is reset, call saveTemplateOnServer
      saveTemplateOnServer({ fileName, fieldType });
    });
  };
  

  return (
    <>
      <DownloadSaveButton
        loading={loading}
        loading2={loading2}
        buttonTitle="Save"
        buttonIcon={<RiSaveLine size={22} />}
        headerTitle="Save Template"
        fieldNameLabel="Template name"
        fieldTypeLabel="Template Ext"
        actionTitle="Save"
        onAction={onSave}
      />
      <IconButton
        size="small"
        color="inherit"
        sx={{ borderRadius: 2 }}
        className="togglebtn"
      >
        <GiHamburgerMenu />
      </IconButton>

      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={onMenuCloseClick}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 2.5,
            width: "270px",
            borderRadius: "0.5rem",
            filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.16))",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box px={2} py={1}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid container item xs alignItems="center">
              <MdSave size={22} />
              <Typography fontWeight="bold" ml={1}>
                Save Content
              </Typography>
            </Grid>
            <Grid item>
              <MdClose size={20} cursor="pointer" onClick={onMenuCloseClick} />
            </Grid>
          </Grid>
        </Box>
        <Divider light />
        <Box p={2}>
          <Typography textAlign="center" fontSize={16}>
            If you'd like to save your designs and come back to them later
          </Typography>
        </Box>
        <Divider light />
        <Box px={2} pt={2} pb={1}>
          <Link to="/welcome">
            <Button
              fullWidth
              size="large"
              variant="contained"
              sx={{
                boxShadow: "0",
                borderRadius: 1.5,
                textTransform: "none",
              }}
            >
              Create a free account
            </Button>
          </Link>
        </Box>
      </Menu>

      <Snackbar
        open={messageBox.show}
        autoHideDuration={5000}
        onClose={closeMessageBox}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={closeMessageBox} severity={messageBox.type}>
          {messageBox.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default HeaderSaveButton;
