import { useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { Button, IconButton, Menu, MenuItem } from "@mui/material";

import { clearStudio } from "../../store/studioSlice";

const HeaderMoreActions = () => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const onClear = () => {
    dispatch(clearStudio());
    closeMenu();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="secondary"
        sx={{ borderRadius: 2 }}
        onClick={onClear}
        className="icon_btn"
      >
        <span className="mbl-hide">New</span>
      </Button>
      <Menu
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            width: "150px",
            borderRadius: "0.5rem",
            filter: "drop-shadow(0px 3px 30px rgba(0,0,0,0.16))",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem sx={{ py: 1 }} onClick={onClear}>
          Clear
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMoreActions;
