import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Transformer } from "react-konva";
import { Html } from "react-konva-utils";
import { FiEdit } from "react-icons/fi";
import { MdDone } from "react-icons/md";
import { resetStudioTransformer, updateStudioElement, studioLayout, setTextEdit } from "../../store/studioSlice";
import { setSelectedMenuGroup, setShowAllMenu } from "../../store/menuSlice";
import { useDispatch } from "react-redux";
import { MdOutlineFormatColorText } from "react-icons/md";
import { setToolsMenu } from "../../store/toolsSlice";
const StudioTransformer = () => {
  const [boundryWidth, setBoundryWidth] = useState({ width: 100, height: 100 });
  const [transformerWidth, setTransformerWidth] = useState({ width: 100, height: 100 });
  const [initialTransformerWidth, setInitialTransformerWidth] = useState({ width: 100, height: 100 });
  const transformerRef = useRef();
  const dispatch = useDispatch();
  const [Edit, setEdit] = useState(false);
  const [transformerAnchors, setTransformerAnchors] = useState([]);
  const {
    stage,
    studioLayout,
    showAllMenu,
    studioElements,
    studioTransformer,
    studioCurrentTarget,
    text_edit,
    any_target_change,
  } = useSelector((state) => state.studio.present);
  // const [studioTransformer1, setStudioTransformer1] = useState({
  //   // Other properties...
  //   width: 100, // Set your desired default width here
  //   height: 100, // Set your desired default height here
  // });

  const MIN_WIDTH = 150; // Minimum width constraint
  const MAX_WIDTH = 1000;

  const [value, setvalue] = useState(0)
  // console.log(value, "valuevaluevaluevaluevalue")
  const [trans, setTrans] = React.useState({
    height: "auto",
    width: "auto",
  });
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const ViewElement = studioElements.find(
    (item) => item.visible
  );
  const isGrouped = studioTransformer.nodes.length > 1;
  const isMenuElement = selectedElement?.type === "menu";
  const isSelectedElementSetAsBackground = selectedElement?.setAsBackground;

  const isOutOfZone =
    selectedElement?.x <= 0 ||
    selectedElement?.x >=
    (studioLayout.width - selectedElement?.width) *
    studioLayout.group.scaleX ||
    selectedElement?.y <= 0 ||
    selectedElement?.y >=
    (studioLayout.height - selectedElement?.height) *
    studioLayout.group.scaleY;

  const handleDoubleClick = (e) => {
    // console.log("selectedElement=>>inside ::::", selectedElement);
    if (selectedElement?.type === "menu") {
      dispatch(setShowAllMenu(true));
      dispatch(setSelectedMenuGroup(selectedElement));
      dispatch(resetStudioTransformer());
    }
    else if (selectedElement?.type === "text") {
      // console.log(selectedElement, "onEdit text")
      dispatch(setTextEdit(true));
    }
  };
  useEffect(() => {
    if (selectedElement === 'undefined' || selectedElement === 'null' || studioCurrentTarget === 'studio-template') {
      dispatch(setTextEdit(false));
    };

  }, [selectedElement, studioCurrentTarget])
  useEffect(() => {
    // console.log(any_target_change, "any_target_change")
    dispatch(setTextEdit(false));
  }, [any_target_change])
  const onEdit = () => {
    if (selectedElement?.type === "menu") {
      const item = {
        key: "text",
        selected: true,
        title: "Text",
        icon: MdOutlineFormatColorText,
      };
      dispatch(setToolsMenu({ ...item, selected: true }));
      dispatch(setShowAllMenu(true));
      dispatch(setSelectedMenuGroup(selectedElement));
      dispatch(resetStudioTransformer());
      setEdit(true);
    }
    else if (selectedElement?.type === "text") {
      // console.log(selectedElement, "onEdit text")
      dispatch(setTextEdit(true));
    }
  };
  useEffect(() => {
    const fetchInitialWidth = () => {
      const transformerNode = transformerRef.current;
      if (transformerNode) {
        const boundingBox = transformerNode.getClientRect();
        setInitialTransformerWidth({ width: boundingBox.width, height: boundingBox.height });
      }
    };
    setTimeout(fetchInitialWidth, 0);
  }, [selectedElement, studioLayout]);
  useEffect(() => {
    const transformerNode = transformerRef.current;
    if (transformerNode) {
      const boundingBox = transformerNode.getClientRect();
      setInitialTransformerWidth({ width: boundingBox.width, height: boundingBox.height });
    }
  }, [transformerRef, transformerWidth, studioLayout]);
  useEffect(() => {
    const transformerNode = transformerRef.current;
    transformerNode.shouldOverdrawWholeArea(selectedElement?.draggable);
    transformerNode.padding(10);
  }, [selectedElement]);

  useEffect(() => {
    if (selectedElement?.type === "text" || isMenuElement) {
      const arr = [];
      if (isMenuElement) {
        arr.push("middle-right", "middle-left");
      }
      setTransformerAnchors([
        "top-left",
        "top-right",
        "bottom-left",
        "bottom-right",
        ...arr,
      ]);
    } else {
      setTransformerAnchors([
        "top-left",
        "top-center",
        "top-right",
        "middle-right",
        "middle-left",
        "bottom-left",
        "bottom-center",
        "bottom-right",
      ]);
    }
  }, [selectedElement]);
  const handleStageClick = () => {
    const transformerNode = transformerRef.current;
    if (transformerNode) {
      const transformerRect = transformerNode.getClientRect();
      const { width, height } = transformerRect;
      // setTrans({
      //   width: width || "auto",
      //   height: height || "auto",
      // });
      // console.log("Transformer Width:", width);
      // console.log("Transformer Height:", height);
      // dispatch(updateStudioElement({ layout: { width } }));
    }
  };
  return (
    <Transformer
      {...studioTransformer}
      ref={transformerRef}
      enabledAnchors={transformerAnchors}
      shouldOverdrawWholeArea={
        isGrouped ? true : isOutOfZone ? true : isSelectedElementSetAsBackground
      }
      padding={50}
      // boundBoxFunc={(oldBox, newBox) => {
      //   // console.log({ oldBox, newBox });
      //   if (selectedElement?.type === "menu") {
      //     setBoundryWidth({ width: newBox.width, height: newBox.height });
      //   }
      //   handleStageClick();
      // }}
      boundBoxFunc={(oldBox, newBox) => {
        if(selectedElement?.type != 'image' || selectedElement?.type != 'video') {
          
        } else {
          if (Math.abs(newBox.width) < MIN_WIDTH) {
            newBox.width = Math.sign(newBox.width) * MIN_WIDTH;
          }
          if (Math.abs(newBox.width) > MAX_WIDTH) {
            newBox.width = Math.sign(newBox.width) * MAX_WIDTH;
          }
        }
        return newBox;
      }}

      onTransform={(e) => {
        const boundingBox = transformerRef.current.getClientRect();
        setTransformerWidth(boundingBox.width);

        setvalue(boundingBox.width / 10);
      }}
      onDblClick={handleDoubleClick}
      onTransformEnd={(e) => {
        handleStageClick();
        dispatch(updateStudioElement({ scalex: e.target.attrs.scaleX, scaley: e.target.attrs.scaleY, }));
      }}
    >
      {selectedElement?.type === "menu" && (
        <Html>

          {selectedElement && ViewElement && (

            <div
              onClick={onEdit}
              style={{
                // height: boundryWidth.height,
                // width: boundryWidth.width,
                position: "relative",
                // backgroundColor: "red",
                zIndex: -10,
              }}
            >
              {selectedElement && initialTransformerWidth.width !== 0 && (
                <button
                  className=""
                  style={{
                    padding: "5px 12px",
                    backgroundColor: "rgb(225 216 216 / 71%)",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    position: "absolute",
                    left: (initialTransformerWidth.width / 2) - 15,
                    bottom: (-initialTransformerWidth.height) + 33,
                    transform: "translateX(-50%)",
                  }}
                >
                  <FiEdit />
                  <span>Edit</span>
                </button>
              )}
            </div>
          )}
        </Html>
      )}
      {selectedElement?.type === "text-hota-tha-kbhi-yaha-pe-agr-dobara-zrurt-ho-to-phir-idr-ana-for-backup" && (
        <Html>

          {selectedElement && ViewElement && (

            <div
              onClick={text_edit ? () => dispatch(setTextEdit(false)) : onEdit}
              onDoubleClick={() => dispatch(setTextEdit(true))}
              style={{
                // height: boundryWidth.height,
                // width: boundryWidth.width,
                position: "relative",
                // backgroundColor: "red",
                zIndex: -10,
              }}
            >
              {selectedElement && initialTransformerWidth.width !== 0 && (
                <button
                  className=""
                  style={{
                    padding: "5px 12px",
                    backgroundColor: "rgb(225 216 216 / 71%)",
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    position: "absolute",
                    left: (initialTransformerWidth.width / 2) - 15,
                    bottom: (-initialTransformerWidth.height) + 33,
                    transform: "translateX(-50%)",
                  }}
                >
                  {text_edit ? <>
                    <MdDone />
                  </> : <>
                    <FiEdit />
                    <span>Edit</span>
                  </>}
                </button>
              )}
            </div>
          )}
        </Html>
      )}
    </Transformer>
  );
};

export default StudioTransformer;
