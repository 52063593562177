import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Slider } from "@mui/material";

import { updateStudioElement } from "../../store/studioSlice";
import CustomToolTip from "../CustomToolTip";
import SideBarHeading from "../Heading";

const PropertiesAnimationEffectsStart = () => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements, studioDuration } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  const selectedElementEffect = selectedElement?.animation;
  const start = selectedElementEffect?.start || 0;

  const onChangeSliderValue = (event, newvalue) => {
    const value = event?.target?.value || newvalue;

    if (value > studioDuration || value < 0) return;

    const animation = {
      ...selectedElementEffect,
      start: value,
    };

    dispatch(updateStudioElement({ animation }));
  };

  useEffect(() => {
    if (start > studioDuration) {
      onChangeSliderValue(undefined, studioDuration);
    }
  }, [studioDuration]);

  return (
    <Grid container alignItems="center" mb={1} spacing={2.5}>
      <Grid item xs={3}>
        <CustomToolTip
          content={"Animation will be applied after selected time"}
        >
          {/* <Typography variant="body2">Start</Typography> */}
          <SideBarHeading content={"Start"} />
        </CustomToolTip>
      </Grid>
      <Grid item xs mt={0.5}>
        <Slider
          step={0.1}
          value={start}
          style={{ color: "#5DBBE4" }}
          size="small"
          max={studioDuration || 1}
          onChange={onChangeSliderValue}
          className="properties-animation-slider"
        />
      </Grid>
      <Grid item>
        <input
          type="number"
          value={start}
          style={{
            width: 30,
            outline: 0,
            fontSize: 11,
            borderRadius: 4,
            textAlign: "center",
            fontWeight: "normal",
            border: "1px solid #ccc",
            backgroundColor: "transparent",
          }}
          max={studioDuration}
          className="input-number"
          onInput={onChangeSliderValue}
        />
      </Grid>
    </Grid>
  );
};

export default PropertiesAnimationEffectsStart;
