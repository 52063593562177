import UploadMediaImage from "../../images/UploadMedia.svg";
import PropertiesPanelImage from "../../images/PropertiesPanel.svg";

import PropertiesStyle from "./Properties.module.scss";

const PropertiesDefault = () => {
  return (
    <div className={PropertiesStyle.default}>
      <div className={PropertiesStyle.defaultItem}>
        <img
          src={PropertiesPanelImage}
          width={115}
          alt=""
          className={PropertiesStyle.defaultItemImage}
        />
        <span className={PropertiesStyle.defaultItemTitle}>
          Properties panel
        </span>
        <p className={PropertiesStyle.defaultItemHint}>
          Click on any object on the canvas to edit properties
        </p>
      </div>
      <div className={PropertiesStyle.defaultItem}>
        <img
          src={UploadMediaImage}
          width={170}
          alt=""
          className={PropertiesStyle.defaultItemImage}
        />

        <span className={PropertiesStyle.defaultItemTitle}>Upload media</span>
        <p className={PropertiesStyle.defaultItemHint}>
          Drag and drop anywhere on the screen to upload your media
        </p>
      </div>
    </div>
  );
};

export default PropertiesDefault;
