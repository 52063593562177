import { useEffect } from "react";
import { IconButton } from "@mui/material";
import { ActionCreators } from "redux-undo";
import { BiUndo, BiRedo } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";

const HeaderRedoUndo = () => {
  const dispatch = useDispatch();
  const studio = useSelector((state) => state.studio);
  const { stage } = studio.present;
  const layer = stage?.findOne("#studio-layer");

  const undo = () => {
    if (studio.index === 1) return;
    dispatch(ActionCreators.undo());
    layer?.draw();
  };

  const redo = () => {
    dispatch(ActionCreators.redo());
    layer?.draw();
  };

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      const key = event.key;
      const isCtrlKeyPressed = event?.ctrlKey;
      if (isCtrlKeyPressed && key === "z") undo();
      if (isCtrlKeyPressed && key === "y") redo();
    });
  }, []);

  return (
    <>
      <IconButton
        size="small"
        color="inherit"
        sx={{ borderRadius: 2 }}
        onClick={undo}
        className="iconbtn"
      >
        <BiUndo size={"1.5em"} />
      </IconButton>

      <IconButton
        size="small"
        color="inherit"
        sx={{ borderRadius: 2 }}
        onClick={redo}
        className="iconbtn"
      >
        <BiRedo size={"1.5em"} />
      </IconButton>
    </>
  );
};

export default HeaderRedoUndo;
