import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import store from "./store";
import reportWebVitals from "./reportWebVitals";

import "./styles/index.scss";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import TestPage from "./pages/TestPage";
import MultiPortions from "./tests/MultiPortions";
import MultiLayers from "./tests/MultiLayers";
import MultiLayers2 from "./tests/MultiLayers2";

// import "react-tooltip/dist/react-tooltip.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
    {/* <TestPage /> */}
    {/* <MultiPortions /> */}
    {/* <MultiLayers /> */}
    {/* <MultiLayers2 /> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
