import React, { useState } from "react";
import { v4 as uuid } from "uuid";
import { Html } from "react-konva-utils";
import { useSelector, useDispatch } from "react-redux";

const headingFont1 = "italic 44px arial";
const headingFont = "italic 44px A Love of Thunder";
const descriptionFont = "italic 14px arial";
const priceFont = "italic 18px arial";


export function getTextHeight(text, font) {
  // Create a temporary element to measure the text height
  const tempElement = document.createElement('div');
  tempElement.style.font = font;
  tempElement.style.position = 'absolute';
  tempElement.style.fontFamily = font;
  if (font === 'italic 14px arial') {
    tempElement.style.lineHeight = '14px';
  }
  tempElement.style.opacity = 0; // Make it invisible

  // Set the text and append the element to the document
  tempElement.textContent = text;
  document.body.appendChild(tempElement);

  // Measure the height
  const height = tempElement.clientHeight;

  // Remove the temporary element
  document.body.removeChild(tempElement);

  return height;
}

export function getMulitLineTextHeight({ text, font, width, x, y }) {
  // Create a temporary element to measure the text height     
  const tempElement = document.createElement('div');
  tempElement.style.font = font;
  tempElement.style.position = 'absolute';
  tempElement.style.fontFamily = font;
  tempElement.style.lineHeight = font;
  tempElement.style.width = `${width}px`;
  tempElement.style.opacity = 0; // Make it invisible
  const lineElement = document.createElement('div');
  lineElement.style.position = 'absolute';
  lineElement.style.fontFamily = font;
  lineElement.style.lineHeight = font;
  // Set the text and append the element to the document
  tempElement.textContent = text;
  document.body.appendChild(tempElement);
  // Calculate line count based on the scroll height and line height 
  const words = text.split(' ');
  const lines = [];
  let currentLine = '';
  for (const word of words) {
    const textWord = lineElement.textContent = currentLine + ' ' + word;
    const wordWidth = getTextWidth(textWord, `italic ${font}px arial`) - 150

    if (wordWidth <= width) {
      currentLine += (currentLine ? ' ' : '') + word;
    } else {
      const lineHeight = getTextHeight(currentLine, `italic ${font}px arial`)
      lines.push({
        text: currentLine,
        height: lineHeight,
        width: getTextWidth(currentLine, `italic ${font}px arial`) - 150,
        layout: width
      });
      currentLine = word;
    }

  }
  lines.push(currentLine);
  console.log(lines, 'multitextLines')
  const lastLine = lines[lines.length - 1];
  const height = getTextHeight(lines[0], `italic ${font}px arial`) * lines.length;
  const output = {
    y: height + y - getTextHeight(lines[0], `italic ${font}px arial`),
    height: lines.length > 1 ? height - 2 : height - 2,
    width: width,
    lines: lines.length,
    font: font,
    x: getTextWidth(lastLine, `italic ${font}px arial`) - 150
  }
  return output;
}



// export function getTextWidth(text, font) {

//   // Create a temporary element to measure the text height
//   const tempElement = document.createElement('div');
//   tempElement.style.font = font;
//   tempElement.style.position = 'absolute';
//   tempElement.style.fontFamily = font;
//   tempElement.style.opacity = 0;

//   // Set the text and append the element to the document
//   tempElement.textContent = text;
//   document.body.appendChild(tempElement);

//   const width = tempElement.clientWidth
//   // Measure the height

//   // Remove the temporary element
//   document.body.removeChild(tempElement);


//   return width
// }
// export function getTextWidth(text, font, item, layoutId) {
//   debugger
//   // Create a temporary element to measure the text height
//   const tempElement = document.createElement('div');
//   tempElement.style.font = font;
//   tempElement.style.position = 'absolute';
//   tempElement.style.fontFamily = font;
//   tempElement.style.opacity = 0;

//   // Set the text and append the element to the document
//   tempElement.textContent = text;
//   document.body.appendChild(tempElement);

//   let width = tempElement.clientWidth;

//   // Check the layoutId and adjust the width accordingly
//   if (layoutId === 4 && item?.itemName === text) {
//     console.log('Layout ID is 4. Adjusting width by 150.');
//     width = width + 550;
//     document.body.removeChild(tempElement);
//     return width;
//   } else {
//     // Remove the temporary element
//     document.body.removeChild(tempElement);
//     return width;

//   }

// }

// export function getTextWidth(text, font, layoutId) {
//   debugger
//   // Create a temporary element to measure the text width
//   const tempElement = document.createElement('div');
//   tempElement.style.font = font;
//   tempElement.style.position = 'absolute';
//   tempElement.style.fontFamily = font;
//   tempElement.style.opacity = 0;

//   // Set the text and append the element to the document
//   tempElement.textContent = text;
//   document.body.appendChild(tempElement);

//   let width = tempElement.clientWidth;

//   // // Check the layoutId and adjust the width accordingly
//   // if (layoutId === 4) {
//   //   console.log('Layout ID is 4. Adjusting width by 150.');
//   //   width = width + 150; // Adjust the width as needed
//   // }

//   // Remove the temporary element
//   document.body.removeChild(tempElement);

//   return width;
// }

export function getTextWidth(text, font, layoutId, item) {
  // Create a temporary element to measure the text width
  if (layoutId == 4) {
    const tempElement = document.createElement('div');
    tempElement.style.font = font;
    tempElement.style.position = 'absolute';
    tempElement.style.fontFamily = font;
    tempElement.style.opacity = 0;

    // Set the text and append the element to the document
    tempElement.textContent = text;
    document.body.appendChild(tempElement);
    const width1 = tempElement.clientWidth;

    let width = width1 + 150;

    // Check the layoutId and adjust the width accordingly
    // if (layoutId === 4) {
    //   // Calculate an additional width based on the length of the text
    //   const additionalWidth = text.length * 30; // Adjust the factor (10) as needed
    //   width += additionalWidth;
    // }

    // Remove the temporary element
    document.body.removeChild(tempElement);

    return width;
  }
  else {

    const tempElement = document.createElement('div');
    tempElement.style.font = font;
    tempElement.style.position = 'absolute';
    tempElement.style.fontFamily = font;
    tempElement.style.opacity = 0;

    // Set the text and append the element to the document
    tempElement.textContent = text
    document.body.appendChild(tempElement);

    let width = tempElement.clientWidth + 150;

    // Remove the temporary element
    document.body.removeChild(tempElement);

    return width;
  }
}


function displayTextWidth(text, font) {
  let canvas =
    displayTextWidth.canvas ||
    (displayTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  return metrics.width;
}

function getMaxWidth(items) {
  const array1 = [
    displayTextWidth(items["itemName"], "italic 44px arial"),
    displayTextWidth(items["description"], "italic 16px arial"),
    displayTextWidth(items["itemPrice"], "italic 20px arial"),
  ];
  const maxWidth = Math.max(...array1) > 450 ? 450 : Math.max(...array1)
  return maxWidth;
}


const maxWidth = []
function getMaxWidth2(val) {
  // const list = val.map((items) => {
  //   maxWidth.push(getMaxWidth(items));
  // })
  // console.log(maxWidth,'getMaxWidth2') 
  return Math.max(...maxWidth);
}

const maxPriceWidth = []


function getTextOffSet(text, font) {
  let canvas =
    displayTextWidth.canvas ||
    (displayTextWidth.canvas = document.createElement("canvas"));
  let context = canvas.getContext("2d");
  context.font = font;
  let metrics = context.measureText(text);
  console.log(metrics.fontBoundingBoxAscent, "metrics", text, font);
  return metrics.fontBoundingBoxAscent + 10;
}
// console.log(displayTextWidth('This is demo text!','italic 19px arial'),'test')

function formatSizeArrayAsString(arr) {
  const formattedStrings = arr.map(item => `${item.size} ${item.price}`);
  return formattedStrings.join(', ');
}
function formatAddOnsArrayAsString(arr) {
  const formattedStrings = arr.map(item => `${item.addOn} ${item.price}`);
  return formattedStrings.join(', ');
}


const getMenuRow = (rowElements = []) => {
  return rowElements?.map((ele) => {

    return {
      xs: 10,
      colorRange: 600,
      props: {
        x: 0,
        y: 0,
        opacity: 1,
        fontSize: 15,
        height: 15,
        color: { fill: "#000" },
        fill: "#333",
        text: `${ele?.value}`,
        type: ele?.type || "text",
        fontFamily: "Arial",
        // fontFamily: ele.style.menuID.style.fontFamily,
        fontStyle: "normal",
        scaleX: 1,
        scaleY: 1,
        opacity: 1,
        rotation: 0,
        stroke: null,
        strokeWidth: 0,
        animation: null,
        draggable: true,
        textDecoration: "",
        // fontFamily: "Arial",
        // fontStyle: "normal",
        id: `id-${uuid()}`,

        // align: "center",
        // verticalAlign: "middle",
        ...ele?.style,
      },
    };
  });
};
const getMenuRowsWithLayout = (selectedItems, layoutId, studioElements) => {
  const rowItems = [];
  const rows = selectedItems
    ?.map((item, index) => {

      // const sizePriceStrRowUoDown = item?.sizes
      //   ?.map((ele) => {
      //     return `${ele?.size}\n${ele?.price}`;
      //   })
      //   .join(" ");

      // const sizePriceStrRow = item?.sizes
      //   ?.map((ele) => {
      //     return `${ele?.size} ${ele?.price}`;
      //   })
      //   .join(" ");

      // const sizesStrRow = item?.sizes
      //   ?.map((ele) => {
      //     return `${ele?.size}`;
      //   })
      //   .join(" ");

      // const sPriceStrRow = item?.sizes
      //   ?.map((ele) => {
      //     return `${ele?.price}`;
      //   })
      //   .join(" ");

      // const sizesArray = item?.sizes.map((size) => {
      //   return {
      //     elements: getMenuRow([
      //       {
      //         value: size.size,
      //       },
      //     ]),
      //   };
      // });
      // const pricesArray = item?.sizes.map((size) => {
      //   return {
      //     elements: getMenuRow([
      //       {
      //         value: size.price,
      //       },
      //     ]),
      //   };
      // });

      // console.log("lksdjlkasdjflkajsdlkjlskd", ...pricesArray);

      //addons series
      const addOnPriceStrRow = item?.addOns
        ?.map((ele) => {
          return `${ele?.addOn} ${ele?.price}`;
        })
        .join(" ");

      if (layoutId === 1) {
        const titleWidth = displayTextWidth(item?.itemName, headingFont);
        const descriptionWidth = displayTextWidth(item?.description, descriptionFont);

        const sizeWidth = getTextWidth(`${item?.itemPrice + (item?.sizes[0] ?? 0) + (item?.sizes[1] ?? 0) + (item?.sizes[2] ?? 0) + (item?.sizes[3] ?? 0) + (item?.sizes[4] ?? 0) + (item?.sizes[5] ?? 0) + (item?.sizes[6] ?? 0) + (item?.sizes[7] ?? 0) + (item?.sizes[8] ?? 0) + (item?.sizes[9] ?? 0) + (item?.sizes[10] ?? 0)}`, priceFont) - 150;

        const desTextMatrics = getMulitLineTextHeight({
          text: item?.description,
          font: descriptionFont,
          width: getMaxWidth(item),
          x: 0,
          y: getTextOffSet(item?.itemName, headingFont)
        });
        console.log(item, 'itemsfsdfdsfd')

        const simpleRows = [
          {
            elements: getMenuRow([
              {
                value: `${item?.itemName}`,
                style: {
                  dataType: "title",
                  fontSize: 40,
                  width: titleWidth,
                  fontStyle: "bold",
                  align: "center",
                  x: 0,
                  menuID: item,
                  y: 0 * item?.id
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.description}`,
                style: {
                  dataType: "description",
                  width: descriptionWidth,
                  fontSize: 14,
                  align: "center",
                  height: desTextMatrics.height,
                  x: 0,
                  menuID: item,
                  y: getTextOffSet(item?.itemName, headingFont) - 10,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: item?.sizes[0].price ? formatSizeArrayAsString(item?.sizes) : item?.itemPrice,
                style: {
                  dataType: "price",
                  fontSize: 22,
                  width: sizeWidth,
                  fontStyle: "bold",
                  align: "center",
                  x: 0,
                  menuID: item,
                  y: desTextMatrics.y + 10,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: item?.addOns[0]?.price && formatAddOnsArrayAsString(item?.addOns),
                style: {
                  dataType: "addOns",
                  fontSize: 14,
                  width: getMaxWidth(item),
                  fontStyle: "normal",
                  align: "center",
                  x: 0,
                  menuID: item,
                  y: desTextMatrics.y + 10,
                },
              },
            ]),


          },
          {

            elements: getMenuRow([
              {
                type: "icon",
                id: "icon",
                src: item?.icon,
                value: `${item?.icon}`,
                style: {
                  dataType: "icon",
                  fontSize: 24,
                  width: getMaxWidth(item),
                  height: 30,
                  width: 10,
                  align: "center",
                  menuID: item,
                  x: getMaxWidth(item) / 2 - 5,
                  // y: desTextMatrics.y + getTextHeight(item?.itemPrice, priceFont) + 10,
                  y: desTextMatrics.y,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: "",
                style: {
                  dataType: "price",
                },
              },
            ]),
          },

        ];
        // return [...simpleRows, sizeRow, addOnRow];// layout 01
        return [...simpleRows];
      }

      //layout 02
      if (layoutId === 12) {

        const layoutWidth = displayTextWidth(item?.itemName, headingFont) * 1.5;
        const titleWidth = displayTextWidth(item?.itemName, headingFont);
        const descriptionWidth = displayTextWidth(item?.description, descriptionFont);
        const priceWidth = displayTextWidth(item?.itemPrice, priceFont);
        const linedWidth = Math.max(...maxWidth);
        const desdWidth = getMaxWidth2(item) - 70;
        const offSetHY = getTextOffSet(item?.itemName, headingFont);
        const offSetTY = getTextOffSet(item?.itemPrice, descriptionFont);
        const offSetPY = getTextOffSet(item?.description, priceFont);
        //const offSetY = offSetHY  item.id + offSetTY  item.id - offSetHY * item.id;
        const offSetY = offSetHY * item.id + offSetTY * item.id - offSetHY * item.id;
        maxWidth.push(layoutWidth);
        maxPriceWidth.push(priceWidth);

        const offSetLine = getMulitLineTextHeight({
          text: item?.description,
          font: descriptionFont,
          width: layoutWidth - 20,
          x: 0,
          y: offSetY
        });

        //console.log(maxPriceWidth, 'checkIndexaaaaa', index);

        const dline = rowItems?.[index - 1]?.[2]?.elements[0]?.props.y;
        const simpleRows = [
          {
            elements: getMenuRow([
              {
                value: `${item?.itemName}`,
                style: {
                  dataType: "title",
                  fontSize: 40,
                  width: titleWidth,
                  fontStyle: "bold",
                  align: "left",
                  x: 0,
                  menuID: item,
                  y: 0
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.description}`,
                style: {
                  dataType: "description",
                  width: desdWidth,
                  // height: 100,
                  fontSize: 14,
                  align: "left",
                  x: 0,
                  menuID: item,
                  y: getTextOffSet(item?.itemName, headingFont) * item?.id - 50,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.itemPrice}`,
                style: {
                  dataType: "price",
                  fontSize: 22,
                  width: linedWidth + 50,
                  fontStyle: "bold",
                  align: "right",
                  x: 0,
                  menuID: item,
                  y: offSetLine.y * item?.id - 14,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                type: "icon",
                src: item?.icon,
                value: `${item?.icon}`,
                style: {
                  x: titleWidth,
                  scaleX: 1.5,
                  scaleY: 1.5,
                  menuID: item,
                  y: 0 * item?.id,
                  align: "left",
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                type: "icon",
                style: {
                  scaleX: 0,
                  scaleY: 0,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `line`,
                style: {
                  fontSize: 0,
                  // dataType: "line",
                  height: 1,
                  Width: 1,
                  menuID: item,
                  //  scaleX: offSetLine.x,
                  scaleY: dline,
                  // right: linedWidth,
                  left: offSetLine.lines === 1 ? linedWidth - offSetLine.x - 5 : linedWidth - offSetLine.x - priceWidth,
                },
              },
            ]),
          },
        ];
        // console.log(simpleRows, 'checkIndex Row')
        rowItems.push(simpleRows)

        // return [...simpleRows, sizeRow, addOnRow];// layout 01
        return [...simpleRows];
      }

      // if (layoutId === 13) {
      //   const layoutWidth = displayTextWidth(item?.itemName, headingFont) * 1.5;
      //   const titleWidth = displayTextWidth(item?.itemName, headingFont);
      //   const sizeWidth = getTextWidth(`${item?.itemPrice + (item?.sizes[0] ?? 0) + (item?.sizes[1] ?? 0) + (item?.sizes[2] ?? 0) + (item?.sizes[3] ?? 0) + (item?.sizes[4] ?? 0) + (item?.sizes[5] ?? 0) + (item?.sizes[6] ?? 0) + (item?.sizes[7] ?? 0) + (item?.sizes[8] ?? 0) + (item?.sizes[9] ?? 0) + (item?.sizes[10] ?? 0)}`, priceFont);
      //   const linedWidth = getMaxWidth2(item) - titleWidth + 150;
      //   const offSetY = getTextOffSet(item?.itemPrice, headingFont);
      //   console.log(getMaxWidth2(item), 'getMaxWidth2')
      //   maxWidth.push(layoutWidth)
      //   const simpleRows = [
      //     {
      //       elements: getMenuRow([
      //         {
      //           value: `${item?.itemName}`,
      //           style: {
      //             fontSize: 40,
      //             fontStyle: "bold",
      //             dataType: "title",
      //             menuID: item,
      //             width: layoutWidth,
      //             align: "left",
      //             x: 0,
      //             y: offSetY * item.id - offSetY,
      //           },
      //         },
      //         // { value: item?.itemPrice, style: null }
      //       ]),
      //     },
      //     {
      //       elements: getMenuRow([
      //         {
      //           value: item?.sizes[0].price ? formatSizeArrayAsString(item?.sizes) : item?.itemPrice,
      //           style: {
      //             fontSize: 22,
      //             fontStyle: "bold",
      //             align: "left",
      //             dataType: "price",
      //             menuID: item,
      //             width: sizeWidth - 100,
      //             x: titleWidth + linedWidth,
      //             y: 10 + offSetY * (index + 1) - offSetY,
      //           },
      //         },
      //       ]),
      //     },
      //     {
      //       elements: getMenuRow([
      //         {
      //           type: "icon",
      //           src: item?.icon,
      //           value: `${item?.icon}`,
      //           style: {
      //             x: titleWidth,
      //             scaleX: 1.5,
      //             scaleY: 1.5,
      //             menuID: item,
      //             y: 5 + offSetY * (index + 1) - offSetY,
      //             align: "left",
      //           },
      //         },
      //       ]),
      //     },
      //     {
      //       elements: getMenuRow([
      //         {
      //           value: `line`,
      //           style: {
      //             fontSize: 0,
      //             dataType: "line",
      //             menuID: item,
      //             scaleX: titleWidth,
      //             // scaleY: 29 + offSetY * item.id - offSetY,
      //             scaleY: 30 + offSetY * (index + 1) - offSetY,
      //             left: linedWidth,
      //             height: 0,
      //             Width: 0

      //           },
      //         },
      //       ]),
      //     },
      //   ];

      //   return [...simpleRows]; // layout 03
      // }
      if (layoutId === 13) {
        const layoutWidth = displayTextWidth(item?.itemName, headingFont) * 1.5;
        const titleWidth = displayTextWidth(item?.itemName, headingFont);
        const sizeWidth = getTextWidth(`${item?.itemPrice + (item?.sizes[0] ?? 0) + (item?.sizes[1] ?? 0) + (item?.sizes[2] ?? 0) + (item?.sizes[3] ?? 0) + (item?.sizes[4] ?? 0) + (item?.sizes[5] ?? 0) + (item?.sizes[6] ?? 0) + (item?.sizes[7] ?? 0) + (item?.sizes[8] ?? 0) + (item?.sizes[9] ?? 0) + (item?.sizes[10] ?? 0)}`, priceFont);
        const linedWidth = getMaxWidth2(item) - titleWidth;
        const sizeWidthx = titleWidth + linedWidth;


        const offSetY = getTextOffSet(item?.itemPrice, headingFont);
        console.log(getMaxWidth2(item), 'getMaxWidth2')
        maxWidth.push(layoutWidth)
        const simpleRows = [
          {
            elements: getMenuRow([
              {
                value: `${item?.itemName}`,
                style: {
                  fontSize: 40,
                  fontStyle: "bold",
                  dataType: "title",
                  menuID: item,
                  width: titleWidth,
                  align: "left",
                  x: 0,
                  y: offSetY * item.id - offSetY,
                },
              },
              // { value: item?.itemPrice, style: null }
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: item?.sizes[0].price ? formatSizeArrayAsString(item?.sizes) : item?.itemPrice,
                style: {
                  fontSize: 22,
                  fontStyle: "bold",
                  align: "left",
                  dataType: "price",
                  menuID: item,
                  Width: sizeWidth - 100,
                  x: sizeWidthx,
                  y: 10 + offSetY * (index + 1) - offSetY,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                type: "icon",
                src: item?.icon,
                value: `${item?.icon}`,
                style: {
                  x: titleWidth,
                  scaleX: 1.5,
                  scaleY: 1.5,
                  menuID: item,
                  y: 5 + offSetY * (index + 1) - offSetY,
                  align: "left",
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `line`,
                style: {
                  fontSize: 0,
                  dataType: "line",
                  menuID: item,
                  scaleX: titleWidth,
                  // scaleY: 29 + offSetY * item.id - offSetY,
                  scaleY: 30 + offSetY * (index + 1) - offSetY,
                  left: linedWidth,
                  height: 0,
                  Width: 0

                },
              },
            ]),
          },
        ];

        return [...simpleRows]; // layout 03
      }
      //layout 04
      if (layoutId === 4) {

        const titleWidth = getTextWidth(item?.itemName, headingFont1) - 150;
        const descriptionWidth = displayTextWidth(item?.description, descriptionFont);
        const layoutWidth = titleWidth + getTextWidth(`${item?.itemPrice}`, priceFont) + getTextWidth(`${item?.itemPrice}`, priceFont);
        // const iconWidth = getIconWidth(item?.itemName, headingFont, 4)


        const offSetY = 0;
        const offSetLine = getMulitLineTextHeight({
          text: item?.description,
          font: descriptionFont,
          width: descriptionWidth,
          x: 0,
          y: offSetY
        });
        const offSetLineTitle = getMulitLineTextHeight({
          text: item?.itemName,
          font: headingFont1,
          width: layoutWidth,
          x: 0,
          y: offSetY
        });

        const priceWidth = getTextWidth(`${item?.itemPrice}`, priceFont);
        const titleHight = getTextHeight(item?.itemName, headingFont) * item?.id - getTextHeight(item?.itemName, headingFont);
        const lineH = offSetLineTitle.height + offSetLine.height;

        console.log(offSetLine, 'titleHight---');

        const simpleRows = [
          {
            elements: getMenuRow([
              {
                value: `${item?.itemName}`,
                style: {
                  dataType: "title",
                  fontSize: 40,
                  width: titleWidth,
                  fontStyle: "bold",
                  align: "left",
                  x: 10,
                  menuID: item,
                  y: item?.id + 60
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.description}`,
                style: {
                  dataType: "description",
                  width: descriptionWidth,
                  fontSize: 14,
                  align: "left",
                  x: 0 + 10,
                  menuID: item,
                  y: getTextHeight(item?.itemName, headingFont1) * item?.id,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.itemPrice}`,
                style: {
                  dataType: "price",
                  fontSize: 22,
                  fontStyle: "bold",
                  align: "center",
                  width: priceWidth,
                  x: layoutWidth - priceWidth,
                  menuID: item,
                  y: layoutWidth + 10,
                },
              },
            ]),
          },
          // {
          //   elements: getMenuRow([
          //     {
          //       type: "icon",
          //       src: item?.icon,
          //       value: `${item?.icon}`,
          //       style: {
          //         x: layoutWidth - titleWidth,
          //         // scaleX: 3,
          //         // scaleY: 3,
          //         menuID: item,
          //         y: item?.id + 10,
          //         align: "right",
          //       },
          //     },
          //   ]),
          // },
          {
            elements: getMenuRow([
              {
                type: "icon",
                src: item?.icon,
                value: `${item?.icon}`,
                style: {
                  x: titleWidth,
                  scaleX: 1.5,
                  scaleY: 1.5,
                  menuID: item,
                  y: 0 * item?.id,
                  align: "left",
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `react`,
                style: {
                  width: layoutWidth,
                  height: offSetLine.lines === 1 ? lineH + 30 : offSetLine.lines === 2 ? lineH + 25 : lineH + 15,
                  fontSize: 0,
                  left: layoutWidth - priceWidth
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `react-line`,
                style: {
                  width: 1,
                  height: offSetLine.lines === 1 ? lineH + 30 : offSetLine.lines === 2 ? lineH + 25 : lineH + 15,
                  fontSize: 0,
                  left: layoutWidth - priceWidth,
                  // x: titleWidth + 50
                },
              },
            ]),
          },
        ];
        // return [...simpleRows, sizeRow, addOnRow];// layout 04
        return [...simpleRows];
      }
      //layout 05
      if (layoutId === 5) {
        const titleWidth = displayTextWidth(item?.itemName, headingFont);
        let titleWidth1;

        if (titleWidth > 1000) {
          titleWidth1 = titleWidth + 100;
        }
        else if (titleWidth > 950) {
          titleWidth1 = titleWidth + 95;
        }
        else if (titleWidth > 900) {
          titleWidth1 = titleWidth + 95;
        }
        else if (titleWidth > 850) {
          titleWidth1 = titleWidth + 90;
        }
        else if (titleWidth > 800) {
          titleWidth1 = titleWidth + 85;
        }
        else if (titleWidth > 750) {
          titleWidth1 = titleWidth + 80;
        }
        else if (titleWidth > 700) {
          titleWidth1 = titleWidth + 75;
        }
        else if (titleWidth > 650) {
          titleWidth1 = titleWidth + 70;
        }
        else if (titleWidth > 600) {
          titleWidth1 = titleWidth + 65;
        }
        else if (titleWidth > 550) {
          titleWidth1 = titleWidth + 60;
        }
        else if (titleWidth > 500) {
          titleWidth1 = titleWidth + 55;
        }
        else if (titleWidth > 450) {
          titleWidth1 = titleWidth + 50;
        }
        else if (titleWidth > 400) {
          titleWidth1 = titleWidth + 45;
        }
        else if (titleWidth > 350) {
          titleWidth1 = titleWidth + 40;
        }
        else if (titleWidth > 300) {
          titleWidth1 = titleWidth + 35;
        }
        else if (titleWidth > 250) {
          titleWidth1 = titleWidth + 30;
        }
        else if (titleWidth > 200) {
          titleWidth1 = titleWidth + 25;
        }
        else if (titleWidth > 150) {
          titleWidth1 = titleWidth + 20;
        }
        else if (titleWidth > 100) {
          titleWidth1 = titleWidth + 15;
        }
        else if (titleWidth > 50) {
          titleWidth1 = titleWidth + 10;
        }
        else {
          titleWidth1 = titleWidth + 5;
        }
        const descriptionWidth = displayTextWidth(item?.description, descriptionFont);
        const sizeWidth = getTextWidth(`${item?.itemPrice + (item?.sizes[0] ?? 0) + (item?.sizes[1] ?? 0) + (item?.sizes[2] ?? 0) + (item?.sizes[3] ?? 0) + (item?.sizes[4] ?? 0) + (item?.sizes[5] ?? 0) + (item?.sizes[6] ?? 0) + (item?.sizes[7] ?? 0) + (item?.sizes[8] ?? 0) + (item?.sizes[9] ?? 0) + (item?.sizes[10] ?? 0)}`, priceFont, 4, item);
        const offSetY = getTextOffSet(item?.itemPrice, headingFont);
        console.log(offSetY, 'titleWidth')
        const simpleRows = [
          {
            elements: getMenuRow([
              {
                value: `${item?.itemName}`,
                style: {
                  dataType: "title",
                  fontSize: 40,
                  width: titleWidth1,
                  fontStyle: "bold",
                  align: "left",
                  x: 0,
                  menuID: item,
                  y: 0 * item?.id
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.description}`,
                style: {
                  dataType: "description",
                  width: descriptionWidth,
                  fontSize: 14,
                  align: "left",
                  x: 0,
                  menuID: item,
                  y: getTextOffSet(item?.itemName, headingFont) - 5 * item?.id,

                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: item?.addOns[0]?.price && formatAddOnsArrayAsString(item?.addOns),
                style: {
                  dataType: "addOns",
                  width: getMaxWidth(item),
                  fontSize: 15,
                  align: "left",
                  x: 0,
                  menuID: item,
                  y: getTextOffSet(item?.itemName, headingFont) - 5 * item?.id,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: item?.sizes[0].price ? formatSizeArrayAsString(item?.sizes) : item?.itemPrice,

                style: {
                  dataType: "price",
                  width: sizeWidth,
                  fontStyle: "bold",
                  fontSize: 22,
                  align: "right",
                  x: 0,
                  menuID: item,
                  y: getTextOffSet(item?.itemName, headingFont),
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                type: "icon",
                src: item?.icon,
                value: `${item?.icon}`,
                style: {
                  x: titleWidth1,
                  scaleX: 1.5,
                  scaleY: 1.5,
                  menuID: item,
                  y: 0 * item?.id,
                  align: "left",
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                type: "icon",
                style: {
                  scaleX: 0,
                  scaleY: 0,
                },
              },
            ]),
          },
        ];
        // return [...simpleRows, sizeRow, addOnRow];// layout 05
        return [...simpleRows];
      }
      //layout 06 
      // if (layoutId === 6) {
      //   const layoutWidth = displayTextWidth(item?.itemName, headingFont);
      //   // const titleWidth = displayTextWidth(item?.itemName, headingFont);
      //   const priceWidth = getTextWidth(`${item?.itemPrice}`, priceFont);
      //   const descriptionWidth = displayTextWidth(item?.description, descriptionFont);
      //   const sizeWidth = getTextWidth(`${item?.itemPrice + (item?.sizes[0] ?? 0) + (item?.sizes[1] ?? 0) + (item?.sizes[2] ?? 0) + (item?.sizes[3] ?? 0) + (item?.sizes[4] ?? 0) + (item?.sizes[5] ?? 0) + (item?.sizes[6] ?? 0) + (item?.sizes[7] ?? 0) + (item?.sizes[8] ?? 0) + (item?.sizes[9] ?? 0) + (item?.sizes[10] ?? 0)}`, priceFont);
      //   const offSetY = getTextOffSet(item?.itemPrice, headingFont);

      //   console.log(offSetY, 'titleWidth');
      //   const titleWidth = displayTextWidth(item?.itemName, headingFont);
      //   const gapWidth = 200 - titleWidth
      //   let titleWidth1;

      //   if (titleWidth > 1000) {
      //     titleWidth1 = titleWidth + 100;
      //   }
      //   else if (titleWidth > 950) {
      //     titleWidth1 = titleWidth + 95;
      //   }
      //   else if (titleWidth > 900) {
      //     titleWidth1 = titleWidth + 95;
      //   }
      //   else if (titleWidth > 850) {
      //     titleWidth1 = titleWidth + 90;
      //   }
      //   else if (titleWidth > 800) {
      //     titleWidth1 = titleWidth + 85;
      //   }
      //   else if (titleWidth > 750) {
      //     titleWidth1 = titleWidth + 80;
      //   }
      //   else if (titleWidth > 700) {
      //     titleWidth1 = titleWidth + 75;
      //   }
      //   else if (titleWidth > 650) {
      //     titleWidth1 = titleWidth + 70;
      //   }
      //   else if (titleWidth > 600) {
      //     titleWidth1 = titleWidth + 65;
      //   }
      //   else if (titleWidth > 550) {
      //     titleWidth1 = titleWidth + 60;
      //   }
      //   else if (titleWidth > 500) {
      //     titleWidth1 = titleWidth + 55;
      //   }
      //   else if (titleWidth > 450) {
      //     titleWidth1 = titleWidth + 50;
      //   }
      //   else if (titleWidth > 400) {
      //     titleWidth1 = titleWidth + 45;
      //   }
      //   else if (titleWidth > 350) {
      //     titleWidth1 = titleWidth + 40;
      //   }
      //   else if (titleWidth > 300) {
      //     titleWidth1 = titleWidth + 35;
      //   }
      //   else if (titleWidth > 250) {
      //     titleWidth1 = titleWidth + 30;
      //   }
      //   else if (titleWidth > 200) {
      //     titleWidth1 = titleWidth + 25;
      //   }
      //   else if (titleWidth > 150) {
      //     titleWidth1 = titleWidth + 20;
      //   }
      //   else if (titleWidth > 100) {
      //     titleWidth1 = titleWidth + 15;
      //   }
      //   else if (titleWidth > 50) {
      //     titleWidth1 = titleWidth + 10;
      //   }
      //   else {
      //     titleWidth1 = titleWidth + 5;
      //   }
      //   // const offSetY = getTextOffSet(item?.itemPrice, headingFont) ;
      //   const desTextMatrics = getMulitLineTextHeight({
      //     text: item?.description,
      //     font: descriptionFont,
      //     width: getMaxWidth(item),
      //     x: 0,
      //     y: getTextOffSet(item?.itemName, headingFont)
      //   });
      //   console.log(item, 'itemsfsdfdsfd')

      //   const simpleRows = [
      //     {
      //       elements: getMenuRow([
      //         {
      //           value: `${item?.itemName}`,
      //           style: {
      //             dataType: "title",
      //             fontSize: 40,
      //             width: titleWidth,
      //             fontStyle: "bold",
      //             align: "left",
      //             x: 0,
      //             menuID: item,
      //             y: 0 * item?.id
      //           },
      //         },
      //       ]),
      //     },
      //     {
      //       elements: getMenuRow([
      //         {
      //           value: `${item?.description}`,
      //           style: {
      //             dataType: "description",
      //             width: descriptionWidth,
      //             fontSize: 14,
      //             align: "left",
      //             // height: 100,
      //             x: 0,
      //             menuID: item,
      //             y: getTextOffSet(item?.itemName, headingFont) - 5 * item?.id,
      //           },
      //         },
      //       ]),
      //     },

      //     {
      //       elements: getMenuRow([
      //         {
      //           value: item?.sizes[0].price ? formatSizeArrayAsString(item?.sizes) : item?.itemPrice,
      //           style: {
      //             dataType: "price",
      //             fontSize: 22,
      //             width: sizeWidth,
      //             fontStyle: "bold",
      //             align: "right",
      //             x: gapWidth + titleWidth,
      //             menuID: item,
      //             y: desTextMatrics.y,
      //             // + getTextHeight(item?.itemPrice, priceFont) + 10,
      //           },
      //         },
      //       ]),
      //     },
      //     {
      //       elements: getMenuRow([
      //         {
      //           type: "icon",
      //           src: item?.icon,
      //           value: `${item?.icon}`,
      //           style: {
      //             x: titleWidth,
      //             scaleX: 1.5,
      //             scaleY: 1.5,
      //             menuID: item,
      //             y: desTextMatrics.y,
      //             // + getTextHeight(item?.itemPrice, priceFont) + 10,
      //             align: "right",
      //           },
      //         },
      //       ]),
      //     },
      //     {
      //       elements: getMenuRow([
      //         {
      //           value: item?.addOns[0]?.price && formatAddOnsArrayAsString(item?.addOns),
      //           style: {
      //             dataType: "addOns",
      //             width: getMaxWidth(item),
      //             fontSize: 15,
      //             align: "left",
      //             // height: 100,
      //             x: 0,
      //             menuID: item,
      //             y: getTextOffSet(item?.itemName, headingFont) - 5 * item?.id,
      //           },
      //         },
      //       ]),
      //     },
      //   ];
      //   // return [...simpleRows, sizeRow, addOnRow];// layout 06
      //   return [...simpleRows];

      // }
      //layout 06 
      if (layoutId === 6) {
        const layoutWidth = displayTextWidth(item?.itemName, headingFont);
        const titleWidth = displayTextWidth(item?.itemName, headingFont);
        const priceWidth = getTextWidth(`${item?.itemPrice}`, priceFont);
        const descriptionWidth = displayTextWidth(item?.description, descriptionFont);
        const sizeWidth = getTextWidth(`${item?.itemPrice + (item?.sizes[0] ?? 0) + (item?.sizes[1] ?? 0) + (item?.sizes[2] ?? 0) + (item?.sizes[3] ?? 0) + (item?.sizes[4] ?? 0) + (item?.sizes[5] ?? 0) + (item?.sizes[6] ?? 0) + (item?.sizes[7] ?? 0) + (item?.sizes[8] ?? 0) + (item?.sizes[9] ?? 0) + (item?.sizes[10] ?? 0)}`, priceFont);
        const offSetY = getTextOffSet(item?.itemPrice, headingFont);



        console.log(offSetY, 'titleWidth');

        // const offSetY = getTextOffSet(item?.itemPrice, headingFont) ;
        const desTextMatrics = getMulitLineTextHeight({
          text: item?.description,
          font: descriptionFont,
          width: getMaxWidth(item),
          x: 0,
          y: getTextOffSet(item?.itemName, headingFont)
        });
        console.log(item, 'itemsfsdfdsfd')

        const simpleRows = [
          {
            elements: getMenuRow([
              {
                value: `${item?.itemName}`,
                style: {
                  dataType: "title",
                  fontSize: 40,
                  width: titleWidth,
                  fontStyle: "bold",
                  align: "left",
                  x: 0,
                  menuID: item,
                  y: 0 * item?.id
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.description}`,
                style: {
                  dataType: "description",
                  width: descriptionWidth,
                  fontSize: 14,
                  align: "left",
                  // height: 100,
                  x: 0,
                  menuID: item,
                  y: getTextOffSet(item?.itemName, headingFont) - 5 * item?.id,
                },
              },
            ]),
          },

          {
            elements: getMenuRow([
              {
                value: item?.sizes[0].price ? formatSizeArrayAsString(item?.sizes) : item?.itemPrice,
                style: {
                  dataType: "price",
                  fontSize: 22,
                  width: sizeWidth,
                  fontStyle: "bold",
                  align: "right",
                  x: titleWidth - titleWidth + sizeWidth,
                  menuID: item,
                  y: desTextMatrics.y,
                  // + getTextHeight(item?.itemPrice, priceFont) + 10,
                },
              },
            ]),
          },

          {
            elements: getMenuRow([
              {
                value: item?.addOns[0]?.price && formatAddOnsArrayAsString(item?.addOns),
                style: {
                  dataType: "addOns",
                  width: getMaxWidth(item),
                  fontSize: 15,
                  align: "left",
                  // height: 100,
                  x: 0,
                  menuID: item,
                  y: getTextOffSet(item?.itemName, headingFont) - 5 * item?.id,
                },
              },
            ]),


          },

          {
            elements: getMenuRow([
              {
                type: "icon",
                src: item?.icon,
                value: `${item?.icon}`,
                style: {
                  x: titleWidth,
                  scaleX: 1.5,
                  scaleY: 1.5,
                  menuID: item,
                  y: 0 * item?.id,
                  align: "left",
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                // type: "icon",
                style: {
                  scaleX: 0,
                  scaleY: 0,
                },
              },
            ]),
          },




        ];
        // return [...simpleRows, sizeRow, addOnRow];// layout 06
        return [...simpleRows];

      }
      if (!layoutId) {
        const simpleRows = [
          {
            elements: getMenuRow([
              {
                value: `${item?.itemName}`,
                style: {
                  fontSize: 40,
                  width: getMaxWidth(item),
                  fontStyle: "bold",
                  align: "center",
                  x: 0,
                  y: 0,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `${item?.description}`,
                style: {
                  width: getMaxWidth(item),
                  fontSize: 14,
                  align: "left",
                  x: 0,
                  y: 55,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                value: `$${item?.itemPrice}.00`,
                style: {
                  fontSize: 22,
                  width: getMaxWidth(item),
                  fontStyle: "bold",
                  align: "right",
                  y: 90,
                  x: 55,
                },
              },
            ]),
          },
          {
            elements: getMenuRow([
              {
                type: "icon",
                src: item?.icon,
                value: `${item?.icon}`,
                style: {
                  fontSize: 24,
                  // width: 500,
                  height: 30,
                  align: "right",
                  x: getMaxWidth(item) / 2 - 5,
                  y: 120,
                },
              },
            ]),
          },
        ];
        // return [...simpleRows, sizeRow, addOnRow];// layout 01
        return [...simpleRows];
      }

    })
    .flat();
  return rows;
};

function createReactElement(element, options) {
  return React.createElement(element, options);
}

function downloadURI(uri, name) {
  let link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function loadImage(imageSrc) {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      const height = image.height;
      const width = image.width;
      resolve({ image, width, height });
    };
    image.src = imageSrc;
  });
}

function layoutScaleRelativeToPoint({
  node,
  stage,
  layout,
  scaleBy = 85,
  pointer = null,
  changeImmediately = false,
}) {
  const scale = {};
  const ratio = layout.width / layout.height;
  const scaleByNumber = scaleBy / 100;
  const oldScaleX = node.scaleX();
  const oldScaleY = node.scaleY();

  let scalePointer = pointer || {
    x: stage.width() / 2,
    y: stage.height() / 2,
  };

  if (ratio > 1) {
    scale.x = (stage.width() / layout.width) * scaleByNumber;
    scale.y = (stage.width() / layout.width) * scaleByNumber;
  } else if (ratio === 1) {
    scale.x = scaleByNumber;
    scale.y = scaleByNumber;
  } else {
    scale.x = (stage.height() / layout.height) * scaleByNumber;
    scale.y = (stage.height() / layout.height) * scaleByNumber;
  }

  let pointTo = {
    x: (scalePointer.x - node.x()) / oldScaleX,
    y: (scalePointer.y - node.y()) / oldScaleY,
  };

  let position = {
    x: scalePointer.x - pointTo.x * scale.x,
    y: scalePointer.y - pointTo.y * scale.y,
  };

  if (changeImmediately) {
    node.scale({ x: scale.x, y: scale.y });
    node.position(position);
  }

  return { scale, position };
}

function getCrop(image, size, cropPosition) {
  const imageWidth = image.width || image.videoWidth;
  const imageHeight = image.height || image.videoHeight;
  const width = size.width;
  const height = size.height;
  const aspectRatio = width / height;

  let x = 0;
  let y = 0;
  let newWidth = 0;
  let newHeight = 0;

  const imageRatio = imageWidth / imageHeight;

  if (aspectRatio >= imageRatio) {
    newWidth = imageWidth;
    newHeight = imageWidth / aspectRatio;
  } else {
    newWidth = imageHeight * aspectRatio;
    newHeight = imageHeight;
  }

  if (cropPosition === "middle-right") {
    x = 0;
    y = (imageHeight - newHeight) / 2;
  } else if (cropPosition === "bottom-center") {
    x = (imageWidth - newWidth) / 2;
    y = 0;
  } else if (cropPosition === "top-center") {
    x = (imageWidth - newWidth) / 2;
    y = imageHeight - newHeight;
  } else if (cropPosition === "middle-left") {
    x = imageWidth - newWidth;
    y = (imageHeight - newHeight) / 2;
  }

  return {
    cropX: 0,
    cropY: 0,
    cropWidth: newWidth,
    cropHeight: newHeight,
  };
}

function createArrayByLength(length) {
  if (!length) return [];
  let a = Array(length);
  let b = 0;
  while (b < length) a[b++] = b;
  return a;
}

export {
  createReactElement,
  downloadURI,
  loadImage,
  layoutScaleRelativeToPoint,
  getCrop,
  createArrayByLength,
  getMenuRowsWithLayout,
  getTextOffSet,
};