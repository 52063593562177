import { useState } from "react";
import { Grid, Slider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { updateStudioElement } from "../../store/studioSlice";
import CustomToolTip from "../CustomToolTip";
import SideBarHeading from "../Heading";

const PropertiesPanelOpacity = () => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements, studioTransformer } =
    useSelector((state) => state.studio.present);
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const transformerNodes = studioTransformer.nodes;
  const [opacity, setOpacity] = useState(
    parseInt(selectedElement?.opacity * 100, 10) || 100
  );

  const onSliderChange = (e, value) => {
    setOpacity(value);
    const transformerNodesIds = transformerNodes.map((item) => item.id());
    studioElements.forEach((item) => {
      if (transformerNodesIds.includes(item.id)) {
        dispatch(updateStudioElement({ id: item.id, opacity: value / 100 }));
      }
    });
  };

  return (
    <Grid container alignItems="center">
      <Grid width={40}>
        <CustomToolTip content={"Apply Opacity on selected element"}>
          {/* <span className="text-sm">Opacity</span> */}
          <SideBarHeading content={"Opacity"} />
        </CustomToolTip>
      </Grid>

      <Grid item xs px={2}>
        <Slider
          style={{ color: "#5DBBE4" }}
          className="mt-2.5"
          value={opacity}
          onChange={onSliderChange}
        />
      </Grid>
      <Grid width={25}>
        <span className="text-xs">{opacity}%</span>
      </Grid>
    </Grid>
  );
};

export default PropertiesPanelOpacity;
