import { v4 as uuid } from "uuid";

const ELEMENT_TYPES = [
  "stage",
  "shape",
  "image",
  "video",
  "text",
  "menu",
  "widget",
];

const TEXT_ELEMENT_DEFAULT_PROPS = {
  text: "",
  scaleX: 1,
  scaleY: 1,
  opacity: 1,
  fill: null,
  rotation: 0,
  type: "text",
  stroke: null,
  strokeWidth: 0,
  animation: null,
  draggable: true,
  textDecoration: "",
  fontFamily: "Arial",
  fontStyle: "normal",
  id: `text-${uuid()}`,
};

export { ELEMENT_TYPES, TEXT_ELEMENT_DEFAULT_PROPS };
export const MENU_ICONS = [
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/1.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/2.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/3.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/4.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/6.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/7.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/8.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/9.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/10.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/11.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/12.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/13.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/14.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/15.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/16.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/17.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/18.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/19.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/20.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/21.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/22.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/23.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/24.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/25.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/26.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/27.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/28.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/29.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/30.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/31.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/32.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/33.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/34.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/35.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/36.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/37.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/38.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/39.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/40.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/41.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/42.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/43.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/44.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/45.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/46.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/47.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/48.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/49.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/50.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/51.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/52.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/53.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/54.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/55.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/56.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/57.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/58.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/59.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/60.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/61.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/62.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/63.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/64.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/65.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/66.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/67.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/68.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/69.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/70.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/71.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/72.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/73.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/74.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/75.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/76.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/77.png",
  // "https://www.postermywall.com//assets/postermaker/menu-icons/previews/78.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/79.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/80.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/81.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/82.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/83.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/84.png",
  "https://www.postermywall.com//assets/postermaker/menu-icons/previews/85.png",
];
