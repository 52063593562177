import {useEffect, useState} from "react";
import {Box} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {
    Alert,
    Snackbar,
    TextField,
    Typography,
    IconButton,
    InputAdornment,
} from "@mui/material";
import {MdVisibility, MdVisibilityOff} from "react-icons/md";

import request, {baseURL} from "../services/request";
import {setUser} from "../store/authSlice";
import AuthBox from "../components/Auth/AuthBox";
import {getToken, storeToken} from "../services/localStorage";

const Signin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [messageBox, setMessageBox] = useState({
        show: false,
        message: "",
    });
    const [form, setForm] = useState({email: "", password: ""});
    const [categories, setCategories] = useState([]);


    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const closeMessageBox = () => {
        setMessageBox({...messageBox, show: false});
    };

    const onSubmit = async () => {
        setLoading(true);

        try {
            const res = await request({
                method: "post",
                url: "/login",
                data: form,
            });

            const token = res?.data?.access_token;
            const roles = res?.data?.roles;


            if (token) {
                storeToken(token);
                dispatch(setUser({roles}));
                navigate(categories.length > 0 ? "/" : "/onboarding"); // Redirect based on categories
            }
        } catch (error) {
            setMessageBox({
                ...messageBox,
                message: error.message,
                show: true,
                type: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCategories();
    }, []);

    const getCategories = async () => {
        setLoading(true);

        const token = getToken();

        try {
            const res = await request({
                method: "get",
                url: "/categories",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const categories = res?.data?.data;
            setCategories(categories);
            return categories.length > 0; // Return true if categories exist
        } catch (error) {
            setCategories([]);
            return false; // Return false on error
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <AuthBox>
                <Typography mb={2} variant="h6" textAlign="center">
                    Log in to your account
                </Typography>

                <Box component="form">
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={form.email}
                        label="Email / Username"
                        sx={{marginBottom: 2.5}}
                        onChange={(e) => setForm({...form, email: e.target.value})}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        label="Password"
                        variant="outlined"
                        value={form.password}
                        sx={{marginBottom: 2.5}}
                        type={showPassword ? "text" : "password"}
                        onChange={(e) => setForm({...form, password: e.target.value})}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <MdVisibilityOff size={"1em"}/>
                                        ) : (
                                            <MdVisibility size={"1em"}/>
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    <LoadingButton
                        loading={loading}
                        onClick={onSubmit}
                        fullWidth
                        size="large"
                        variant="contained"
                        sx={{
                            boxShadow: "0",
                            marginBottom: 2,
                            borderRadius: 1.5,
                            textTransform: "none",
                        }}
                    >
                        Log in
                    </LoadingButton>

                    <Typography
                        variant="caption"
                        textAlign="center"
                        sx={{display: "block"}}
                    >
                        New to Media Editor?{" "}
                        <a
                            href={`${baseURL}/register`}
                            className="text-blue-500 cursor-pointer"
                        >
                            Sign up
                        </a>
                    </Typography>
                </Box>
            </AuthBox>

            <Snackbar
                open={messageBox.show}
                autoHideDuration={5000}
                onClose={closeMessageBox}
                anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            >
                <Alert onClose={closeMessageBox} severity={messageBox.type}>
                    {messageBox.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Signin;
