import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Button, ButtonGroup } from "@mui/material";

import { updateStudioElement } from "../../store/studioSlice";
import CustomToolTip from "../CustomToolTip";
import SideBarHeading from "../Heading";
import { red } from "@mui/material/colors";

const ANIMTATION_EFFECT_DURATIONS = [
  {
    key: "short",
    number: 0.5,
  },
  {
    key: "med",
    number: 1,
  },
  {
    key: "long",
    number: 1.5,
  },
];

const DurationButton = ({ active, label, datakey }) => {
  const dispatch = useDispatch();
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );
  const selectedElementEffect = selectedElement?.animation;

  const onClick = () => {
    const selectedEffectDuration = ANIMTATION_EFFECT_DURATIONS.find(
      (item) => item.key === datakey
    );
    const animation = {
      ...selectedElementEffect,
      duration: selectedEffectDuration,
    };

    dispatch(updateStudioElement({ animation }));
  };

  return (
    <Button

      size="small"
      style={{
        backgroundColor: active ? '#5dbbe4' : "#ccc",
        borderColor: '#5dbbe4',
        border: 'none',
        height: 25,
        minWidth: 40,
        fontSize: 12,
        marginRight: 4,
        borderRadius: 4,
        textTransform: "capitalize",
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

const PropertiesAnimationEffectsDuration = () => {
  const { studioCurrentTarget, studioElements } = useSelector(
    (state) => state.studio.present
  );
  const selectedElement = studioElements.find(
    (item) => item.id === studioCurrentTarget
  );

  const durationKey =
    selectedElement?.animation?.duration?.key ||
    ANIMTATION_EFFECT_DURATIONS[1].key;

  return (
    <Grid container alignItems="center" mb={2}>
      <Grid item xs={3}>
        <CustomToolTip content={"You can use desired animation type"}>
          {/* <Typography variant="body2">Duration</Typography> */}
          <SideBarHeading content={'Duration'} />
        </CustomToolTip>
      </Grid>
      <Grid item xs>
        <Grid container>
          <ButtonGroup disableElevation variant="contained" color="primary">
            {ANIMTATION_EFFECT_DURATIONS.map((item) => (
              <DurationButton
                active={item.key === durationKey}
                key={item.key}
                datakey={item.key}
                label={item.key}
              />
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PropertiesAnimationEffectsDuration;
