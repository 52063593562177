import { v4 as uuid } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import {
  Grid,
  ImageList,
  ImageListItem,
  CircularProgress,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { BiSearch } from "react-icons/bi";

import request from "../../services/request";
import { setToolsDragElement } from "../../store/toolsSlice";

const EditToolsContentImages = () => {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [prevPage, setPrevPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [wasLastList, setWasLastList] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [searching, setSearching] = useState('');
  const { contentScrollEnd } = useSelector((state) => state.tools);

  const getImageList = async (isSearch = false) => {
    setLoading(true);

    try {

      if (isSearch === true && searchString.length >= 0 || searchString.length >= 0 && searching === true) {
        console.log({
          searchString, isSearch,
          searching
        }, "searchString");
        if (isSearch === true) {
          setSearching(isSearch);
        }
        const res = await request({
          baseURL: "https://api.unsplash.com/",
          method: "get",
          url: "/search/photos",
          params: {
            per_page: 40,
            page: currentPage,
            client_id: "LcT1mkX9Ua-Sm-y3kwc0Jv_GmZaFSQNz_VSbOLGPUvU",
            query: searchString,
          },
        });
        // console.log(res.data.results, "search")
        const imageList = res.data.results;
        if (!imageList.length) {
          setWasLastList(true);
          return;
        }
        setPrevPage(currentPage);
        if (isSearch === true) {
          setImages([...imageList]);
        } else {
          setImages([...images, ...imageList]);
        }
      } else {
        const res = await request({
          baseURL: "https://api.unsplash.com/",
          method: "get",
          url: "/photos",
          params: {
            per_page: 20,
            page: currentPage,
            client_id: "LcT1mkX9Ua-Sm-y3kwc0Jv_GmZaFSQNz_VSbOLGPUvU",
          },
        });
        const imageList = res.data;
        if (!imageList.length) {
          setWasLastList(true);
          return;
        }
        setPrevPage(currentPage);
        setImages([...images, ...imageList]);
      }
    } catch (error) {
      setImages([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (contentScrollEnd) {
      setCurrentPage(currentPage + 1);
    }
  }, [contentScrollEnd]);

  useEffect(() => {
    if (!wasLastList && prevPage !== currentPage) {
      getImageList();
    }
  }, [currentPage, wasLastList, prevPage, images, searching]);

  return (
    <>
      <form onSubmit={(e) => {
        e.preventDefault();
        if (searchString.length >= 0) getImageList(true);
        else {
          setImages([]);

          setTimeout(() => {
            setSearching(false);
            getImageList();
          }, 500);
        }
      }}>
        <div className="SearchItem">
          <input type="text" placeholder="Search Images Here" onChange={(e) => {
            if (e.target.value.length <= 0) {
              if (searching) {
                setImages([]);
              }
              setTimeout(() => {
                setSearching(false);
                getImageList();
              }, 500);
            }
            setSearchString(e.target.value);
          }}></input>
          <button type="submit">
            <IconButton size="small" color="inherit">
              <BiSearch size={"1.5em"} />
            </IconButton>
          </button>
        </div>
      </form>
      {searching && images.length <= 0 && (
        <Grid container justifyContent="center" py={2}>
          <CircularProgress />
        </Grid>
      )}
      <ImageList variant="standard" cols={2} gap={5}>
        {images.length
          ? images.map((image, index) => (
            <ImageListItem
              key={`${image.id}-${index}`}
              draggable
              onDragStart={(e) => {
                dispatch(
                  setToolsDragElement({
                    scaleX: 1,
                    scaleY: 1,
                    opacity: 1,
                    rotation: 0,
                    type: "image",
                    animation: null,
                    draggable: true,
                    width: image.width / 6,
                    height: image.height / 6,
                    src: image.urls.regular,
                    name: `by ${image.user.name}`,
                    id: `image-${image.id}-${uuid()}`,
                  })
                );
              }}
              onDragEnd={(e) => {
                dispatch(setToolsDragElement(null));
              }}
            >
              <img
                src={image.urls.thumb}
                alt={image.alt_description}
                loading="lazy"
              />
            </ImageListItem>
          ))
          : ""}
      </ImageList>
      {loading && (
        <Grid container justifyContent="center" py={2}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
};

export default EditToolsContentImages;
