import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { MENU_ICONS } from "../../constants";

// import { svgData } from "../../svgs/svgs";

const App = ({ selectedEmoji, setSelectedEmoji }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEmojiSelect = (emoji) => {
        setSelectedEmoji(emoji);
          setOpen(false);
  };
  
  return (
    <div>
      <Button
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          borderWidth: "0.1rem",
          borderStyle: "dashed",
          color: "#b3b3b3",
          minWidth: "140px",
          width: "135px",
          padding: "8px",
        }}
        component="label"
        onClick={handleOpen}
      >
        <AddIcon sx={{ width: "15px" }} />
        <Typography sx={{ fontSize: "12px" }}>Add icon</Typography>
        <img src={selectedEmoji} height={20} width={20} />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Select an icon</DialogTitle>
        <DialogContent>
          <Box display="flex" flexWrap="wrap">
            {MENU_ICONS.map((emoji, index) => (
              <img
                style={{
                  cursor: "pointer",
                }}
                key={index}
                onClick={() => handleEmojiSelect(emoji)}
                src={emoji}
                height={20}
                width={20}
                className="m-3"
              />
            ))}
          </Box>
          <p>
            Selected Icon: <img src={selectedEmoji} height={20} width={20} />
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default App;
