import { Line, Text } from "react-konva";
import { useSelector } from "react-redux";

import { createArrayByLength } from "../../utils";

const LineVertical = ({ portion = 2 }) => {
  const { studioLayout } = useSelector((state) => state.studio.present);
  return (
    <Line
      name="GridLine"
      x={studioLayout.width / portion}
      points={[0, 0, 0, studioLayout.height]}
      stroke="#333"
      strokeWidth={1}
    />
  );
};

const LineHorizontal = ({ portion = 2 }) => {

  const { studioLayout } = useSelector((state) => state.studio.present);
  return (
    <Line
      name="GridLine"
      y={studioLayout.height / portion}
      points={[0, 0, studioLayout.width, 0]}
      stroke="#333"
      strokeWidth={1}
    />
  );
};

const StudioLayoutGrid = () => {
  const X_GAP = 10;
  const Y_GAP = 15;
  const { studioLayout, studioLayoutGrid, studioElements } = useSelector(
    (state) => state.studio.present
  );

  const isNoLine =
    studioLayoutGrid?.width === 1 && studioLayoutGrid?.height === 1;

  const widthLines = createArrayByLength(studioLayoutGrid?.width - 1);
  const heightLines = createArrayByLength(studioLayoutGrid?.height - 1);
  const dimentionsCounts = createArrayByLength(
    studioLayoutGrid?.width * studioLayoutGrid?.height
  );
  // console.log({ widthLines, heightLines })
  return (

    <>
      {!isNoLine &&
        widthLines.map((item, index) => (
          <LineHorizontal key={item} portion={studioLayoutGrid?.width / item} />
        ))}

      {!isNoLine &&
        heightLines.map((item, index) => (
          <LineVertical key={item} portion={studioLayoutGrid?.height / item} />
        ))}

      {!studioElements.length &&
        dimentionsCounts.map((item, index) => {
          const x =
            studioLayout.width *
            ((index % studioLayoutGrid?.height) / studioLayoutGrid?.height);
          const y =
            studioLayout.height *
            (parseInt(index / studioLayoutGrid?.height) /
              studioLayoutGrid?.width);
          const width =
            studioLayout.width *
            (1 / (studioLayoutGrid?.width * studioLayoutGrid?.height));

          const height =
            studioLayout.height *
            (1 / (studioLayoutGrid?.width * studioLayoutGrid?.height));

          return (
            <Text
              key={index}
              x={isNaN(x * 0) ? 0 : 0}
              // x={isNaN(x * 0) ? 0 : x + X_GAP}
              y={isNaN(y * 0) ? 0 : 0}
              // y={isNaN(y * 0) ? 0 : y + Y_GAP}
              text={`${parseInt(width)}x${parseInt(height)}`}
              fontSize={18}
              fill="#bbb"
            />
          );
        })}
    </>
  );
};

export default StudioLayoutGrid;
